import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FileSaverService } from 'ngx-filesaver';
import { GlobalSettings, ModuleName } from '../settings/global-settings';
import {
  Ahm,
  AhmData,
  CabinArea,
  Calculation,
  Flight,
  Route,
  HoldsAndCompartmentsCalc,
  FilterParams,
  DowChangesCrew,
  UldTypes,
  Document,
  NotocDangerous,
  NotocOther
} from '../weight-balance-data/weight-balance';
import { WeightBalanceModuleRestApiService } from '../weight-balance-data/weight-ballance-rest-api-service';
import { GlobalI18n } from '../settings/global-i18n';
import {
  ReferanceAirport,
  ReferanceAirline,
  ReferanceAircraft,
  ReferanceTail
} from '../referance-module-data/referance';
import { HttpResponse } from '@angular/common/http';
import { drawGravity, createCanvas } from '../core/gravity'

@Component({
  selector: 'app-weight-balance',
  templateUrl: './weight-balance.component.html',
  styleUrls: ['./weight-balance.component.less']
})
export class WeightBalanceComponent implements OnInit {

  @ViewChild('closebutton') closebutton: any;
  @ViewChild('flightForm') flightForm: any;

  // Текущее окно
  activeWindow = 'flight-list';
  // Текущая вкладка
  activeTab = 'standard-units';
  flightCardActiveTab = 'flight-card-tab-route';
  // Отображение данных и весах и центровке в виде таблицы или графика
  chartIsShow = false;

  // Классы для добавления элементов по кнопке add
  calculationCompartments = HoldsAndCompartmentsCalc;
  NotocDangerous = NotocDangerous;
  NotocOther = NotocOther;

  units = {
    weight: [{
      id: 1,
      englishName: 'Killogram',
      localName: 'Килограммы'
    }],
    length: [{
      id: 1,
      englishName: 'Meters',
      localName: 'Метры'
    }],
    volume: [{
      id: 1,
      englishName: 'Cubic metre',
      localName: 'Кубические метры'
    }],
    liquidVolume: [{
      id: 1,
      englishName: 'Liter',
      localName: 'Литры'
    }]
  };

  typeOfCargo = [
    {id: 1, name: 'Baggage'},
    {id: 2, name: 'Cargo'},
    {id: 3, name: 'Mail'},
    {id: 4, name: 'FKT'},
    {id: 5, name: 'Ballast'}
  ];

  documentTypes = [
    {
      id: 1,
      type: 'Loading instruction',
      url: '/loading_instruction',
    }, {
      id: 2,
      type: 'Loadsheet',
      url: '/loadsheet',
    }, {
      id: 3,
      type: 'Notoc',
      url: '',
    },
  ];
  // documentUrl = '/load_instruction_report';
  documentText = '';

  // Флаг, который блокирует кнопку печати, если точки вышли
  printDisabled = false;

  // Багажники
  locations = [];
  // Точки маршрута после домашнего
  destination = [];
  // Типы контейнеров
  uldTypes = [];
  // Типы компоновок экипажа
  crewComposition: Array<DowChangesCrew> = [];
  calculationCrewComposition: DowChangesCrew = new DowChangesCrew({cockpit: 0, cabin: 0, extra: 0, dow: 0, doi: 0});

  // Дельта балласта
  deltaBallast = {deltaDow: 0, deltaDoi: 0};

  // Максимальная коммерческая загрузка
  maxTrafficLoad = null;

  // Признак существующей корректной центровки для ВС
  calculationCorrect = false;

  flights: Flight[];
  flight: Flight = new Flight(this.globalSettings.homeAirport);
  calculation: Calculation = new Calculation();
  ahms: Ahm[];
  ahmData: AhmData = new AhmData();
  chartData = [];

  references = {
    aircraft_types: [] as ReferanceAircraft[],
    airlines: [] as ReferanceAirline[],
    airports: [] as ReferanceAirport[],
    seasons: [],
    tails: [] as ReferanceTail[],
    // dow_items: []
  }

  selectLoadAnimation = {
    airlines: true,
    aircraft_types: true,
    airports: true,
    tails: true,
  }

  buffer = {
    tails: [],
    airlines: [],
    aircraft_types: [],
    airports: [],
  }

  // Размер отображаемых данных в выпадающем списке
  bufferSize = {
    tails: 50,
    airlines: 50,
    aircraft_types: 50,
    airports: 50,
  }

  numberOfItemsFromEndBeforeFetchingMore = 10;

  deleteQueue = new Set();
  error = {
    errorMessage: '',
    errorType: '',
  }

  modalType = '';
  userAnswer = null;
  usesAction = '';

  filterParams: FilterParams = new FilterParams();
  filterApply = false;
  showFilter = false;
  searchFlight = '';
  messages: Array<string> = [];

  documentEditions: Array<Document> = [];
  currentDocumentEditions = null;
  currentDocumentView = null;

  // для setInterval обновления даты AHM
  ahmLastupdateInterval;

  //@ViewChild('gravityCentre', {static: false}) gravityCentre: ElementRef;
  @ViewChild('gravityCentreBlock', {static: false}) gravityCentreBlock: ElementRef;
  @ViewChild('gravityCentreBlockBig', {static: false}) gravityCentreBlockBig: ElementRef;
  @ViewChild("print") print!: ElementRef;

  public context: CanvasRenderingContext2D;
  public contextBig: CanvasRenderingContext2D;

  constructor(
    public restApi: WeightBalanceModuleRestApiService,
    private fileSaverService: FileSaverService,
    public globalSettings: GlobalSettings,
    public globalI18n: GlobalI18n
  ) {}

  ngOnInit(): void {
    this.loadWindow();
    this.loadReferences();
    this.messages['current'] = this.globalI18n.getMessage(ModuleName.Schedule, 'current');
  }

  // AHM
  async loadAhms() {
    this.activeWindow = 'ahm-list';
    this.ahms = [];
    await this.restApi.getAhms().then(data => {
      data.forEach(el => {
        let ahm = new Ahm();
        Object.assign(ahm, el);
        this.ahms.push(ahm);
      });
    }, err => {
      this.displayError(err);
    });
  }

  async selectAhm(id) {
    this.activeWindow = 'ahm-card';
    this.activeTab = 'flight-information';
    const data = await this.restApi.getAhm(id).then(data => {
      this.ahmData = new AhmData();
    }, err => {
      this.displayError(err);
    });
  }

  // Flights
  async loadFlights() {
    this.activeWindow = 'flight-list';
    this.flights = [];
    await this.restApi.getFlights(this.filterApply ? this.filterParams : null).then(data => {
      data.forEach(el => {
        let flight = new Flight(this.globalSettings.homeAirport);
        Object.assign(flight, el);
        this.flights.push(flight);
      });
      clearInterval(this.ahmLastupdateInterval);
      this.flight = new Flight(this.globalSettings.homeAirport);
    }, err => {
      this.displayError(err);
    });
  }

  get flightList() {
    return this.flights
      .filter(item => {
        if (this.searchFlight !== '') {
          return ((item.airlineIata && item.airlineIata.toLowerCase().indexOf(this.searchFlight.toLowerCase()) !== -1) ||
                  (item.flightNumber && item.flightNumber.toLowerCase().indexOf(this.searchFlight.toLowerCase()) !== -1));
        } else {
          return item;
        }
      });
  }

  createFlight() {
    this.flight = new Flight(this.globalSettings.homeAirport);
    this.flightForm.form.markAsUntouched();
    this.flightForm.reset();
    this.usesAction = 'addFlight';
  }

  async selectFlight(id) {
    this.usesAction = 'selectFlight';
    this.flightForm.form.markAsUntouched();
    this.flightForm.reset();
    await this.restApi.getFlight(id).then(flight => {
      this.flight = new Flight(this.globalSettings.homeAirport);
      Object.assign(this.flight, flight);
      if (this.flight.route) {
         this.flight.route = [];
         this.destination = [];
         let flag = false;
         flight.route.forEach(el => {
           let route = new Route();
           Object.assign(route, el);
           if (flag) this.destination.push(route)
           this.flight.route.push(route);
           if (route.airportId === this.globalSettings.homeAirport) flag = true
       });
      }
    }, err => {
      this.displayError(err);
    });
  }

  addFlight() {
    this.flightForm.form.markAllAsTouched();
    if (this.flightForm.valid) {
      let airports = this.flight.route.map(el => el.airportId);
      if (!airports.includes(this.globalSettings.homeAirport)) {
        this.error.errorMessage = this.globalI18n.getMessage(ModuleName.WeightBalance, 'noBaseAirport');
        this.error.errorType = 'error';
        return;
      }
      return this.restApi.addFlight(this.flight).subscribe(async (data: HttpResponse<any>) => {
        // Получение данных из заголовков ответа
        // идентификатор рейса будет в нем как /flights/110008765
        const location = data.headers.get('Location');
        // Регулярное выражение, в 2 группе будет идентификатор рейса
        let getFlightIdFromHeader = new RegExp("(\/flights\/)([0-9]*)", "g");
        let match = getFlightIdFromHeader.exec(location);
        // Закрытие окна редактирования карточки рейса
        this.closebutton.nativeElement.click();
        await this.loadFlights().then(async (data) => {
          // Открытие расчета для вновь созданного рейса
          await this.openCalculation(match[2]);
        });
      }, err => {
        this.displayError(err);
      });
    } else {
      this.error.errorMessage = this.globalI18n.getMessage(ModuleName.WeightBalance, 'missingRequiredFields');
      this.error.errorType = 'error';
      return;
    }
  }

  editFlight() {
    this.usesAction = 'editFlight';
  }

  async openCalculation(flightId) {
    this.documentText = '';
    this.calculationCorrect = false;
    this.maxTrafficLoad = null;
    await this.selectFlight(flightId);
    await this.editCalculation(flightId);
  }

  saveFlight() {
    this.flightForm.form.updateValueAndValidity();
    this.flightForm.form.markAllAsTouched();
    if (this.flightForm.valid) {
      let airports = this.flight.route.map(el => el.airportId);
      if (!airports.includes(this.globalSettings.homeAirport)) {
        this.error.errorMessage = this.globalI18n.getMessage(ModuleName.WeightBalance, 'noBaseAirport');
        this.error.errorType = 'error';
        return;
      }
      return this.restApi.updateFlight(this.flight).subscribe((data: {}) => {
        this.closebutton.nativeElement.click();
        this.loadFlights();
      }, err => {
        this.displayError(err);
      });
    } else {
      this.error.errorMessage = this.globalI18n.getMessage(ModuleName.WeightBalance, 'missingRequiredFields');
      this.error.errorType = 'error';
      return;
    }
  }

  async editCalculation(id) {
    this.activeWindow = 'flight-card';
    this.activeTab = 'flight-tab-info';
    this.calculation = new Calculation();
    this.calculationCrewComposition = new DowChangesCrew({cockpit: 0, cabin: 0, extra: 0, dow: 0, doi: 0});

    await this.loadAhmData();

    this.ahmLastupdateInterval = setInterval(() => {
      if (this.flight?.id) {
        this.getAhmLastupdate(this.flight.tailId);
      }
    }, 30000);

    let dataWell = this.ahmData.centreOfGravityChart[0];

    this.chartData = [];
    await this.restApi.getCalculation(id).then( async (data) => {
      this.usesAction = 'editCalculation';
      this.calculation = JSON.parse(JSON.stringify(data));
      //Object.assign(this.calculation, data);
      this.calculation.passengers.cabinArea = [];
      data.passengers.cabinArea.forEach(el => {
        this.calculation.passengers.cabinArea.push(Object.assign(new CabinArea(), el));
      });
      await this.restApi.getChart(this.calculation).then(charts => {
        this.chartData = charts;
      });
      for (const crew of this.crewComposition) {
        if (crew.cabin === this.calculation.crew.schema.cabin &&
            crew.cockpit === this.calculation.crew.schema.cockpit &&
            crew.extra === this.calculation.crew.schema.extra) {
          this.calculationCrewComposition = new DowChangesCrew(crew);
          break;
        } else if (crew.cabin === this.calculation.crew.schema.cabin &&
                   crew.cockpit === this.calculation.crew.schema.cockpit &&
                   !crew.extra) {
          this.calculationCrewComposition = new DowChangesCrew(crew);
          break;
        }
      }
      dataWell = this.calculation.centreOfGravityChart;
      this.calculateMaxTrafficLoad();
    },
    error => {
      this.usesAction = 'addCalculation';
      this.setDefaultCalculation();
    });

    this.documentEditions = [];
    this.currentDocumentEditions = null;
    this.getDocumentEditions();

    if (this.gravityCentreBlock) {
      let width = this.gravityCentreBlock.nativeElement.clientWidth;
      this.context = createCanvas(width, width, this.gravityCentreBlock.nativeElement);
      this.printDisabled = drawGravity(width, width, dataWell, this.chartData, this.ahmData, this.context);
    }

    if (this.gravityCentreBlockBig) {
      let height = document.documentElement.clientHeight - 100;
      this.contextBig = createCanvas(height, height, this.gravityCentreBlockBig.nativeElement);
      this.printDisabled = drawGravity(height, height, dataWell, this.chartData, this.ahmData, this.contextBig);
    }
    this.checkCalculationCorrect();
  }

  async loadAhmData() {
    await this.restApi.getAhmData(this.flight.tailId).then(data => {
      this.ahmData = new AhmData();
      Object.assign(this.ahmData, data);
      this.locations = [];
      this.ahmData.holdsAndCompartments.fwd.forEach(el => {
        if (el.index !== 0 && el.maxWeight !==0) this.locations.push(el);
        if (el.bays && el.bays.length > 0) {
          el.bays.forEach(bay => {
            this.locations.push(bay);
          });
        }
      });
      this.ahmData.holdsAndCompartments.aft.forEach(el => {
        if (el.index !== 0 && el.maxWeight !==0) this.locations.push(el);
        if (el.bays && el.bays.length > 0) {
          el.bays.forEach(bay => {
            this.locations.push(bay);
          });
        }
      });
      this.uldTypes = [];
      if (this.ahmData.uldTypes && this.ahmData.uldTypes.length > 0) {
        this.ahmData.uldTypes.forEach(el => {
          this.uldTypes.push(el);
        });
      }
      let bulkUld = new UldTypes;
      bulkUld.name = 'BULK';
      bulkUld.weight = 0;
      bulkUld.maxWeight = 0;
      bulkUld.maxVolume = 0;
      this.uldTypes.push(bulkUld);

      this.crewComposition = [];
      if (this.ahmData.dowChanges.crew && this.ahmData.dowChanges.crew.length > 0) {
        this.ahmData.dowChanges.crew.forEach( el => {
          this.crewComposition.push(new DowChangesCrew(el));
        })
      }
    }, err => {
      this.displayError(err);
    });
  }

  async reloadAhmData() {
    this.error.errorMessage = this.globalI18n.getMessage(ModuleName.WeightBalance, 'reloadAhmData');
    this.error.errorType = 'warning';
    this.modalType = 'setAnswer';
    await this.waitAnswer().then(async res => {
      if (res) {
        let id = this.calculation.id;
        let edno = this.calculation.edno;
        await this.loadAhmData();
        this.calculation = new Calculation();
        this.calculation.id = id;
        this.calculation.edno = edno;
        this.calculationCrewComposition = new DowChangesCrew({cockpit: 0, cabin: 0, extra: 0, dow: 0, doi: 0});
        this.setDefaultCalculation();
        this.calculation.ahmLastupdate = this.ahmData.lastupdate;

        let dataWell = this.ahmData.centreOfGravityChart[0];

        this.chartData = [];

        let width = this.gravityCentreBlock.nativeElement.clientWidth;
        //this.context = createCanvas(width, width, this.gravityCentreBlock.nativeElement);
        this.printDisabled = drawGravity(width, width, dataWell, this.chartData, this.ahmData, this.context);

        let height = document.documentElement.clientHeight - 100;
        //this.contextBig = createCanvas(height, height, this.gravityCentreBlockBig.nativeElement);
        this.printDisabled = drawGravity(height, height, dataWell, this.chartData, this.ahmData, this.contextBig);
        this.checkCalculationCorrect();
      }
    });
  }

  getAhmLastupdate(id) {
    this.restApi.getAhmData(id).then(data => {
      this.ahmData.lastupdate = data.lastupdate;
    }, err => {
      this.displayError(err);
    });
  }

  setDefaultCalculation() {
    this.calculation.flightId = this.flight.id;
    this.calculation.tailsAhmDataId = this.ahmData.id;
    this.calculation.mtow = this.ahmData.takeOffWeight;
    this.calculation.mlw = this.ahmData.landingWeight;
    this.calculation.ahmLastupdate = this.ahmData.lastupdate;
    this.calculation.fuel.taxi = this.ahmData.taxiFuel;
    this.calculation.fuel.onBoard = 0;
    this.calculation.fuel.trip = 0;
    this.calculation.fuel.ballast = 0;
  // Установка плотности топлива по умолчанию, если задано в AHM
    for (const fuel of this.ahmData.effectOfFuel) {
      if (fuel.default) {
        this.calculation.fuelDensity = fuel.density;
        break;
      }
    }
    this.calculation.crew.cockpit.weight = this.ahmData.standartWeights.crew.cockpit.weight;
    this.calculation.crew.cockpit.handLuggage = this.ahmData.standartWeights.crew.cockpit.handLuggage;
    this.calculation.crew.cabin.weight = this.ahmData.standartWeights.crew.cabin.weight;
    this.calculation.crew.cabin.handLuggage = this.ahmData.standartWeights.crew.cabin.handLuggage;
    // Поиск стандартного экипажа
    for (const crew of this.ahmData.dowChanges.crew) {
      if (crew.standard) {
        this.calculationCrewComposition = new DowChangesCrew(crew);
        this.calculation.crew.schema.cabin = crew.cabin;
        this.calculation.crew.schema.cockpit = crew.cockpit;
        this.calculation.crew.schema.extra = crew.extra;
        this.calculation.crew.cabin.amount = crew.cabin;
        this.calculation.crew.cockpit.amount = crew.cockpit;
        this.calculation.dow = crew.dow;
        this.calculation.doi = crew.doi;
        break;
      }
    }
    // Если стандартного экипажа нету, берет первый
    if (this.calculation.dow === null && this.ahmData.dowChanges.crew.length > 0) {
      this.calculationCrewComposition = new DowChangesCrew(this.ahmData.dowChanges.crew[0]);
      this.calculation.crew.schema.cabin = this.ahmData.dowChanges.crew[0].cabin;
      this.calculation.crew.schema.cockpit = this.ahmData.dowChanges.crew[0].cockpit;
      this.calculation.crew.schema.extra = this.ahmData.dowChanges.crew[0].extra;
      this.calculation.crew.cabin.amount = this.ahmData.dowChanges.crew[0].cabin;
      this.calculation.crew.cockpit.amount = this.ahmData.dowChanges.crew[0].cockpit;
      this.calculation.dow = this.ahmData.dowChanges.crew[0].dow;
      this.calculation.doi = this.ahmData.dowChanges.crew[0].doi;
    }

    let season = this.getSeason(this.flight.homeRoute.dtDepartureScheduled);

    this.calculation.passengers.weights = {...this.ahmData.standartWeights.passengers[season]}
    this.calculation.passengers.weights.handLuggageIncluded = this.ahmData.standartWeights.passengers.handLuggageIncluded;

  }

  changeVersionAhm(event) {
    if (event) {
      this.calculation.passengers.cabinArea = [];
      event.sections.forEach(section => {
        let area = new CabinArea(section);
        this.calculation.passengers.cabinArea.push(area);
      });
    } else return;
  }

  async calculate() {
    if (this.calculation.fuel.ballast > 0) {
      await this.restApi.getDeltaBallast(this.calculation).then(ballast => {
        this.deltaBallast = ballast;
      }, err => {
        this.displayError(err);
      });
    } else {
      this.deltaBallast = {deltaDow: 0, deltaDoi: 0};
    }
    return new Promise((resolve) => {
      this.calculation.lastupdate = new Date();
      this.restApi.calculate(this.calculation).subscribe(async data => {
        console.log('calc');
        console.log(data);

        this.calculation = JSON.parse(JSON.stringify(data));
        this.calculation.lastupdate = new Date();
        //Object.assign(this.calculation, data);
        this.calculation.passengers.cabinArea = [];
        data.passengers.cabinArea.forEach(el => {
          this.calculation.passengers.cabinArea.push(Object.assign(new CabinArea(), el))
        });
        await this.restApi.getChart(this.calculation).then(charts => {
          this.chartData = charts;
          let width = this.gravityCentreBlock.nativeElement.clientWidth;
          this.printDisabled = drawGravity(width, width, this.calculation.centreOfGravityChart, this.chartData, this.ahmData, this.context);
          let height = document.documentElement.clientHeight - 100;
          this.printDisabled = drawGravity(height, height, this.calculation.centreOfGravityChart, this.chartData, this.ahmData, this.contextBig);
          this.checkCalculationCorrect();
          this.calculateMaxTrafficLoad();
        }, err => {
          this.displayError(err);
        });

        resolve(this.calculation);
      }, err => {
        this.displayError(err);
      });
    });
  }

  async validationCalculate(calc, makeDocuments?: boolean) {

    this.error.errorMessage = '';

    // Check version
    if (!calc.configuration) {
      this.error.errorType = 'error';
      this.error.errorMessage = this.globalI18n.getMessage(ModuleName.WeightBalance, 'enterVersionAircraft');
      return false;
    }

    // Check fuel
    if (!calc.fuel.onBoard || !calc.fuel.taxi || !calc.fuel.trip || !calc.fuelDensity) {
      this.error.errorType = 'error';
      this.error.errorMessage = this.globalI18n.getMessage(ModuleName.WeightBalance, 'enterFuelData');
      return false;
    }

    if (calc.fuel.ballast > 0 && (calc.fuel.onBoard - calc.fuel.taxi - calc.fuel.trip) < calc.fuel.ballast) {
      this.error.errorType = 'error';
      this.error.errorMessage = this.globalI18n.getMessage(ModuleName.WeightBalance, 'fuelWeightDiscrepancy');
      return false;
    }

    // Check composition and DOW/DOI
    if (!calc.dow || !calc.doi || (!calc.crew.schema.cabin && !calc.crew.schema.cockpit)) {
      this.error.errorType = 'error';
      this.error.errorMessage = this.globalI18n.getMessage(ModuleName.WeightBalance, 'enterCrewComposition');
      return false;
    }

    // Check weight
    if (!calc.mtow || !calc.mlw) {
      this.error.errorType = 'error';
      this.error.errorMessage = this.globalI18n.getMessage(ModuleName.WeightBalance, 'enterWeightData');
      return false;
    }

    // check compartments
    let errCompartment = false;
    this.ahmData.holdsAndCompartments.aft.forEach(compartment => {
      if (compartment.maxWeight > 0 && this.getLoadCompartment(compartment.name) > compartment.maxWeight) {
        errCompartment = true;
      }
      compartment.bays.forEach(bay => {
        if (this.getLoadWeight(bay.name) > bay.maxWeight) {
          errCompartment = true;
        }
      })
    });

    this.ahmData.holdsAndCompartments.fwd.forEach(compartment => {
      if (compartment.maxWeight > 0 && this.getLoadCompartment(compartment.name) > compartment.maxWeight) {
        errCompartment = true;
      }
      compartment.bays.forEach(bay => {
        if (this.getLoadWeight(bay.name) > bay.maxWeight) {
          errCompartment = true;
        }
      })
    });

    if (errCompartment) {
      this.error.errorType = 'error';
      this.error.errorMessage += this.globalI18n.getMessage(ModuleName.WeightBalance, 'compartmentsOverloaded');
      return false;
    }

    // Check Passengers
    let errPassengers = false;
    this.calculation.passengers.cabinArea.forEach((el) => {
      if (el.maxPassengers < el.passengers.adult) {
        errPassengers = true;
      }
    });

    if (errPassengers) {
      this.error.errorType = 'error';
      this.error.errorMessage += this.globalI18n.getMessage(ModuleName.WeightBalance, 'maxValueCabinSectionExceeded');
      return false;
    }

    // Проверка на превышение весов только в момент создания документов для
    // экипажа
    if (makeDocuments) {
      if (calc.tow > calc.mtow) {
        this.error.errorType = 'error';
        this.error.errorMessage += this.globalI18n.getMessage(ModuleName.WeightBalance, 'maxTow');
        return false;
      }

      if (calc.lw > calc.mlw) {
        this.error.errorType = 'error';
        this.error.errorMessage += this.globalI18n.getMessage(ModuleName.WeightBalance, 'maxLw');
        return false;
      }
    }

    // Check payload and seating
    let warning = false;

    if (this.allLoadWeight() != this.flight.loading.luggage + this.flight.loading.cargo + this.flight.loading.mail) {
      this.error.errorMessage += this.globalI18n.getMessage(ModuleName.WeightBalance, 'warningPayload') + '\n';
      warning = true;
    }
    if (this.placesDeclared() != this.getTotalPassenger()) {
      this.error.errorMessage += this.globalI18n.getMessage(ModuleName.WeightBalance, 'warningZoneSeating') + '\n';
      warning = true;
    }

    let validate = true;

    if (warning) {
      this.error.errorMessage += this.globalI18n.getMessage(ModuleName.WeightBalance, 'continue');
      this.error.errorType = 'warning';
      this.modalType = 'setAnswer'
      await this.waitAnswer().then(async res => {
        if (res) {
          validate = true;
        } else {
          validate = false;
        }
      });
    }

    return validate;
  }

  async addCalculate() {
    let res = await this.validationCalculate(this.calculation);
    if (res) {
      this.calculate().then((data) => {
        this.restApi.addCalculate(this.calculation).subscribe(async (data) => {
          // Получение данных из заголовков ответа
          const location = data.headers.get('Location');
          // Регулярное выражение, в 2 группе будет идентификатор расчета
          let getIdFromHeader = new RegExp("(\/calculations\/)([0-9]*)", "g");
          this.calculation.id = +getIdFromHeader.exec(location)[2];
          this.usesAction = 'editCalculation';
          this.editCalculation(this.flight.id);
        }, err => {
          this.displayError(err);
        });
      });
    }
  }

  async saveCalculate(id, res?) {
    res = res || await this.validationCalculate(this.calculation);
    if (res) {
      await this.calculate().then(async (data) => {
        await this.restApi.updateCalculate(id, this.calculation).then(data => {
        }, err => {
          this.displayError(err);
        });
      });
    }
  }

  changeHandBaggage() {
    this.calculation.passengers.weights.handLuggageIncluded = !this.calculation.passengers.weights.handLuggageIncluded;
    if (this.calculation.passengers.weights.handLuggageIncluded) this.calculation.passengers.weights.handLuggage = 0;
  }

  changeFktIncluded() {
    this.calculation.fktIncludedInDowDoi = !this.calculation.fktIncludedInDowDoi;
  }

  changeNotocNil() {
    this.calculation.notoc.releasedByAnother = !this.calculation.notoc.releasedByAnother;
  }

  async printDocument(url) {
    await this.restApi.loadDocument(this.flight.id, this.currentDocumentEditions, url).then(data => {
      this.documentText = data.text;
      // Пауза перед печатью, что бы ангуляр обновил элемент из которого берется текс для печати
      setTimeout(() => {
        this.print.nativeElement.click();
      }, 250);
    }, err => {
      this.displayError(err);
    });
  }

  async loadDocument(url: string){
    await this.restApi.loadDocument(this.flight.id, this.currentDocumentEditions, url).then(data => {
      this.documentText = data.text;
    }, err => {
      this.displayError(err);
    });
  }

  async createDocuments() {
    let res = await this.validationCalculate(this.calculation, true);
    if (res) {
      // Перед формированием документов предварительное сохранение расчета
      // if (this.usesAction == 'addCalculation') {
      //   this.addCalculate();
      // } else if (this.usesAction == 'editCalculation') {
      //   this.saveCalculate(this.calculation.id);
      // }
      // Формирование новой редакции документов
      // Увеличение
      await this.saveCalculate(this.calculation.id, res);
      return this.restApi.createDocuments(this.flight.id).subscribe(data => {
        // После удочного создания новой редакции документов
        // нужно обновить порядковый номер документов в расчете
        this.calculation.edno++;
        this.saveCalculate(this.calculation.id, res);
        this.getDocumentEditions();
      }, err => {
        this.displayError(err);
      });
    }
  }

  saveDocument(url: string) {
    return this.restApi.saveDocument(this.flight.id, this.currentDocumentEditions, url).subscribe(data => {
      const blob = new Blob([data], { type: 'application' });
      this.fileSaverService.save(blob, this.flight.airlineIata +
                                 this.flight.flightNumber + '_' +
                                 this.flight.homeRoute.dtDepartureScheduled + '_'
                                 + url + '.pdf');
    }, err => {
      this.displayError(err);
    });
  }

  async getDocumentEditions() {
    this.currentDocumentView = 0;
    this.documentText = null;
    return await this.restApi.getDocumentEditions(this.flight.id).then(data => {
      this.documentEditions = data;
      // Установка последней редакции активной в списке документов
      if (this.documentEditions.length > 0) {
        this.currentDocumentEditions = this.documentEditions[this.documentEditions.length - 1].edno;
      }
    }, err => {
      this.displayError(err);
    });
  }

  async loadReferences() {
    for (const key in this.references) {
      if (Object.prototype.hasOwnProperty.call(this.references, key)) {
        this.selectLoadAnimation[key] = true;
        if (key == 'aircraft_types') {
          await this.restApi.getReference(key).then( aircrafts => {
            aircrafts.forEach(el => {
              let aicraft = new ReferanceAircraft();
              Object.assign(aicraft, el);
              this.references[key] = [...this.references[key], aicraft];
            });
          }, err => {
            this.displayError(err);
          });
        } else {
          await this.restApi.getReference(key).then(data => {
            this.references[key] = data;
          }, err => {
            this.displayError(err);
          });
        }
        this.selectLoadAnimation[key] = false;
      }
    }
  }

  onScrollToEndNgSelect(name) {
    this.fetchMore(name);
  }

  onScrollNgSelect({ end }, name) {
    if (this.selectLoadAnimation[name] || this.references[name].length <= this.buffer[name].length) {
      return;
    }

    if (end + this.numberOfItemsFromEndBeforeFetchingMore >= this.buffer[name].length) {
      this.fetchMore(name);
    }
  }

  private fetchMore(name) {
    const len = this.buffer[name].length;
    const more = this.references[name].slice(len, this.bufferSize[name] + len);
    this.selectLoadAnimation[name] = true;
    this.buffer[name] = this.buffer[name].concat(more);
    this.selectLoadAnimation[name] = false;
  }

  /**
   * Функция обработки значения из редактируемой таблицы
   * @param {string} value Строка, введеная пользователем
   */
  toNumber(value) {
    let num = parseFloat(value.replace(/<\/?[^>]+(>|$)/g, "").replace(/^(-)|[^0-9.,]+/ig, '$1').replace(/^-+/g, '-').replace(',', '.'));
    return num || null;
  }

  /**
   * Функция поиска в выпадающим списке по нескольким параметрам
   * @param {string} term Строка для поиска введеня пользователем
   * @param {ReferanceAirline} item Элемент для поиска
   */
  customSelectSearch(term: string, item) {
    term = term.toLowerCase();
    return item.iata.toLowerCase().indexOf(term) > -1 ||
           item.icao.toLowerCase().indexOf(term) > -1 ||
           item.name[0].toLowerCase().indexOf(term) > -1 ||
           item.name[1].toLowerCase().indexOf(term) > -1
  }

  getById(array, id: number) {
    if (!array || array.length === 0) return null;
    let res = array.filter(el => el.id === id)[0];
    if (res) return res;
    else return null;
  }

  getProp(object, id, prop) {
    if(object && object.length > 0 && id) {
      let res = this.getById(object, id);
      if (res && res[prop])
        return res[prop];
      else
        return null;
    } else return null;
  }

  setProperty(prop, value) {
    if (prop = value) return true;
  }

  addItem(array, item) {
    array.push(item);
  }

  loadWindow() {
    switch (this.activeWindow) {
      case 'ahm-list': {
        this.loadAhms();
        break;
      }
      case 'flight-list': {
        this.loadFlights();
        break;
      }
    }
  }

  changeTab(event: { target: { id: string; }; }) {
    this.activeTab = event.target.id;
    // this.loadTab();
  }

  showTab(item: string): boolean {
    return item === this.activeTab;
  }

  showFlightCardTab(item: string): boolean {
    return item === this.flightCardActiveTab;
  }

  parseDate(dateString: string, time?): Date {
    if (time && dateString) {
      return new Date(dateString + 'T' + time);
    } else if (!time && dateString) {
      return new Date(dateString);
    }
    return null;
  }

  parseTime(date, dateString: string): Date {
    if (date.value && date.value + 'T' + dateString) {
      return new Date(date.value + 'T' + dateString);
    }
    return null;
  }

  addRoutePoint() {
    const num = this.flight.route.length;
    this.flight.route[num] = new Route();
    this.flight.route[num].order = num;
  }

  getLoadWeight(name) {
    return this.calculation.holdsAndCompartments.filter(el => el.name === name).reduce((acc, el) => { return acc += el.weight }, 0)
  }

  getLoadCompartment(section) {
    return this.calculation.holdsAndCompartments.filter(el => el.name[0] === section).reduce((acc, el) => { return acc += el.weight }, 0)
  }

  allLoadWeight() {
    return this.calculation.holdsAndCompartments.reduce((acc, el) => {
      if (el.type !== 'FKT' && el.type !== 'Ballast') acc += el.weight;
      return acc;
    }, 0);
  }

  addItemByClass(array, item) {
    array.push(new item());
  }

  addToDeleteQueue(value) {
    if (this.deleteQueue.has(value)) {
      this.deleteQueue.delete(value);
    } else {
      this.deleteQueue.add(value);
    }
  }

  getSeason(date) {
    date  = new Date(date);
    const lastMarch = new Date('03-31-' + date.getFullYear());
    let day = lastMarch.getDay();
    let startSummer = lastMarch;
    if (day !== 0) {
      startSummer.setDate(startSummer.getDate() - (6-day));
    }

    const lastOctober = new Date('10-31-' + date.getFullYear());
    day = lastOctober.getDay();
    let startWinter = lastOctober;
    if (day !== 0) {
      startWinter.setDate(startWinter.getDate() - (6-day));
    }

    let season = '';
    if (date > startSummer && date < startWinter) {
      season = 'summer';
    } else {
      season = 'winter';
    }

    return season;
  }

  deleteRoute() {
    if (this.flight.route.length < 3) {
      this.error.errorMessage = this.globalI18n.getMessage(ModuleName.WeightBalance, 'warningRoute');
    } else {
      const deleteQueue = this.deleteQueue;
      this.flight.route  = this.flight.route.filter((el, i) => {
        if (!deleteQueue.has(i)) {
          return el;
        }
      });
      this.deleteQueue = new Set();
    }

    this.flight.route[0].dtArrivalScheduled = null;
    this.flight.route[0].dtArrivalEstimated = null;
    this.flight.route[0].dtArrivalFact = null;

    this.flight.route[this.flight.route.length - 1].dtDepartureScheduled = null;
    this.flight.route[this.flight.route.length - 1].dtDepartureEstimated = null;
    this.flight.route[this.flight.route.length - 1].dtDepartureFact = null;
    //this.checkTypeFlight();
  }

  async deleteElement(array, index) {
    this.error.errorMessage = this.globalI18n.getMessage(ModuleName.WeightBalance, 'deleteItem');
    this.error.errorType = 'warning';
    this.modalType = 'setAnswer';
    await this.waitAnswer().then(async res => {
      if (res) {
        array.splice(index, 1);
      }
    });
  }

  async waitAnswer() {
    let res = await this.checkAnswer();
    return res;
  }

  checkAnswer() {
    return new Promise(resolve => {
      let interval = setInterval(() => {
        if (this.userAnswer !== null) {
          const answer = this.userAnswer;
          this.clearErrorMess();
          clearInterval(interval);
          resolve(answer);
        }
      }, 500);
   });
  }

  clearErrorMess() {
    this.error.errorMessage = '';
    this.error.errorType = '';
    this.modalType = '';
    this.userAnswer = null;
  }

  changeWeightAndBalanceView(showItem) {
    if (showItem === 'table') {
      this.chartIsShow = false;
    } else {
      this.chartIsShow = true;
    }

  }

  clearFilter() {
    this.filterParams = new FilterParams();
    this.filterApply = false;
    this.filterFlight();
  }

  filterApp() {
    this.filterApply = true;
    this.filterFlight();
  }

  filterSwitch() {
    this.filterApply = !this.filterApply;
    this.filterFlight();
  }

  filterFlight() {
    this.showFilter = false;
    this.loadFlights();
  }

  clearFilterParametr(field: string, event) {
    event.stopPropagation();
    if (field === 'date') {
      delete this.filterParams['start'];
      delete this.filterParams['finish'];
    } else {
      delete this.filterParams[field];
    }
    if (this.quickSearchCheck()) {
      this.filterApply = false;
    }
    this.filterFlight();
  }

  quickSearchCheck() {
    const newFilter = new FilterParams();
    return JSON.stringify(this.filterParams) === JSON.stringify(newFilter);
  }

  findAircraftTypeByTail() {
    if (this.flight.tailId) {
      for (const tail of this.references.tails) {
        if (tail.id === this.flight.tailId) {
          this.flight.aircraftTypeId = tail.aircraftTypeId;
          break;
        }
      }
    }
  }

  updateDowDoiAfterCrewChange($event) {
    if ($event) {
      this.calculation.dow = $event.dow;
      this.calculation.doi = $event.doi;
      this.calculation.crew.schema.cabin = $event.cabin;
      this.calculation.crew.schema.cockpit = $event.cockpit;
      this.calculation.crew.schema.extra = $event.extra;
      this.calculationCrewComposition = <DowChangesCrew>$event;
    }
  }

  setUldWeight($event, index) {
    this.calculation.holdsAndCompartments[index].uldWeight = $event.weight;
  }

  getTotalPassenger() {
    return this.calculation.passengers.cabinArea.reduce((acc, element) => acc += element.passengers.adult, 0);
  }

  placesDeclared() {
    return this.calculation.crew.additional.inCabin + this.flight.loading.business + this.flight.loading.economy;
  }

  getDocumentValue(edno, field): string | Date {
    for (let edition of this.documentEditions) {
      if (edition.edno === edno) {
        if (field == 'preparedBy') {
          return edition.preparedBy;
        } else if (field == 'preparedAt') {
          return edition.preparedAt.toLocaleString();
        }
      }
    }
    return '';
  }

  displayError(err) {
    console.log(err);

    if (err.type) {
      this.error.errorType = err.type;
      if (typeof err.message == 'object') {
        if (this.globalSettings.language == 'en') {
          this.error.errorMessage = err.message[0];
        } else {
          this.error.errorMessage = err.message[1];
        }
      } else {
        this.error.errorMessage = err.message;
      }
    } else {
      this.error.errorMessage = err;
    }
  }

  verifiedMessage: Array<any> = []; // для валидации сообщения
  /**
   * Разбивает сообщение на строки
   *
   * @param {text} текст сообщения
   * @param {textarea} элемент поля ввода
   */
   splitIntoLines(text: string, textarea?) {
    let coursorPosition;
    if (textarea) {
      coursorPosition = textarea.selectionStart;
    }
    let contentArray = [];
    if (text && text != '') {
      contentArray = text.toUpperCase().split(/\n|\r\n/);
    }
    const maxLength = 30;
    const newContent = [];
    this.verifiedMessage = [];
    contentArray.forEach(element => {

      if (element.length > maxLength) {
        if (element[maxLength] != ' ') {
          let line = element.slice(0, maxLength);
          let lineEndIndex = line.lastIndexOf(' ') + 1;
          if (lineEndIndex == 0) {
            newContent.push(element);
            this.verifiedMessage.push(Array(true, element, this.globalI18n.getMessage(ModuleName.ComMan, 'textLineLong')));
          } else {
            while (element.length > maxLength) {
              const lineSlice = line.slice(0, lineEndIndex - 1);
              newContent.push(lineSlice);
              this.verifiedMessage.push(Array(false, lineSlice));
              element = element.slice(lineEndIndex);
              line = element.slice(0, maxLength);
              lineEndIndex = line.lastIndexOf(' ') + 1;
            }
            newContent.push(element);
            this.verifiedMessage.push(Array(false, element));
          }
        } else {
          newContent.push(element.slice(0, maxLength));
          this.verifiedMessage.push(Array(false, element.slice(0, maxLength)));
          newContent.push(element.slice(maxLength));
          this.verifiedMessage.push(Array(false, element.slice(maxLength)));
        }
      } else {
        newContent.push(element);
        this.verifiedMessage.push(Array(false, element));
      }
    });
    if (textarea) {
      textarea.value = newContent.join('\r\n');
      textarea.setSelectionRange(coursorPosition, coursorPosition);
    }
    return newContent.join('\r\n');
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    let dataWell = this.ahmData.centreOfGravityChart[0];

    if (this.gravityCentreBlock) {
      let width = this.gravityCentreBlock.nativeElement.clientWidth;
      this.context = createCanvas(width, width, this.gravityCentreBlock.nativeElement);
      this.printDisabled = drawGravity(width, width, dataWell, this.chartData, this.ahmData, this.context);
    }

    if (this.gravityCentreBlockBig) {
      let height = document.documentElement.clientHeight - 100;
      this.contextBig = createCanvas(height, height, this.gravityCentreBlockBig.nativeElement);
      this.printDisabled = drawGravity(height, height, dataWell, this.chartData, this.ahmData, this.contextBig);
    }
    this.checkCalculationCorrect();
    event.target.innerWidth;
  }

  calculateMaxTrafficLoad() {
    this.maxTrafficLoad = null;
    const trafficLoadVarian1 = this.calculation.mtow - this.calculation.dow -
                              (this.calculation.fuel.onBoard - this.calculation.fuel.taxi);
    const trafficLoadVarian2 = this.calculation.mlw - this.calculation.dow  -
                               (this.calculation.fuel.onBoard - this.calculation.fuel.taxi - this.calculation.fuel.trip);
    const trafficLoadVarian3 = this.ahmData.zeroFuelWeight - this.calculation.dow;
    this.maxTrafficLoad = Math.min(trafficLoadVarian1, trafficLoadVarian2, trafficLoadVarian3);
    if (this.maxTrafficLoad < 0) {
      this.maxTrafficLoad = null;
    }
  }

  /**
   * Функция поиска в выпадающим списке по нескольким параметрам
   * @param {string} term Строка для поиска введеная пользователем
   * @param {ReferanceTail} item Элемент для поиска
   */
  customSelectSearchTail(term: string, item: ReferanceTail) {
    // Преобразуется все в верхний регистр
    // Из строк убираются все символы форматирования бортового номера
    // Производится поиск на вхождение по упрощенным строкам
    return item.tail.toUpperCase().replace(/[-=_+\\\/\s]/g, '').indexOf(term.toUpperCase().replace(/[-=_+\\\/\s]/g, '')) !== -1;
  }

  // Функция сортировки массива Авиакомпаний по коду IATA для удобного
  // просмотра и поиска по выпадающему списку
  get airlinesSortIata(): ReferanceAirline[] {
    return this.references.airlines.sort((a, b) => a.iata.localeCompare(b.iata));
  }

  // Функция сортировки массива Авиакомпаний по коду ICAO для удобного
  // просмотра и поиска по выпадающему списку
  get airlinesSortIcao(): ReferanceAirline[] {
    return this.references.airlines.sort((a, b) => a.icao.localeCompare(b.icao));
  }

  get airportsSortIata(): ReferanceAirport[] {
    return this.references.airports.sort((a, b) => a.iata.localeCompare(b.iata));
  }

  checkCalculationCorrect() {
    if (this.chartData && this.chartData.length > 0 && !this.printDisabled) {
      this.calculationCorrect = true;
    } else {
      this.calculationCorrect = false;
    }
  }
}

export class Flight {
    constructor(private homeAirport: number) {
        this.route = [new Route(), new Route()];
        this.airlineId = null;
        this.airlineIata = '';
        this.airlineIcao = '';
        this.flightNumber = '';
        this.aircraftTypeId = null;
        this.aircraftTypeIata = '';
        this.tailId = null;
        this.isDeleted = false;
        this.crew = [null, null];
        this.cateringWeight = null;
        this.fuel = [null, null, null, null];
    }
    id: number;
    airlineId: number;
    airlineIata: string;
    airlineIcao: string;
    flightNumber: string;
    aircraftTypeId: number;
    aircraftTypeIata: string;
    tailId: number;
    isDeleted: boolean;
    crew: Array<number>;
    cateringWeight: number;
    fuel: Array<number>;
    route: Array<Route>;
    lastupdate: Date;

    get startRoute() {
        if (this.route && Array.isArray(this.route))
            return this.route.sort((a, b) => a.order - b.order)[0];
        else return null;
    }

    get finishRoute() {
        if (this.route && Array.isArray(this.route))
            return this.route.sort((a, b) => b.order - a.order)[0];
        else return null;
    }

    get homeRoute() {
      if (this.route && Array.isArray(this.route)) {
        for (let i = 0; i < this.route.length; i++) {
          if (this.route[i].airportId === this.homeAirport) {
            let route = new Route()
            Object.assign(route, this.route[i])
            return route;
          }
        }
      }
      return null;
    }

    get nextRoute() {
      if (this.route && Array.isArray(this.route)) {
        for (let i = 0; i < this.route.length; i++) {
          if (this.route[i].airportId === this.homeAirport && this.route[i + 1]) {
            let route = new Route()
            Object.assign(route, this.route[i + 1])
            return route;
          }
        }
      }
        else return null;
    }

    get loading() {
      let result = {
        luggage: 0,
        cargo: 0,
        mail: 0,
        adult: 0,
        child: 0,
        infant: 0,
        business: 0,
        economy: 0
      };
      if (this.route && Array.isArray(this.route)) {
        let flag = false;
        for (let route of this.route) {
          if (route.airportId === this.homeAirport) {
            flag = true;
          } else if (flag == true) {
            result.luggage += route.baggageWeight[0] + route.baggageWeight[1];
            result.cargo += route.cargo[0] + route.cargo[1];
            result.mail += route.mail[0] + route.mail[1];
            result.adult += route.adult[0] + route.adult[1];
            result.child += route.child[0] + route.child[1];
            result.infant += route.infant[0] + route.infant[1];
            result.business += route.business[0] + route.business[1];
            result.economy += route.economy[0] + route.economy[1];
          }
        }
      }
      return result;
    }

    public isDeparture(value: number) {
      let result = false;
      let flag = false;
      for (let route of this.route) {
        if (route.airportId === this.homeAirport) {
          flag = true;
        } else if (flag == true && route.airportId === value) {
          result = true;
        }
      }
      return result;
    }
}

export class Route {
    constructor() {
        this.order = null;
        this.airportId = null;
        this.airportIata = '';
        this.dtArrival = [null, null, null];
        this.dtDeparture = [null, null, null];
        this.adult = [null, null, null, null];
        this.child = [null, null, null, null];
        this.infant = [null, null, null, null];
        this.business = [null, null, null, null];
        this.economy = [null, null, null, null];
        this.baggageWeight = [null, null, null, null];
        this.baggageUnit = [null, null, null, null];
        this.baggagePayed = [null, null, null, null];
        this.cargo = [null, null, null, null];
        this.mail = [null, null, null, null];
    }
    order: number;
    airportId: number;
    airportIata: string;
    dtArrival: Array<Date>;
    dtDeparture: Array<Date>;
    adult: Array<number>;
    child: Array<number>;
    infant: Array<number>;
    business: Array<number>;
    economy: Array<number>;
    baggageWeight: Array<number>;
    baggageUnit: Array<number>;
    baggagePayed: Array<number>;
    cargo: Array<number>;
    mail: Array<number>;
    lastupdate: Date;

    private isDateNull(value: Date): boolean {
      if (!value || +value[0] < 2) {
        return false;
      } else {
        return true;
      }
    }

    get dtArrivalScheduled(): Date {
      if (this.isDateNull(this.dtArrival[0])) {
        return this.dtArrival[0];
      } else {
        return null;
      }
    }

    set dtArrivalScheduled(value: Date) {
      this.dtArrival[0] = value;
    }

    get dtArrivalEstimated(): Date {
      if (this.isDateNull(this.dtArrival[1])) {
        return this.dtArrival[1];
      } else {
        return null;
      }
    }

    set dtArrivalEstimated(value: Date) {
      this.dtArrival[1] = value;
    }

    get dtArrivalFact(): Date {
      if (this.isDateNull(this.dtArrival[2])) {
        return this.dtArrival[2];
      } else {
        return null;
      }
    }

    set dtArrivalFact(value: Date) {
      this.dtArrival[2] = value;
    }

    get dtDepartureScheduled(): Date {
      if (this.isDateNull(this.dtDeparture[0])) {
        return this.dtDeparture[0];
      } else {
        return null;
      }
    }

    set dtDepartureScheduled(value: Date) {
      this.dtDeparture[0] = value;
    }

    get dtDepartureEstimated(): Date {
      if (this.isDateNull(this.dtDeparture[1])) {
        return this.dtDeparture[1];
      } else {
        return null;
      }
    }

    set dtDepartureEstimated(value: Date) {
      this.dtDeparture[1] = value;
    }

    get dtDepartureFact(): Date {
      if (this.isDateNull(this.dtDeparture[2])) {
        return this.dtDeparture[2];
      } else {
        return null;
      }
    }

    set dtDepartureFact(value: Date) {
      this.dtDeparture[2] = value;
    }
}

export class Ahm {
    constructor() {
        this.airlineId = null;
        this.aircraftTypeId = null;
        this.revision = null;
        this.dtRange = [new Date(), new Date('2099-01-01')];
        this.lastupdate = new Date();
    }
    id: number;
    airlineId: number;
    airlineName: string;
    aircraftTypeId: number;
    aircraftTypeName: string;
    revision: number;
    dtRange: Array<Date>;
    lastupdate: Date;

    /**
     * Функции чтения/записи времени актуальности записи
     */

    get dtRangeStart(): Date {
      if ((this.dtRange) && (this.dtRange.length >= 1) && this.dtRange[0]!==null) {
        return new Date(this.dtRange[0]);
      } else {
        return null;
      }
    }

    set dtRangeStart(value: Date) {
      this.dtRange[0] = value;
    }

    get dtRangeFinish(): Date {
      if ((this.dtRange) && (this.dtRange.length === 2) && this.dtRange[1]!==null) {
        return new Date(this.dtRange[1]);
      } else {
        return null;
      }
    }

    set dtRangeFinish(value: Date) {
      this.dtRange[1] = value;
    }
}

export class AhmData {
    constructor() {
        this.units = new Units();
        this.centreOfGravityChart = [new CentreOfGravityChart()];
        this.effectOfFuel = [{
            density: null,
            default: null,
            balance: [new CentreOfGravityChartItem()]
        }];
        this.standartWeights = {
            crew: {
                cockpit: {
                    weight: null,
                    handLuggage: null,
                },
                cabin: {
                    weight: null,
                    handLuggage: null,
                },
                handLuggageIncluded: true,
            },
            passengers: {
                winter: new Passengers(),
                summer: new Passengers(),
                handLuggageIncluded: true
            }

        },
        this.dowChanges = {
            crew: []
        };
        this.configurations = [];
        this.holdsAndCompartments = {
            fwd: [],
            aft: []
        };
        this.stabilizerTrim = [];
        this.uldTypes = [];
        this.dowItemsIncluded = [1]; // ЭТО ЗАГЛУШКА, НАДО БУДЕТ УБРАТЬ!!!!
        this.dtRange = [new Date(), new Date('2099-01-01')];
        this.lastupdate = new Date();

    }
    id: number;
    tailId: number;
    tailName: string
    airlineId: number;
    airlineIata: string;
    airlineIcao: string;
    ahmId: number;
    ahm: string;
    units: Units;
    k: number;
    c: number;
    referenceArm: number;
    macRc: number;
    lemacLerc: number;
    dow: number;
    doi: number;
    mac: number;
    zeroFuelWeight: number;
    landingWeight: number;
    takeOffWeight: number;
    rampTaxiWeight: number;
    centreOfGravityChart: Array<CentreOfGravityChart>;
    effectOfFuel: Array<{
        density: number;
        default: boolean;
        balance: Array<CentreOfGravityChartItem>
    }>;
    taxiFuel: number;
    holdsAndCompartments: {
        fwd: Array<HoldsAndCompartments>,
        aft: Array<HoldsAndCompartments>,
      };
    configurations: Array<AhmConfiguration>;
    dowItemsIncluded: Array<number>
    dowChanges: {
        crew: Array<DowChangesCrew>
    };
    standartWeights: {
        crew: {
            cockpit: {
                weight: number;
                handLuggage: number;
            };
            cabin: {
                weight: number;
                handLuggage: number;
            };
            handLuggageIncluded: boolean;
        };
        passengers: {
            winter: Passengers;
            summer: Passengers;
            handLuggageIncluded: boolean;
          }
    };
    uldTypes: Array<UldTypes>;
    stabilizerTrim: Array<StabilizerTrim>;
    dtRange: Array<Date>;
    lastupdate: Date;

    /**
     * Функции чтения/записи времени актуальности записи
     */

    get dtRangeStart(): Date {
      if ((this.dtRange) && (this.dtRange.length >= 1) && this.dtRange[0]!==null) {
        return new Date(this.dtRange[0]);
      } else {
        return null;
      }
    }

    set dtRangeStart(value: Date) {
      this.dtRange[0] = value;
    }

    get dtRangeFinish(): Date {
      if ((this.dtRange) && (this.dtRange.length === 2) && this.dtRange[1]!==null) {
        return new Date(this.dtRange[1]);
      } else {
        return null;
      }
    }

    set dtRangeFinish(value: Date) {
      this.dtRange[1] = value;
    }

    get crewStandart() {
      if (this.dowChanges.crew && this.dowChanges.crew.length > 0) {
        let res = this.dowChanges.crew.filter(el => el.standard)[0];
        if (res) {
          return res.cockpit + '/' + res.cabin ;
        }
      }
      return '';
  }
}

export class UldTypes {
  constructor() {
    this.name = '';
    this.weight = null;
    this.maxWeight = null;
    this.maxVolume = null;
  }
  name: string;
  weight: number;
  maxWeight: number;
  maxVolume: number;
}

export class StabilizerTrim {
  constructor() {
    this.note = '';
    this.values = [];
  }
  note: string;
  values: Array<StabToValues>
}

export class StabToValues {
  constructor() {
    this.weight = null;
    this.trims = [];
  }
  weight: number;
  trims: Array<StabToTrims>;
}

export class StabToTrims {
  constructor() {
    this.mac = null;
    this.trim = null;
  }
  mac: number;
  trim: number;
}

export class HoldsAndCompartments {
  constructor() {
    this.name = '';
    this.type = '';
    this.maxWeight = null;
    this.volume = null;
    this.index = null;
    this.group = '';
    this.bays = [];
  }
  name: string;
  type: string;
  maxWeight: number;
  volume: number;
  index: number;
  group: string;
  bays: Array<HoldsAndCompartments>;
}

export class DowChangesCrew {
  constructor(obj) {
    obj && Object.assign(this, obj);
  }
  cockpit: number;
  cabin: number;
  extra: number;
  dow: number;
  doi: number;
  standard: boolean;

  get name(): string  {
    if (this.dow !== 0 && this.doi !== 0) {
      if (this.extra)
        return this.cockpit.toString() + ' / ' + this.cabin.toString() + ' / ' + this.extra.toString();
      else
        return this.cockpit.toString() + ' / ' + this.cabin.toString();
    } else {
      return '';
    }
  }

  get nameDowDoi(): string  {
    if (this.dow !== 0 && this.doi !== 0) {
      if (this.extra)
        return this.cockpit.toString() + ' / ' + this.cabin.toString() + ' / ' + this.extra.toString() + ' (' + this.dow + ' / ' + this.doi + ')';
      else
        return this.cockpit.toString() + ' / ' + this.cabin.toString() + ' (' + this.dow + ' / ' + this.doi + ')';
    } else {
      return '';
    }
  }
}

export class Units {
    constructor() {
        this.weight = null;
        this.length = null;
        this.volume = null;
        this.liquidVolume = null;
    }
    weight: string;
    length: string;
    liquidVolume: string;
    volume: string;
    fuelDensity: string;
    moments: string;
}

export class CentreOfGravityChart {
  constructor() {
    this.fwd = {
        zeroFuel: [],
        takeOff: [],
        landing: [],
    };
    this.aft = {
        zeroFuel: [],
        takeOff: [],
        landing: [],
    };
    this.note = '';
    this.conditions = [];
  }
  fwd: {
      zeroFuel: Array<CentreOfGravityChartItem>;
      takeOff: Array<CentreOfGravityChartItem>;
      landing: Array<CentreOfGravityChartItem>;
  };
  aft: {
      zeroFuel: Array<CentreOfGravityChartItem>;
      takeOff: Array<CentreOfGravityChartItem>;
      landing: Array<CentreOfGravityChartItem>;
  };
  note: string;
  conditions: Array<CentreOfGravityChartCondition>
};

export class CentreOfGravityChartCondition {
  constructor() {
    this.from = null;
    this.to = null;
    this.type = "";
  }
  from: number;
  to: number;
  type: string;
}

export class CentreOfGravityChartItem {
    constructor() {
        this.weight = null;
        this.index = null;
    }
    weight: number;
    index: number;
}

export class AhmConfiguration {
  name: string;
  sections: Array<ConfigurationSection>;
}

export class ConfigurationSection {
  constructor() {
    this.name = '';
    this.rows = [];
    this.maxPassengers = null;
    this.index = null;
  }
  name: string;
  rows: Array<number>;
  maxPassengers: number;
  index: number;

  get rowFrom(): number  {
    if (this.rows && this.rows.length > 0) {
      return this.rows[0];
    } else {
      return null;
    }
  }

  set rowFrom(value: number) {
    this.rows[0] = value;
  }

  get rowTo(): number  {
    if (this.rows && this.rows.length > 1) {
      return this.rows[1];
    } else {
      return null;
    }
  }

  set rowTo(value: number) {
    this.rows[1] = value;
  }
}

export class Passengers {
    constructor() {
        this.adult = 0;
        this.female = 0;
        this.child = 0;
        this.infant = 0;
        this.handLuggage = 0;
    }
    adult: number;
    female: number;
    child: number;
    infant: number;
    handLuggage: number;
    handLuggageIncluded: boolean;
}

export class Calculation {
  constructor() {
    this.tailId = null;
    this.tailsAhmDataId =null;
    this.configuration = '';
    this.captain = '';
    this.crew = {
        schema: {
            cockpit: 0,
            cabin: 0,
            extra: 0
        },
        cockpit: {
            weight: 0,
            baggage: 0,
            amount: 0,
            handLuggage: 0,
            handLuggageIncluded: true
        },
        cabin: {
            weight: 0,
            baggage: 0,
            amount: 0,
            handLuggage: 0,
            handLuggageIncluded: true
        },
        extra: {
          amount: 0,
          baggage: 0,
          handLuggage: 0,
          handLuggageIncluded: false,
          weight: 0,
        },
        additional: {
          cockpit: 0,
          cabin: 0,
          inCabin: 0
        }
    };
    this.passengers = {
        weights: new Passengers(),
        cabinArea: []
    };
    this.handLuggage = null;
    this.fuel = {
        onBoard: null,
        taxi: null,
        trip: null,
        ballast: null,
    };
    this.holdsAndCompartments = [];
    this.fktIncludedInDowDoi = false;
    this.centreOfGravityChart = new CentreOfGravityChart();
    this.fuelDensity = null;
    this.captain = '';
    this.dow = null;
    this.doi = null;
    this.maczfw = null;
    this.dli = null;
    this.lizfw = null;
    this.ttl = null;
    this.zfw = null;
    this.mactow = null;
    this.tow = null;
    this.lw = null;
    this.litow = null;
    this.maclaw = null;
    this.lilaw = null;
    this.stabTo = null;
    this.preparedBy = '';
    this.preparedAt = new Date();
    this.documentId = '';
    this.lastupdate = new Date();
    this.edno = null;
    this.notoc = {
      releasedByAnother: true,
      dangerous: [],
      other: [],
    };
    this.si = '';
  }
    id: number;
    tailId: number;
    flightId: number;
    tailsAhmDataId: number;
    configuration: string;
    crew: {
        schema: {
          cockpit: number,
          cabin: number,
          extra: number
        },
        cockpit: {
            weight: number;
            baggage: number;
            amount: number;
            handLuggage: number;
            handLuggageIncluded: boolean;
        };
        cabin: {
            weight: number;
            baggage: number;
            amount: number;
            handLuggage: number;
            handLuggageIncluded: boolean;
        },
        extra: {
          amount: number,
          baggage: number,
          handLuggage: number,
          handLuggageIncluded: boolean,
          weight: number,
        },
        additional: {
          cockpit: number,
          cabin: number,
          inCabin: number
        }
    };
    passengers: {
        weights: Passengers;
        cabinArea: Array<CabinArea>;
    };
    handLuggage: number;
    fuel: {
        onBoard: number;
        taxi: number;
        trip: number;
        ballast: number;
    };
    holdsAndCompartments: Array<HoldsAndCompartmentsCalc>;
    fktIncludedInDowDoi: boolean;
    centreOfGravityChart: CentreOfGravityChart;
    fuelDensity: number;
    captain: string;
    dow: number;
    doi: number;
    maczfw: number;
    dli: number;
    lizfw: number;
    ttl: number;
    zfw: number;
    mactow: number;
    tow: number;
    lw: number;
    litow: number;
    maclaw: number;
    lilaw: number;
    stabTo: number;
    preparedBy: string;
    preparedAt: Date;
    documentId: string;
    lastupdate: Date;
    edno: number;
    mtow: number;
    mlw: number;
    ahmLastupdate: Date;
    notoc: {
      releasedByAnother: boolean;
      dangerous: Array<NotocDangerous>;
      other: Array<NotocOther>;
    };
    si: string;
}

export class NotocDangerous {
  to: string;
  properShippingName: string;
  awbNr: string;
  clDv: string;
  comp: string;
  unId: string;
  subRsk: string;
  pieces: number;
  qtyTi: string;
  rrrCat: string;
  pckGrp: string;
  impDrill: string;
  cao: true;
  pos: string;
  uldCode: string;
}

export class NotocOther {
  to: string;
  contents: string;
  awbNr: string;
  pieces: number;
  qty: number;
  impCode: string;
  pos: string;
  uldCode: string;
}

export class CabinArea {
  constructor(section?) {
    this.name = section?.name || '';
    this.maxPassengers = section?.maxPassengers || null;
    if (section?.rows.length > 1) {
      this.rows = [...section.rows];
    } else if (section?.rows.length > 0) {
      this.rows = [section.rows[0]];
    } else {
      this.rows = [];
    }
    this.passengers = section?.passengers || new Passengers();
  }
  name: string;
  maxPassengers: number;
  rows: Array<number>;
  passengers: Passengers;

  get rowsString() {
    if (this.rows && this.rows.length > 1)
      return this.rows[0] + ' - ' + this.rows[1];
    else
      return '';
  }
}

export class HoldsAndCompartmentsCalc {
  constructor() {
    this.name = '';
    this.type = '';
    this.destination = '';
    this.weight = null;
    this.uldType = '';
    this.uldNum = '';
    this.uldWeight = null;
    this.allWeight = null;
  }
  name: string;
  type: string;
  destination: string;
  weight: number;
	uldType: string;
	uldNum: string;
  uldWeight: number
  allWeight: number;
}

export class FilterParams {
  start: Date;
  finish: Date;
  flight: string;
  airport: number;
  airline: number;
  aircraft: number;
  tail: string;
}

export class Document {
  constructor() {
    this.edno = null;
    this.preparedBy = '';
    this.preparedAt = new Date();;
  }
  edno: number;
  preparedBy: string;
  preparedAt: Date;
}

<div class="row mt-2">
    <div class="col-2 pr-1">
      <div class="card">
        <div class="card-header bg-gray-blue-dark text-center text-white font-weight-bold"
             i18n="adm|Main left menu - name@@adm.main.leftMenu.name">
          Modules
        </div>
        <ul class="list-group list-group-flush">
          <li id="module-communication"
              class="list-group-item active"
              [ngClass]="{'active': activeModule=='module-communication'}"
              (click)="changeModule($event)"
              i18n="adm|Main left menu - communication module@@adm.main.leftMenu.item.comMod"
              *ngIf="isComModule">
            Communication module
          </li>
          <li id="module-informational"
              class="list-group-item"
              [ngClass]="{'active': activeModule=='module-informational'}"
              (click)="changeModule($event)"
              i18n="adm|Main left menu - informing@@adm.main.leftMenu.item.informing"
              *ngIf="isInforming">
            Informing
          </li>
          <li id="module-techprocesses"
              class="list-group-item"
              [ngClass]="{'active': activeModule=='module-techprocesses'}"
              (click)="changeModule($event)"
              i18n="adm|Main left menu - technical processes@@adm.main.leftMenu.item.techProcesses"
              *ngIf="isWorkflow">
            Technical processes
          </li>
          <li id="module-boarding-control"
              class="list-group-item"
              [ngClass]="{'active': activeModule=='module-boarding-control'}"
              (click)="changeModule($event)"
              i18n="adm|Main left menu - boarding control@@adm.main.leftMenu.item.boardingControl"
              *ngIf="isWorkflow">
            Boarding control
          </li>
          <li id="module-weight-balance"
              class="list-group-item"
              [ngClass]="{'active': activeModule=='module-weight-balance'}"
              (click)="changeModule($event)"
              i18n="adm|Main left menu - weight balance@@adm.main.leftMenu.item.weightBalance"
              *ngIf="isBalance">
            Weight balance
          </li>
          <li id="module-flights"
              class="list-group-item"
              [ngClass]="{'active': activeModule=='module-flights'}"
              (click)="changeModule($event)"
              i18n="adm|Main left menu - flights@@adm.main.leftMenu.item.flights"
              *ngIf="isFlights">
            Flights
          </li>
        </ul>
      </div>
    </div>
    <div class="col-10 pl-0">
      <div *ngIf="activeModule=='module-communication' && isComModule">
        <app-am-communication></app-am-communication>
      </div>
      <div *ngIf="activeModule=='module-informational' && isInforming">
        <app-am-informational></app-am-informational>
      </div>
      <div *ngIf="activeModule=='module-techprocesses' && isWorkflow">
        <app-am-techprocesses></app-am-techprocesses>
      </div>
      <div *ngIf="activeModule=='module-boarding-control'">
        <app-am-boarding-control></app-am-boarding-control>
      </div>
      <div *ngIf="activeModule=='module-weight-balance'">
        <app-am-balance></app-am-balance>
      </div>
      <div *ngIf="activeModule=='module-flights'">
        <app-am-flights></app-am-flights>
      </div>
    </div>
</div>

<div *ngIf="activeWindow == 'flight-card'"
  class="mb-2 p-3 border border-top-0 shadow-sm rounded-bottom d-flex align-items-center justify-content-between">
  <button class="btn btn-sm btn-ico btn-light-gray"
          (click)="loadFlights()">
    <i class="material-icons">arrow_back_ios</i>
    <span i18n="wb|Control panel@@wb.controlPanel.button.flights">Flights</span>
  </button>
  <div class="d-flex align-items-center">
    <div class="input-group flex-nowrap mx-3">
      <div class="input-group-prepend custom-sm">
        <span class="input-group-text font-small py-0"
          i18n="wb|Control panel@@wb.controlPanel.field.maxTrafficLoad">Max traffic load
        </span>
      </div>
      <input type="text"
            class="form-control custom-sm"
            value="{{maxTrafficLoad}}"
            disabled />
    </div>
    <div class="input-group flex-nowrap mr-3">
      <div class="input-group-prepend custom-sm">
        <span class="input-group-text font-small py-0"
          i18n="wb|Control panel@@wb.controlPanel.field.flight">Flight
        </span>
      </div>
      <input type="text"
            class="form-control custom-sm"
            value="{{flight.airlineIata}} {{flight.flightNumber}}"
            disabled />
    </div>
    <div class="input-group flex-nowrap mr-3">
      <div class="input-group-prepend custom-sm">
        <span class="input-group-text font-small py-0"
              i18n="wb|Control panel@@wb.controlPanel.field.route">Route
        </span>
      </div>
      <input type="text"
            class="form-control custom-sm"
            value="{{ flight.homeRoute ? flight.homeRoute.airportIata : '' }} - {{ flight.nextRoute ? flight.nextRoute.airportIata : '' }}"
            disabled />
    </div>
    <div class="input-group flex-nowrap">
      <div class="input-group-prepend custom-sm">
        <span class="input-group-text font-small py-0"
              i18n="wb|Control panel@@wb.controlPanel.field.departure">Departure
        </span>
      </div>
      <input type="text"
            class="form-control custom-sm"
            value="{{ flight.homeRoute ? (flight.homeRoute.dtDepartureScheduled | date: 'dd.MM.yyyy HH:mm') : '' }}"
            disabled />
    </div>
    <!--<button class="btn btn-sm btn-blue ml-3"
            (click)="calculate()"
            i18n="wb|Control panel - button calculate@@wb.controlPanel.button.calculate">Calculate</button>-->
    <button type="button"
            class="btn btn-xs ml-3 btn-ico btn-light-gray"
            ngbTooltip="Reload AHM data"
            (click)="reloadAhmData()"
            i18n-ngbTooltip="wb|Control panel - button calculate@@wb.controlPanel.button.reloadAhmData">
      <i class="material-icons">refresh</i>
    </button>
    <button class="btn btn-sm btn-blue ml-3"
            (click)="addCalculate()"
            *ngIf="usesAction == 'addCalculation'"
            i18n="wb|Control panel - button calculate@@wb.controlPanel.button.calculate">Calculate</button>
    <button class="btn btn-sm btn-blue ml-3"
            (click)="saveCalculate(calculation.id)"
            *ngIf="usesAction == 'editCalculation'"
            i18n="wb|Control panel - button calculate@@wb.controlPanel.button.calculate">Calculate</button>

  </div>
</div>
<div *ngIf="activeWindow == 'flight-list'"
     class="mb-2 p-3 border border-top-0 shadow-sm rounded-bottom d-flex align-items-center justify-content-between">
  <div>
    <button type="button"
            class="btn btn-blue" data-toggle="modal"
            data-target=".bd-example-modal-xl" (click)="createFlight()">
      <i class="material-icons">add</i>
      <span i18n="wb|Control panel@@wb.controlPanel.button.addFlight">Add flight</span>
    </button>
    <button class="btn btn-blue ml-3"
            [disabled]="!flight.id"
            data-toggle="modal"
            (click)="editFlight()"
            data-target=".bd-example-modal-xl"
            i18n="wb|Control panel@@wb.controlPanel.button.editFlight">Edit flight
    </button>
  </div>


  <div class="d-flex flex-grow-1 pl-3">
    <div class="">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text font-small"
                i18n="wb|Control panel@@wb.controlPanel.text.displayedPeriod">
            Displayed period
          </span>
        </div>
          <ng-container *ngIf="filterApply && filterParams.start && filterParams.finish; else current">
            <input type="text"
                   value="{{filterParams.start | date: 'dd.MM.yyyy'}} - {{filterParams.finish | date: 'dd.MM.yyyy'}}"
                   class="form-control form-control-sm"
                   disabled>
          </ng-container>
          <ng-template #current>
            <input type="text"
                   value="{{messages['current']}}"
                   class="form-control form-control-sm"
                   disabled>
          </ng-template>
      </div>
    </div>
    <div class="d-flex flex-fill row pl-3">
      <div class="col-4">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text font-small" i18n="wb|Filter@@wb.filter.label.search">
              Search
            </span>
          </div>
          <input type="text" class="form-control form-control-sm" [(ngModel)]="searchFlight">
        </div>
      </div>
      <div class="col-8">
        <div class="d-flex">
          <div class="filter-block-wrap mr-2" (click)="showFilter = !showFilter">
            <div class="filter-elements d-flex pl-2 text-nowrap flex-wrap">
              <div class="filter-element mr-2 my-1" *ngIf="filterParams.start && filterParams.finish">
                <span i18n="wb|Filter - date@@wb.filter.field.date">Date</span>&nbsp;{{filterParams.start | date: 'dd.MM.yyyy'
                }}&nbsp;-&nbsp;{{filterParams.finish | date: 'dd.MM.yyyy'}}
                <i class="material-icons mx-1" (click)="clearFilterParametr('date', $event)">close</i>
              </div>
              <div class="filter-element mr-2 my-1" *ngIf="filterParams.flight!=null">
                <span i18n="wb|Filter - date@@wb.filter.field.flight">Flight</span>&nbsp;{{filterParams.flight}}
                <i class="material-icons ml-2" (click)="clearFilterParametr('flight', $event)">close</i>
              </div>
              <div class="filter-element mr-2 my-1" *ngIf="filterParams.airport!=null">
                <span i18n="wb|Filter - airport@@wb.filter.field.airport">Airport</span>&nbsp;{{getById(references.airports, filterParams.airport ).iata}}
                <i class="material-icons ml-2" (click)="clearFilterParametr('airport', $event)">close</i>
              </div>
              <div class="filter-element mr-2 my-1" *ngIf="filterParams.airline!=null">
                <span i18n="wb|Filter - airline@@wb.filter.field.airline">Airline</span>&nbsp;{{getById(references.airlines, filterParams.airline ).iata}}
                <i class="material-icons ml-2" (click)="clearFilterParametr('airline', $event)">close</i>
              </div>
              <div class="filter-element mr-2 my-1" *ngIf="filterParams.aircraft!=null">
                <span i18n="wb|Filter - aircraft@@wb.filter.field.aircraft">Aircraft</span>&nbsp;{{getById(references.aircraft_types, filterParams.aircraft ).names}}
                <i class="material-icons ml-2" (click)="clearFilterParametr('aircraft', $event)">close</i>
              </div>
              <div class="filter-element mr-2 my-1" *ngIf="filterParams.tail!=null">
                <span i18n="wb|Filter - tail@@wb.filter.field.tail">Tail</span>&nbsp;{{filterParams.tail}}
                <i class="material-icons ml-2" (click)="clearFilterParametr('tail', $event)">close</i>
              </div>
            </div>
            <div class="d-flex align-items-center text-dark-gray">
              <i class="material-icons mx-1">arrow_drop_down</i>
            </div>
          </div>
          <!-- Filter -->
            <div class="filter-block border p-2 bg-white font-small" *ngIf="showFilter">
              <div class="container">
                <div class="row pb-2">
                  <div class="col-4 px-1">
                    <label for="filter-date-from"
                           class="mr-2 mb-0"
                           i18n="wb|Filter - date from@@wb.filter.field.dateFrom">
                      Date from
                    </label>
                  </div>
                  <div class="col-8 px-1">
                    <input type="date"
                           class="form-control"
                           [ngModel]="filterParams.start | date: 'yyyy-MM-dd'"
                           (input)="filterParams.start = parseDate($event.target.value, '00:00')"
                           name="filterParamsStartDate"
                           #start_date>
                  </div>
                </div>
                <div class="row pb-2">
                  <div class="col-4 px-1">
                    <label for="filter-date-from"
                           class="mr-2 mb-0"
                           i18n="wb|Filter - date to@@wb.filter.field.dateTo">
                      Date to
                    </label>
                  </div>
                  <div class="col-8 px-1">
                    <input type="date"
                           class="form-control"
                           [ngModel]="filterParams.finish | date: 'yyyy-MM-dd'"
                           (input)="filterParams.finish = parseDate($event.target.value, '23:59')"
                           name="filterParamsStartDate"
                           #finish_date>
                  </div>
                </div>
                <div class="row pb-2">
                  <div class="col-4 px-1">
                    <label for="filter-date-from"
                           class="mr-2 mb-0"
                           i18n="wb|Filter - date@@wb.filter.field.flight">
                      Flight
                    </label>
                  </div>
                  <div class="col-8 px-1">
                    <input type="text"
                           class="form-control form-control-sm"
                           [(ngModel)]="filterParams.flight"
                           name="filterParamsFlight">
                  </div>
                </div>
                <div class="row pb-2">
                  <div class="col-4 px-1">
                    <label for="filter-date-from" class="mr-2 mb-0"
                           i18n="wb|Filter - airport@@wb.filter.field.airport">
                      Airport
                    </label>
                  </div>
                  <div class="col-8 px-1">
                    <ng-select class="form-control custom-sm p-0"
                              [items]="references.airports"
                              bindLabel="iata"
                              bindValue="id"
                              [(ngModel)]="filterParams.airport"
                              #filterParamsAirport="ngModel"
                              name="filterParamsAirport"
                              [virtualScroll]="true"
                              [loading]="selectLoadAnimation.airports"
                              (scroll)="onScrollNgSelect($event, 'airports')"
                              (scrollToEnd)="onScrollToEndNgSelect('airports')"
                              [searchFn]="customSelectSearch"
                              appendTo="body">
                      <ng-template ng-option-tmp let-item="item">
                        <div class="font-small">
                          <span class="font-weight-bold">{{item.iata}}</span>&nbsp;{{item.names}}
                        </div>
                      </ng-template>
                    </ng-select>
                  </div>
                </div>
                <div class="row pb-2">
                  <div class="col-4 px-1">
                    <label for="filter-date-from" class="mr-2 mb-0"
                           i18n="wb|Filter - airline@@wb.filter.field.airline">
                      Airline
                    </label>
                  </div>
                  <div class="col-8 px-1">
                    <ng-select class="form-control custom-sm p-0"
                               [items]="references.airlines"
                               bindLabel="iata"
                               bindValue="id"
                               [(ngModel)]="filterParams.airline"
                               #filterParamsAirline="ngModel"
                               name="filterParamsAirline"
                               [virtualScroll]="true"
                               [loading]="selectLoadAnimation.airlines"
                               (scroll)="onScrollNgSelect($event, 'airlines')"
                               (scrollToEnd)="onScrollToEndNgSelect('airlines')"
                               [searchFn]="customSelectSearch">
                      <ng-template ng-option-tmp
                                   let-item="item">
                        {{item.code}} / {{item.name}}
                      </ng-template>
                    </ng-select>
                  </div>
                </div>
                <div class="row pb-2">
                  <div class="col-4 px-1">
                    <label for="filter-date-from" class="mr-2 mb-0"
                           i18n="wb|Filter - aircraft@@wb.filter.field.aircraft">
                      Aircraft
                    </label>
                  </div>
                  <div class="col-8 px-1">
                    <ng-select class="form-control custom-sm p-0"
                               [items]="references.aircraft_types"
                               bindLabel="iata"
                               bindValue="id"
                               name="filterParamsAircraft"
                               id="filterParamsAircraft"
                               [(ngModel)]="filterParams.aircraft"
                               [virtualScroll]="true"
                               [loading]="selectLoadAnimation.aircraft_types"
                               (scroll)="onScrollNgSelect($event, 'aircraft_types')"
                               (scrollToEnd)="onScrollToEndNgSelect('aircraft_types')"
                               [searchFn]="customSelectSearch">
                    </ng-select>
                  </div>
                </div>
                <div class="row pb-2">
                  <div class="col-4 px-1">
                    <label for="filter-date-from" class="mr-2 mb-0"
                           i18n="wb|Filter - tail@@wb.filter.field.tail">
                      Tail
                    </label>
                  </div>
                  <div class="col-8 px-1">
                    <input type="text"
                           class="form-control form-control-sm"
                           [(ngModel)]="filterParams.tail"
                           name="filterParamsTail">
                  </div>
                </div>
              </div>
              <hr />
              <button class="btn btn-gray-blue-dark btn-sm float-right ml-2"
                      (click)="showFilter = !showFilter"
                      i18n="wb|Filter - close@@wb.filter.button.close">
                Close
              </button>
              <button class="btn btn-blue btn-sm float-right ml-2"
                      (click)="filterApp()"
                      i18n="wb|Filter - find@@wb.filter.button.find">
                Find
              </button>
              <button class="btn btn-blue btn-sm float-right"
                      (click)="clearFilter()"
                      i18n="wb|Filter - clear all@@wb.filter.button.clearAll">
                Clear all
              </button>
            </div>
          <button type="button"
                  class="btn btn-xs btn-ico btn-light-gray mr-2"
                  ngbTooltip="Filter on/off"
                  i18n-ngbTooltip="cmn|Main buttons - filter on/off@@cmn.main.button.filterOnOff"
                  [ngClass]="{'btn-active' : filterApply}"
                  (click)="filterSwitch()">
            <i class="material-icons">filter_alt</i>
          </button>
        </div>
      </div>
    </div>
  </div>

  <button class="btn btn-blue ml-3"
          (click)="editCalculation(flight.id)"
          [disabled]="!flight.id"
          i18n="wb|Control panel@@wb.controlPanel.button.editCalculation">Edit calculation</button>
</div>
<div class="card weight-balance-content" *ngIf="activeWindow == 'flight-list'">
<div class="card-body">
  <p class="font-weight-bold text-blue h5 mb-3"
     i18n="wb|Flight list@@wb.flightList.groupName">Flight list</p>
  <table class="table-striped w-100">
    <thead class="bg-blue color-white">
      <tr>
        <th i18n="wb|Flight list@@wb.flightList.table.column.flight">Flight</th>
        <th i18n="wb|Flight list@@wb.flightList.table.column.date">Date</th>
        <th i18n="wb|Flight list@@wb.flightList.table.column.time">Time</th>
        <th i18n="wb|Flight list@@wb.flightList.table.column.from">From</th>
        <th i18n="wb|Flight list@@wb.flightList.table.column.to">To</th>
        <th i18n="wb|Flight list@@wb.flightList.table.column.aircraft">Aircraft</th>
        <th i18n="wb|Flight list@@wb.flightList.table.column.registration">Registration</th>
      </tr>
    </thead>
    <tbody>
    <tr (click)="selectFlight(oneFlight.id)"
        (dblclick)="openCalculation(oneFlight.id)"
        *ngFor="let oneFlight of flightList"
        [ngClass]="{'bg-gray-blue-dark text-white': flight.id === oneFlight.id}">
      <td>{{ getProp(references.airlines, oneFlight.airlineId, "iata") }}&nbsp;{{ oneFlight.flightNumber }}</td>
      <td>{{ oneFlight.homeRoute ? (oneFlight.homeRoute.dtDepartureScheduled | date: 'dd.MM.yyyy') : '' }}</td>
      <td>{{ oneFlight.homeRoute ? (oneFlight.homeRoute.dtDepartureScheduled | date: 'HH:mm') : '' }}</td>
      <td>{{ oneFlight.homeRoute ? oneFlight.homeRoute.airportIata : '' }}</td>
      <td>{{ oneFlight.nextRoute ? oneFlight.nextRoute.airportIata : '' }}</td>
      <td>{{ oneFlight.aircraftTypeIata }}</td>
      <td>{{ references.tails.length > 0 ? getProp(references.tails, oneFlight.tailId, 'tail') : '' }}</td>
    </tr>
    </tbody>
  </table>
</div>
</div>
<div class="weight-balance-content" *ngIf="activeWindow == 'flight-card'">
<div class="row">
  <div class="col-8 pr-0">
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a id="flight-tab-info"
           [ngClass]="{'active': showTab('flight-tab-info')}"
           class="nav-link"
           (click)="changeTab($event)"
           i18n="wb|Main weight balance@@wb.main.tab.flight.name">Aircraft and flight information
        </a>
      </li>
      <li class="nav-item">
        <a id="flight-tab-detributional"
           [ngClass]="{'active': showTab('flight-tab-detributional')}"
           class="nav-link"
           (click)="changeTab($event)"
           i18n="wb|Main weight balance@@wb.main.tab.load.name">Holds and compartments
        </a>
      </li>
      <li class="nav-item">
        <a id="flight-tab-specialLoad"
           [ngClass]="{'active': showTab('flight-tab-specialLoad')}"
           class="nav-link"
           (click)="changeTab($event)"
           i18n="wb|Main weight balance@@wb.main.tab.notoc.name">Notoc
        </a>
      </li>
      <!--<li class="nav-item">
        <a id="flight-tab-fuel"
           [ngClass]="{'active': showTab('flight-tab-fuel')}"
           class="nav-link"
           (click)="changeTab($event)"
           i18n="wb|Main weight balance@@wb.main.tab.fuel.name">Fuel
        </a>
      </li>-->
      <li class="nav-item">
        <a id="flight-tab-passenger"
           [ngClass]="{'active': showTab('flight-tab-passenger')}"
           class="nav-link"
           (click)="changeTab($event)"
           i18n="wb|Main weight balance@@wb.main.tab.passengers.name">Passengers
        </a>
      </li>
      <li class="nav-item">
        <a id="flight-tab-documents"
           [ngClass]="{'active': showTab('flight-tab-documents')}"
           class="nav-link"
           (click)="changeTab($event)"
           i18n="wb|Main weight balance@@wb.main.tab.documents.name">Documents
        </a>
      </li>
    </ul>
    <div *ngIf="showTab('flight-tab-info')" class="bg-white p-3">
      <div class="row">
        <div class="col-6">
          <div class="card mb-3">
            <div class="card-body">
              <p class="font-weight-bold text-blue h5 mb-3"
                i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.flightData">Flight data</p>
              <p i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupAircraft.groupName">Aircraft</p>
              <div class="row mb-3">
                <div class="col-6">
                  <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0"
                            i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupAircraft.table.registration">
                        Registration
                      </span>
                    </div>
                    <input type="text"
                          class="w-100 form-control custom-sm"
                          [value]="getById(references.tails, +flight.tailId) ? getById(references.tails, +flight.tailId).tail : 'Loading...'"
                          disabled />
                  </div>
                </div>
                <div class="col-6">
                  <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0"
                            i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupAircraft.table.type">
                        Type
                      </span>
                    </div>
                    <input type="text"
                          class="w-100 form-control custom-sm"
                          [value]="flight.aircraftTypeIata"
                          disabled />
                  </div>
                </div>
                <div class="col-12">
                  <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0"
                            i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupAircraft.table.version">
                            Version
                      </span>
                    </div>
                    <ng-select [items]="ahmData.configurations"
                                   class="w-100 custom-sm"
                                   bindLabel="name"
                                   bindValue="name"
                                   name="versionAhm"
                                   id="versionAhm"
                                   (change)="changeVersionAhm($event)"
                                   [(ngModel)]="calculation.configuration">
                    </ng-select>
                  </div>
                </div>
              </div>
              <p class="pt-3 mt-3 border-top" i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupAircraft.flight">Flight</p>
              <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend custom-sm">
                  <span class="input-group-text font-small py-0"
                      i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupFlight.captain">
                        Captain
                  </span>
                </div>
                <input type="text" class="w-100 form-control custom-sm" [(ngModel)]="calculation.captain" />
              </div>
              <p class="pt-3 mt-3 border-top" i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupFlight.weight">Weight</p>
              <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend custom-sm">
                  <span class="input-group-text font-small py-0"
                        i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.mtow">MTOW (max take-off weight)
                  </span>
                </div>
                <input type="number"
                      class="w-100 form-control custom-sm"
                      name="mtow"
                      id="mtow"
                      [(ngModel)]="calculation.mtow" />
              </div>
              <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend custom-sm">
                  <span class="input-group-text font-small py-0"
                        i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.mlw">MLW (max landing weight)
                  </span>
                </div>
                <input type="number"
                      class="w-100 form-control custom-sm"
                      name="mlw"
                      id="mlw"
                      [(ngModel)]="calculation.mlw" />
              </div>
            </div>
          </div>
          <div class="input-group flex-nowrap mb-3">
            <div class="input-group-prepend custom-sm">
              <span class="input-group-text font-small py-0"
                    i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.preparedBy">Prepared by
              </span>
            </div>
            <input type="text"
                   class="w-100 form-control custom-sm"
                   name="preparedBy"
                   id="preparedBy"
                   [(ngModel)]="calculation.preparedBy"
                   disabled />
          </div>
        </div>
        <div class="col-6">
          <div class="card mb-4">
            <div class="card-body">
              <p class="font-weight-bold text-blue h5 mb-3"
                 i18n="wb|Main weight balance tab fuel@@wb.main.tab.fuel.groupFuel">Fuel</p>
              <div class="row">
                <div class="col-12">
                  <div class="input-group flex-nowrap mt-3">
                    <div class="input-group-prepend custom-sm">
                      <div class="input-group-text font-small py-0">
                        <span i18n="wb|Main weight balance tab fuel@@wb.main.tab.fuel.groupFuel.fuelOnBoard">Fuel on board</span><span
                              class="required-asterisk">*</span>
                      </div>
                    </div>
                    <input type="number"
                           class="w-100 form-control custom-sm rounded-0"
                           name="fuelOnBoard"
                           id="fuelOnBoard"
                           [(ngModel)]="calculation.fuel.onBoard" />
                  </div>
                </div>
                <div class="col-12">
                  <div class="input-group flex-nowrap mt-3">
                    <div class="input-group-prepend custom-sm">
                      <div class="input-group-text font-small py-0">
                        <span i18n="wb|Main weight balance tab fuel@@wb.main.tab.fuel.groupFuel.includingBallast">Including ballast</span>
                      </div>
                    </div>
                    <input type="number"
                           class="w-100 form-control custom-sm"
                           name="includingBallast"
                           id="includingBallast"
                           [(ngModel)]="calculation.fuel.ballast" />
                  </div>
                </div>
                <div class="col-12">
                  <div class="input-group flex-nowrap mt-3">
                    <div class="input-group-prepend custom-sm">
                      <div class="input-group-text font-small py-0">
                        <span i18n="wb|Main weight balance tab fuel@@wb.main.tab.fuel.groupFuel.DowDoiBallast">Δ DOW/DOI ballast</span>
                      </div>
                    </div>
                    <input type="number"
                           class="w-100 form-control custom-sm rounded-0"
                           name="DowBallast"
                           id="DowBallast"
                           [(ngModel)]="deltaBallast.deltaDow"
                           disabled />
                    <input type="number"
                           class="w-100 form-control custom-sm"
                           name="DoiBallast"
                           id="DoiBallast"
                           [(ngModel)]="deltaBallast.deltaDoi"
                           disabled />
                  </div>
                </div>
                <div class="col-6 pr-0">
                  <div class="input-group flex-nowrap mt-3">
                    <div class="input-group-prepend custom-sm">
                      <div class="input-group-text font-small py-0">
                        <span i18n="wb|Main weight balance tab fuel@@wb.main.tab.fuel.groupFuel.taxi">Taxi</span><span
                              class="required-asterisk">*</span>
                      </div>
                    </div>
                    <input type="number"
                           class="w-100 form-control custom-sm"
                           name="fuelTaxi"
                           id="fuelTaxi"
                           [(ngModel)]="calculation.fuel.taxi" />
                  </div>
                </div>
                <div class="col-6">
                  <div class="input-group flex-nowrap mt-3">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0" i18n="wb|Main weight balance tab fuel@@wb.main.tab.fuel.groupFuel.takeOffFuel">
                        Take off fuel
                      </span>
                    </div>
                    <input type="number"
                           class="w-100 form-control custom-sm"
                           name="fuelOnBoard"
                           id="fuelOnBoard"
                           [value]="calculation.fuel.onBoard - calculation.fuel.taxi"
                           disabled />
                  </div>
                </div>
                <div class="col-12">
                  <div class="input-group flex-nowrap mt-3">
                    <div class="input-group-prepend custom-sm">
                      <div class="input-group-text font-small py-0">
                        <span i18n="wb|Main weight balance tab fuel@@wb.main.tab.fuel.groupFuel.tripFuel">Trip fuel</span><span
                              class="required-asterisk">*</span>
                      </div>
                    </div>
                    <input type="number"
                           class="w-100 form-control custom-sm"
                           name="fuelTrip"
                           id="fuelTrip"
                           [(ngModel)]="calculation.fuel.trip" />
                  </div>
                </div>
                <div class="col-12">
                  <div class="input-group flex-nowrap mt-3">
                    <div class="input-group-prepend custom-sm">
                      <div class="input-group-text font-small py-0">
                        <span i18n="wb|Main weight balance tab fuel@@wb.main.tab.fuel.groupFuel.fuelDensity">
                          Fuel density</span><span class="required-asterisk">*</span>
                      </div>
                    </div>
                    <ng-select [items]="ahmData.effectOfFuel"
                       class="w-100 custom-sm"
                       bindLabel="density"
                       bindValue="density"
                       [(ngModel)]="calculation.fuelDensity">
                    </ng-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <p class="font-weight-bold text-blue h5 mb-3"
                i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupCrewComposition">
                Crew composition
              </p>
              <div>
                <div class="form-group">
                  <label>
                    <span i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupCrewComposition.composition">Composition</span><span class="required-asterisk">*</span>
                  </label>
                  <ng-select [items]="crewComposition"
                             class="custom-sm bg-white"
                             bindLabel="nameDowDoi"
                             bindValue="name"
                             name="crewComposition"
                             id="crewComposition"
                             [ngModel]="calculationCrewComposition.name"
                             (change)="updateDowDoiAfterCrewChange($event)">
                             <ng-template ng-option-tmp let-item="item">
                                <span class="font-weight-bold">{{item.name}}</span>&nbsp;({{item.dow}}&nbsp;/&nbsp;{{item.doi}})
                            </ng-template>
                  </ng-select>
                </div>
                <div class="row">
                  <div class="col-6 pr-0">
                    <div class="input-group flex-nowrap mb-3">
                      <div class="input-group-prepend custom-sm">
                        <div class="input-group-text font-small py-0">
                          <span i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupDryPlane.dow">DOW</span>
                          <span class="required-asterisk">*</span>
                        </div>
                      </div>
                      <input type="number"
                             class="w-100 form-control custom-sm"
                             name="calculationDow"
                             id="calculationDow"
                             [(ngModel)]="calculation.dow" />
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="input-group flex-nowrap">
                      <div class="input-group-prepend custom-sm">
                        <div class="input-group-text font-small py-0">
                            <span i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupDryPlane.doi">DOI</span>
                            <span class="required-asterisk">*</span>
                        </div>
                      </div>
                      <input type="number"
                             class="w-100 form-control custom-sm"
                             name="calculationDoi"
                             id="calculationDoi"
                             [(ngModel)]="calculation.doi" />
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupCrewComposition.standard">Standard crew</label>
                  <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                      <span class="input-group-text"
                            i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupCrewComposition.standard.crewAndStewards">Cockpit and Cabin</span>
                    </div>
                    <input type="number"
                           class="form-control"
                           [(ngModel)]="calculation.crew.cockpit.amount">
                    <input type="number"
                           class="form-control"
                           [(ngModel)]="calculation.crew.cabin.amount">
                  </div>
                </div>

                <div class="form-group">
                  <label i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupCrewComposition.additional">Additional crew</label>
                  <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                      <span class="input-group-text"
                            i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupCrewComposition.additional.crewAndStewards">Cockpit and Cabin</span>
                    </div>
                    <input type="number"
                           class="form-control"
                           [(ngModel)]="calculation.crew.additional.cabin">
                    <input type="number"
                           class="form-control"
                           [(ngModel)]="calculation.crew.additional.cockpit">
                  </div>
                </div>

                <div class="input-group input-group-sm mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text"
                          i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupCrewComposition.additionalCrewInCabin">Additional
                      crew in the cabin</span>
                  </div>
                  <input type="number"
                         class="form-control"
                         aria-label="Sizing example input"
                         aria-describedby="inputGroup-sizing-default"
                         [(ngModel)]="calculation.crew.additional.inCabin">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="showTab('flight-tab-detributional')" class="bg-white p-3">
      <div class="card mb-4">
        <div class="card-body">
          <div class="d-flex mb-3 justify-content-between">
            <p class="font-weight-bold text-blue h5 mb-0"
               i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.name">Loading</p>
            <div *ngIf="allLoadWeight() != flight.loading.luggage + flight.loading.cargo + flight.loading.mail"
                class="text-red d-flex align-items-center">
              <i class="material-icons mr-2">warning</i>
              <span i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.totalWeightNotEqualCurrent">
                Total weight is not equal to the current load
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col-3 pr-0">
              <div class="input-group input-group-sm mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.baggage">
                    Baggage
                  </span>
                </div>
                <input type="number" class="form-control" [value]="flight.loading.luggage" disabled>
              </div>
            </div>
            <div class="col-3 pr-0">
              <div class="input-group input-group-sm mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.cargo">
                    Cargo
                  </span>
                </div>
                <input type="number" class="form-control" [value]="flight.loading.cargo" disabled>
              </div>
            </div>
            <div class="col-3 pr-0">
              <div class="input-group input-group-sm mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.mail">
                    Mail
                  </span>
                </div>
                <input type="number" class="form-control" [value]="flight.loading.mail" disabled>
              </div>
            </div>
            <div class="col-3">
              <div class="input-group input-group-sm mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.total"
                  [ngClass]="{'border-danger': allLoadWeight() != flight.loading.luggage + flight.loading.cargo + flight.loading.mail}">
                    Total
                  </span>
                </div>
                <input type="number" class="form-control"
                [ngClass]="{'border-danger': allLoadWeight() != flight.loading.luggage + flight.loading.cargo + flight.loading.mail}"
                 [value]="flight.loading.luggage + flight.loading.cargo + flight.loading.mail" disabled>
              </div>
            </div>
          </div>
          <table class="w-100 mb-3 contenteditable">
            <thead>
              <tr class="bg-blue color-white font-weight-bold">
                <td i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.type">Type</td>
                <td i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.location">Location</td>
                <td i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.destination">Destination</td>
                <td i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.uldType">ULD type</td>
                <td width="10%" i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.uldWeight">ULD weight</td>
                <td width="10%" i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.uldNumber">ULD number</td>
                <td width="10%" i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.weight">Weight</td>
                <td width="35px"></td>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let compartment of calculation.holdsAndCompartments; let i = index">
                <td>
                  <ng-select [items]="typeOfCargo"
                             class="custom-sm bg-white text-uppercase airports-long"
                             bindLabel="name"
                             bindValue="name"
                             [(ngModel)]="compartment.type">
                  </ng-select>
                </td>
                <td>
                  <ng-select [items]="locations"
                             class="custom-sm bg-white airports-long"
                             bindLabel="name"
                             bindValue="name"
                             name="location_1"
                             id="location_1"
                             [(ngModel)]="compartment.name">
                  </ng-select>
                </td>
                <td>
                  <ng-select [items]="destination"
                             class="custom-sm bg-white text-uppercase"
                             bindLabel="airportIata"
                             bindValue="airportIata"
                             [(ngModel)]="compartment.destination">
                  </ng-select>
                </td>
                <td>
                  <ng-select [items]="uldTypes"
                             class="custom-sm bg-white airports-long"
                             bindLabel="name"
                             bindValue="name"
                             [(ngModel)]="compartment.uldType"
                             appendTo="body"
                             (change)="setUldWeight($event, i)">
                    <ng-template ng-option-tmp let-item="item">
                      <span i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.uld.name">Name:</span>&nbsp;{{item.name}}&nbsp;
                      <span i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.uld.weight">Weight:</span>&nbsp;{{item.weight}}
                    </ng-template>
                  </ng-select>
                </td>
                <td><input type="number" [(ngModel)]="compartment.uldWeight"></td>
                <td><input type="text" [(ngModel)]="compartment.uldNum"></td>
                <td><input type="number" [(ngModel)]="compartment.weight"></td>
                <td>
                  <i class="material-icons m-0 text-red font-weight-bold pointer"
                    (click)="deleteElement(calculation.holdsAndCompartments, i)">clear</i>
                </td>
              </tr>
              <tr class="bg-light-yellow">
                <td colspan="6" class="font-weight-bold"
                    i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.total">
                  Total
                </td>
                <td colspan="2">{{allLoadWeight()}}</td>
              </tr>
            </tbody>
          </table>
          <div class="d-flex justify-content-between align-items-center">
            <div class="input-group-prepend">
              <div class="custom-control custom-switch d-flex ml-2" style="min-width: fit-content;">
                <input type="checkbox"
                      class="custom-control-input"
                      id="fktIncludedInDowDoi"
                      name="fktIncludedInDowDoi"
                      [checked]="calculation.fktIncludedInDowDoi"
                      (click)="changeFktIncluded()">
                <label class="custom-control-label" for="fktIncludedInDowDoi"
                    i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.fktIncludedInDowDoi">
                    FKT included to DOW/DOI
                </label>
              </div>
            </div>
            <button class="btn btn-xs btn-blue"
                    (click)="addItemByClass(calculation.holdsAndCompartments, calculationCompartments)"
                    i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.button.addLoad">Add load
            </button>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <p class="font-weight-bold text-blue h5 mb-3"
             i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupCompartments.name">Compartments</p>
          <div class="row">
            <div class="col-6">
              <p class="font-weight-bold mb-3"
                 i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupCompartments.table.fwd">FWD</p>
              <table class="w-100 table-striped">
                <thead>
                  <tr class="bg-blue color-white font-weight-bold">
                    <th i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupCompartments.table.fwd.compartments">Compartments</th>
                    <th i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupCompartments.table.fwd.baySection">Bay / section</th>
                    <th i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupCompartments.table.fwd.maxWeight">MAX weight</th>
                    <th i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupCompartments.table.fwd.load">Load</th>
                    <th i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupCompartments.table.aft.overload">Overload</th>
                  </tr>
                </thead>
                <tbody *ngFor="let compartment of ahmData.holdsAndCompartments.fwd">
                  <tr *ngIf="compartment.maxWeight !== 0 && compartment.index !==0">
                    <td class="font-weight-bold">{{ compartment.name }}</td>
                    <td>{{ compartment.name }}</td>
                    <td>{{ compartment.maxWeight }}</td>
                    <td class="font-weight-bold"
                        [ngClass]="{'text-red': getLoadWeight(compartment.name) > compartment.maxWeight}">
                      {{ getLoadCompartment(compartment.name) }}
                    </td>
                    <td class="font-weight-bold text-red">
                      {{ getLoadCompartment(compartment.name) > compartment.maxWeight ? getLoadCompartment(compartment.name) - compartment.maxWeight : '' }}
                    </td>
                  </tr>
                  <tr *ngFor="let bay of compartment.bays">
                    <td class="font-weight-bold">{{ compartment.name }}</td>
                    <td>{{ bay.name }}</td>
                    <td>{{ bay.maxWeight }}</td>
                    <td class="font-weight-bold">{{ getLoadWeight(bay.name) }}</td>
                    <td class="font-weight-bold text-red">{{ getLoadWeight(bay.name) > bay.maxWeight ? getLoadWeight(bay.name) - bay.maxWeight : '' }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-6">
              <p class="font-weight-bold mb-3"
                 i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupCompartments.table.aft">AFT</p>
              <table class="w-100 table-striped">
                <thead>
                  <tr class="bg-blue color-white font-weight-bold">
                    <th i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupCompartments.table.aft.compartments">Compartments</th>
                    <th i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupCompartments.table.fwd.baySection">Bay / section</th>
                    <th i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupCompartments.table.aft.maxWeight">MAX weight</th>
                    <th i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupCompartments.table.aft.load">Load</th>
                    <th i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupCompartments.table.aft.overload">Overload</th>
                  </tr>
                </thead>
                <tbody *ngFor="let compartment of ahmData.holdsAndCompartments.aft">
                  <tr *ngIf="compartment.maxWeight !== 0 && compartment.index !==0">
                    <td class="font-weight-bold">{{ compartment.name }}</td>
                    <td>{{ compartment.name }}</td>
                    <td>{{ compartment.maxWeight }}</td>
                    <td class="font-weight-bold"
                        [ngClass]="{'text-red': getLoadWeight(compartment.name) > compartment.maxWeight}">
                      {{ getLoadCompartment(compartment.name) }}
                    </td>
                    <td class="font-weight-bold text-red">
                      {{ getLoadCompartment(compartment.name) > compartment.maxWeight ? getLoadCompartment(compartment.name) - compartment.maxWeight : '' }}
                    </td>
                  </tr>
                  <tr *ngFor="let bay of compartment.bays">
                    <td class="font-weight-bold">{{ compartment.name }}</td>
                    <td>{{ bay.name }}</td>
                    <td>{{ bay.maxWeight }}</td>
                    <td class="font-weight-bold">{{ getLoadWeight(bay.name) }}</td>
                    <td class="font-weight-bold text-red">{{ getLoadWeight(bay.name) > bay.maxWeight ? getLoadWeight(bay.name) - bay.maxWeight : '' }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="showTab('flight-tab-specialLoad')" class="bg-white p-3">
      <div class="input-group-prepend mb-3">
        <div class="custom-control custom-switch d-flex ml-2" style="min-width: fit-content;">
          <input type="checkbox"
                class="custom-control-input"
                id="releasedByAnother"
                name="releasedByAnother"
                [checked]="calculation.notoc.releasedByAnother"
                (click)="changeNotocNil()">
          <label class="custom-control-label" for="releasedByAnother"
              i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.releasedByAnother">
              No Item Loaded (the telegram will mark NOTOC - NIL)
          </label>
        </div>
      </div>
      <div class="card mb-4" [ngClass]="{'disabled': calculation.notoc.releasedByAnother}">
        <div class="card-body">
          <p class="font-weight-bold text-blue h5 mb-3"
             i18n="wb|Main weight balance tab fuel@@wb.main.tab.specialLoad.dangerousGoods">Dangerous goods</p>
             <table class="w-100 table-striped mb-3">
              <thead>
                <tr class="bg-blue color-white font-weight-bold">
                  <td i18n="wb|Main weight balance tab specialLoad@@wb.main.tab.specialLoad.dangerousGoods.to">TO</td>
                  <td i18n="wb|Main weight balance tab specialLoad@@wb.main.tab.specialLoad.dangerousGoods.awb">AWB</td>
                  <td i18n="wb|Main weight balance tab specialLoad@@wb.main.tab.specialLoad.dangerousGoods.clDv">CL/DV</td>
                  <td i18n="wb|Main weight balance tab specialLoad@@wb.main.tab.specialLoad.dangerousGoods.comp">COMP</td>
                  <td i18n="wb|Main weight balance tab specialLoad@@wb.main.tab.specialLoad.dangerousGoods.unIdNumber">UN/ID NUMBER</td>
                  <td i18n="wb|Main weight balance tab specialLoad@@wb.main.tab.specialLoad.dangerousGoods.subRisk">SUB RISK</td>
                  <td i18n="wb|Main weight balance tab specialLoad@@wb.main.tab.specialLoad.dangerousGoods.pcs">PCS</td>
                  <td i18n="wb|Main weight balance tab specialLoad@@wb.main.tab.specialLoad.dangerousGoods.qtyTi">QTY/TI</td>
                  <td i18n="wb|Main weight balance tab specialLoad@@wb.main.tab.specialLoad.dangerousGoods.prrCat">RRR CAT</td>
                  <td i18n="wb|Main weight balance tab specialLoad@@wb.main.tab.specialLoad.dangerousGoods.pck">PCK</td>
                  <td i18n="wb|Main weight balance tab specialLoad@@wb.main.tab.specialLoad.dangerousGoods.impCode">IMP CODE</td>
                  <td i18n="wb|Main weight balance tab specialLoad@@wb.main.tab.specialLoad.dangerousGoods.cao">CAO</td>
                  <td i18n="wb|Main weight balance tab specialLoad@@wb.main.tab.specialLoad.dangerousGoods.pos">POS</td>
                  <td i18n="wb|Main weight balance tab specialLoad@@wb.main.tab.specialLoad.dangerousGoods.uldCode">ULD CODE</td>
                </tr>
              </thead>
              <tbody *ngFor="let dangerous of calculation.notoc.dangerous; let i = index">
                <tr>
                  <td>
                    <ng-select [items]="destination"
                               class="custom-sm bg-white text-uppercase"
                               bindLabel="airportIata"
                               bindValue="airportIata"
                               [(ngModel)]="dangerous.to">
                    </ng-select>
                  </td>
                  <td><input type="text" [(ngModel)]="dangerous.awbNr"></td>
                  <td><input type="text" [(ngModel)]="dangerous.clDv"></td>
                  <td><input type="text" [(ngModel)]="dangerous.comp"></td>
                  <td><input type="text" [(ngModel)]="dangerous.unId"></td>
                  <td><input type="text" [(ngModel)]="dangerous.subRsk"></td>
                  <td><input type="number" [(ngModel)]="dangerous.pieces"></td>
                  <td><input type="text" [(ngModel)]="dangerous.qtyTi"></td>
                  <td><input type="text" [(ngModel)]="dangerous.rrrCat"></td>
                  <td><input type="text" [(ngModel)]="dangerous.pckGrp"></td>
                  <td><input type="text" [(ngModel)]="dangerous.impDrill"></td>
                  <td>
                    <input type="checkbox" id="cao_{{i}}" name="cao_{{i}}" [(ngModel)]="dangerous.cao">
                  </td>
                  <td><input type="text" [(ngModel)]="dangerous.pos"></td>
                  <td><input type="text" [(ngModel)]="dangerous.uldCode"></td>
                </tr>
                <tr>
                  <td colspan="13">
                    <div class="input-group flex-nowrap">
                      <div class="input-group-prepend custom-sm">
                        <span class="input-group-text font-small py-0"
                          i18n="wb|Main weight balance tab specialLoad@@wb.main.tab.specialLoad.dangerousGoods.properShippingName">
                          Proper shipping name
                        </span>
                      </div>
                      <input type="text" class="form-control custom-sm" [(ngModel)]="dangerous.properShippingName"/>
                    </div>
                  </td>
                  <td class="text-right">
                    <button type="button" class="btn btn-sm btn-red">
                      <span i18n="wb|Main weight balance@@wb.main.button.delete"
                            (click)="deleteElement(calculation.notoc.dangerous, i)">
                        Delete
                      </span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <button class="btn btn-xs float-right btn-blue"
                    (click)="addItemByClass(calculation.notoc.dangerous, NotocDangerous)"
                    i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.button.addLoad">Add load
            </button>
        </div>
      </div>
      <div class="card" [ngClass]="{'disabled': calculation.notoc.releasedByAnother}">
        <div class="card-body">
          <p class="font-weight-bold text-blue h5 mb-3"
             i18n="wb|Main weight balance tab fuel@@wb.main.tab.specialLoad.otherSpecialLoad">Other special load</p>
             <table class="w-100 table-striped mb-3">
              <thead>
                <tr class="bg-blue color-white font-weight-bold">
                  <td i18n="wb|Main weight balance tab specialLoad@@wb.main.tab.specialLoad.dangerousGoods.to">TO</td>
                  <td i18n="wb|Main weight balance tab specialLoad@@wb.main.tab.specialLoad.dangerousGoods.awb">AWB</td>
                  <td i18n="wb|Main weight balance tab specialLoad@@wb.main.tab.specialLoad.dangerousGoods.psc">PCS</td>
                  <td i18n="wb|Main weight balance tab specialLoad@@wb.main.tab.specialLoad.dangerousGoods.qty">QTY</td>
                  <td i18n="wb|Main weight balance tab specialLoad@@wb.main.tab.specialLoad.dangerousGoods.impCode">IMP CODE</td>
                  <td i18n="wb|Main weight balance tab specialLoad@@wb.main.tab.specialLoad.dangerousGoods.pos">POS</td>
                  <td i18n="wb|Main weight balance tab specialLoad@@wb.main.tab.specialLoad.dangerousGoods.uldCode">ULD CODE</td>
                </tr>
              </thead>
              <tbody *ngFor="let other of calculation.notoc.other; let i = index">
                <tr><!---->
                  <td>
                    <ng-select [items]="destination"
                               class="custom-sm bg-white text-uppercase"
                               bindLabel="airportIata"
                               bindValue="airportIata"
                               [(ngModel)]="other.to">
                    </ng-select>
                  </td>
                  <td><input type="text" [(ngModel)]="other.awbNr"></td>
                  <td><input type="number" [(ngModel)]="other.pieces"></td>
                  <td><input type="number" [(ngModel)]="other.qty"></td>
                  <td><input type="text" [(ngModel)]="other.impCode"></td>
                  <td><input type="text" [(ngModel)]="other.pos"></td>
                  <td><input type="text" [(ngModel)]="other.uldCode"></td>
                </tr>
                <tr>
                  <td colspan="6">
                    <div class="input-group flex-nowrap">
                      <div class="input-group-prepend custom-sm">
                        <span class="input-group-text font-small py-0"
                          i18n="wb|Main weight balance tab specialLoad@@wb.main.tab.specialLoad.dangerousGoods.contentsAdnDescription">
                          Contents adn description
                        </span>
                      </div>
                      <input type="text" class="form-control custom-sm" [(ngModel)]="other.contents"/>
                    </div>
                  </td>
                  <td class="text-right">
                    <button type="button" class="btn btn-sm btn-red">
                      <span i18n="wb|Main weight balance@@wb.main.button.delete"
                            (click)="deleteElement(calculation.notoc.other, i)">
                        Delete
                      </span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <button class="btn btn-xs float-right btn-blue"
                    (click)="addItemByClass(calculation.notoc.other, NotocOther)"
                    i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.button.addLoad">Add load
            </button>
        </div>
      </div>
    </div>
    <!--<div *ngIf="showTab('flight-tab-fuel')" class="bg-white p-3">
      <div class="card">
        <div class="card-body">
          <p class="font-weight-bold text-blue h5 mb-3"
             i18n="wb|Main weight balance tab fuel@@wb.main.tab.fuel.groupFuel">Fuel</p>
          <div class="fuel-input-group">
            <label for="fuelOnBoard"
                   i18n="wb|Main weight balance tab fuel@@wb.main.tab.fuel.groupFuel.fuelOnBoard">Fuel on board</label>
            <input type="number"
                   class="w-100 form-control custom-sm"
                   [(ngModel)]="calculation.fuel.onBoard" />
            <label for="incBallastTrapped"
                   i18n="wb|Main weight balance tab fuel@@wb.main.tab.fuel.groupFuel.ballastAndTrapped">(inc ballast and trapped)</label>
            <input type="number"
                   class="w-100 form-control custom-sm"
                   [(ngModel)]="calculation.fuel.ballast" />
            <label for="taxi"
                   i18n="wb|Main weight balance tab fuel@@wb.main.tab.fuel.groupFuel.taxi">Taxi</label>
            <input type="number"
                   class="w-100 form-control custom-sm"
                   [(ngModel)]="calculation.fuel.taxi" />
            <label for="takeOffFuel"
                   i18n="wb|Main weight balance tab fuel@@wb.main.tab.fuel.groupFuel.takeOffFuel">Take off fuel</label>
            <input type="number"
                   class="w-100 form-control custom-sm"
                   [value]="calculation.fuel.onBoard - calculation.fuel.taxi"
                   disabled />
            <label for="takeOffWeightActual"
                   i18n="wb|Main weight balance tab fuel@@wb.main.tab.fuel.groupFuel.takeoffWeightActual">Take off weight actual</label>
            <input type="number"
                   class="w-100 form-control custom-sm"
                   value="{{calculation.tow}}"
                   disabled />
            <label for="takeOffWeightMAX"
                   i18n="wb|Main weight balance tab fuel@@wb.main.tab.fuel.groupFuel.takeOffWeightMax">Take off weight MAX</label>
            <input type="number"
                   class="w-100 form-control custom-sm"
                   [(ngModel)]="ahmData.takeOffWeight"
                   disabled />
            <label for="tripFuel"
                   i18n="wb|Main weight balance tab fuel@@wb.main.tab.fuel.groupFuel.tripFuel">Trip fuel</label>
            <input type="number"
                   class="w-100 form-control custom-sm"
                   [(ngModel)]="calculation.fuel.trip" />
            <label for="fuelDencity"
                   i18n="wb|Main weight balance tab fuel@@wb.main.tab.fuel.groupFuel.fuelDencity">Fuel density</label>
            <ng-select [items]="ahmData.effectOfFuel"
                       class="w-100 custom-sm"
                       bindLabel="density"
                       bindValue="density"
                       [(ngModel)]="calculation.fuelDensity">
            </ng-select>
            <label for="loadIndex"
                   i18n="wb|Main weight balance tab fuel@@wb.main.tab.fuel.groupFuel.loadIndexTakeOffWeight">Load index take off weight</label>
            <input type="number"
                   class="w-100 form-control custom-sm"
                   value="{{calculation.litow ? calculation.litow.toFixed(2) : ''}}"
                   disabled />
            <label for="MACTOW" i18n="wb|Main weight balance tab fuel@@wb.main.tab.fuel.groupFuel.mactow">MACTOW</label>
            <input type="number"
                   class="w-100 form-control custom-sm"
                   value="{{calculation.mactow ? calculation.mactow.toFixed(2) : ''}}"
                   disabled />
          </div>
        </div>
      </div>
    </div>-->
    <div *ngIf="showTab('flight-tab-passenger')" class="bg-white p-3">
      <div class="row mb-4">
        <div class="col-5">
          <div class="card h-100">
            <div class="card-body">
              <div class="d-flex"></div>
              <p class="font-weight-bold text-blue h5 mb-3"
                i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPassengersAndBaggage.table.zoneSeating">
                Zone seating
              </p>
              <div class="input-group input-group-sm mb-2">
                <div class="input-group-prepend">
                  <span class="input-group-text"
                        i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPassengersAndBaggage.placesDeclared"
                        [ngClass]="{'border-danger': getTotalPassenger() != placesDeclared() && calculation.configuration}">
                    Seats declared
                  </span>
                </div>
                <input type="number"
                       class="form-control"
                       aria-label="Sizing example input"
                       aria-describedby="inputGroup-sizing-default"
                       [value]="placesDeclared()"
                       [ngClass]="{'border-danger': getTotalPassenger() != placesDeclared() && calculation.configuration}"
                       disabled>
              </div>
              <div class="input-group input-group-sm mb-1">
                <div class="input-group-prepend">
                  <span class="input-group-text"
                        i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPassengersAndBaggage.unallocatedSeats"
                        [ngClass]="{'border-danger': getTotalPassenger() != placesDeclared() && calculation.configuration}">
                    Unallocated seats
                  </span>
                </div>
                <input type="number"
                       class="form-control"
                       aria-label="Sizing example input"
                       aria-describedby="inputGroup-sizing-default"
                       [value]="placesDeclared() - getTotalPassenger()"
                       [ngClass]="{'border-danger': getTotalPassenger() != placesDeclared() && calculation.configuration}"
                       disabled>
              </div>
              <div *ngIf="getTotalPassenger() != placesDeclared() && calculation.configuration"
                    class="text-red d-flex align-items-center">
                  <i class="material-icons mr-2">warning</i>
                  <span i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.totalPassengersNotEqualCurrent">
                    Total passengers is not equal to the current load
                  </span>
                </div>
              <table class="w-100 table-striped mt-3" *ngIf="calculation.configuration; else: selectConfiguration">
                <thead>
                  <tr class="bg-blue color-white font-weight-bold">
                    <th i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupCrew.table.crewInPassengersSeats.cabinSection">Cabin section</th>
                    <th i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupCrew.table.crewInPassengersSeats.rows">Rows</th>
                    <th i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupCrew.table.crewInPassengersSeats.max">MAX</th>
                    <th i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupCrew.table.crewInPassengersSeats.fact">Fact</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let element of calculation.passengers.cabinArea; let i = index">
                    <td>{{ element.name }}</td>
                    <td class="text-nowrap">{{ element.rowsString }}</td>
                    <td class="text-center" [ngClass]="{'text-red font-weight-bold': element.maxPassengers < element.passengers.adult}">{{ element.maxPassengers }}</td>
                    <td>
                      <input type="number" [(ngModel)]="element.passengers.adult">
                    </td>
                  </tr>
                  <tr class="bg-light-yellow">
                    <td class="font-weight-bold" colspan="3"
                      i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.total">
                      Total
                    </td>
                    <td>{{ getTotalPassenger() }}</td>
                  </tr>
                </tbody>
              </table>
              <ng-template #selectConfiguration>
                <div class="alert alert-warning mt-3"
                  i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.selectCabinVersion">
                  Select cabin version
                </div>
              </ng-template>
            </div>
          </div>
        </div>
        <div class="col-7">
          <div class="card h-100">
            <div class="card-body">
              <p class="font-weight-bold text-blue h5 mb-3"
                 i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPassengersAndBaggage.table.passengersWeight">Passenger weights
              </p>
                <table class="w-100 table-striped mb-3">
                  <tbody>
                    <tr class="bg-blue color-white font-weight-bold text-center">
                      <td i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPassengersAndBaggage.table.passengersWeight.adultMale">Adult / Male</td>
                      <td i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPassengersAndBaggage.table.passengersWeight.female">Female</td>
                      <td i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPassengersAndBaggage.table.passengersWeight.children">Children</td>
                      <td i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPassengersAndBaggage.table.passengersWeight.infant">Infant</td>
                    </tr>
                    <tr>
                      <td>
                        <input type="number" [(ngModel)]="calculation.passengers.weights.adult">
                      </td>
                      <td>
                        <input type="number" [(ngModel)]="calculation.passengers.weights.female">
                      </td>
                      <td>
                        <input type="number" [(ngModel)]="calculation.passengers.weights.child">
                      </td>
                      <td>
                        <input type="number" [(ngModel)]="calculation.passengers.weights.infant">
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="d-flex justify-content-between">
                  <div class="input-group-prepend">
                    <div class="custom-control custom-switch d-flex ml-2" style="min-width: fit-content;">
                      <input type="checkbox"
                            class="custom-control-input"
                            id="handLuggageIncluded"
                            name="handLuggageIncluded"
                            [checked]="calculation.passengers.weights.handLuggageIncluded"
                            (click)="changeHandBaggage()">
                      <label class="custom-control-label" for="handLuggageIncluded"
                      i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPassengersAndBaggage.handBaggageWeightIncluded">
                      Hand baggage weight is included in the passenger weights
                      </label>
                    </div>
                  </div>
                  <div class="input-group flex-nowrap w-auto my-auto" *ngIf="!calculation.passengers.weights.handLuggageIncluded">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0"
                        i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPassengersAndBaggage.table.passengersWeight.handBaggage">
                        Hand baggage
                      </span>
                    </div>
                    <input type="number" class="form-control custom-sm" [(ngModel)]="calculation.passengers.weights.handLuggage"/>
                  </div>
                </div>

              <!--<p class="m-0"
                 i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPassengersAndBaggage.handBaggageWeightIncluded">
                Hand baggage weight is included in the above mentioned crew weights</p>
              <label class="mr-3">
                <input type="radio"
                       name="baggageIncluded"
                       id="baggageIncludedYes"
                       [checked]="calculation.passengers.weights.handLuggageIncluded === true"
                       [value]="true"
                       [(ngModel)]="calculation.passengers.weights.handLuggageIncluded">
                <span i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPassengersAndBaggage.button.yes">Yes</span>
              </label>
              <label>
                <input type="radio"
                       name="baggageIncluded"
                       id="baggageIncludedNo"
                       [checked]="calculation.passengers.weights.handLuggageIncluded === false"
                       [value]="false"
                       [(ngModel)]="calculation.passengers.weights.handLuggageIncluded">
                <span i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPassengersAndBaggage.button.no">No</span>
              </label>
              <p i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPassengersAndBaggage.table.passengersWeight.handBaggage">Hand baggage</p>
              <input type="number" [(ngModel)]="calculation.passengers.weights.handLuggage">-->
            </div>
            <div class="row">
              <div class="col-6">
                <!--<div class="col-4">
                  <p class="font-weight-bold mb-3">Passengers</p>
                  <table class="w-100 table-striped">
                    <thead>
                      <tr class="bg-blue color-white font-weight-bold">
                        <th>Destinat</th>
                        <th>M</th>
                        <th>F</th>
                        <th>C</th>
                        <th>I</th>
                        <th>Weight</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>OVB</td>
                        <td contenteditable="true">19</td>
                        <td contenteditable="true">5</td>
                        <td contenteditable="true">2</td>
                        <td contenteditable="true"></td>
                        <td contenteditable="true">2100</td>
                      </tr>
                      <tr class="bg-light-yellow">
                        <td>TOTAL</td>
                        <td contenteditable="true">19</td>
                        <td contenteditable="true">5</td>
                        <td contenteditable="true">2</td>
                        <td contenteditable="true"></td>
                        <td contenteditable="true">2100</td>
                      </tr>
                    </tbody>
                  </table>
                </div>-->
                <!--<div class="col-4">
                  <p class="font-weight-bold mb-3">Baggage</p>
                  <table class="w-100 table-striped">
                    <thead>
                      <tr class="bg-blue color-white font-weight-bold text-center">
                        <th></th>
                        <th colspan="2">T</th>
                        <th colspan="2">Y</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="font-weight-bold">
                        <td>Destinat</td>
                        <td>P</td>
                        <td>W</td>
                        <td>P</td>
                        <td>W</td>
                      </tr>
                      <tr>
                        <td>OVB</td>
                        <td contenteditable="true"></td>
                        <td contenteditable="true"></td>
                        <td contenteditable="true"></td>
                        <td contenteditable="true"></td>
                      </tr>
                      <tr class="bg-light-yellow">
                        <td>TOTAL</td>
                        <td contenteditable="true"></td>
                        <td contenteditable="true"></td>
                        <td contenteditable="true"></td>
                        <td contenteditable="true"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>-->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
         <div class="col-6">
          <div class="card">
            <div class="card-body">
              <p class="font-weight-bold text-blue h5 mb-3"
                i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupCrew">
                Crew
              </p>
              <table class="w-100 table-striped">
                <thead>
                  <tr class="bg-blue color-white font-weight-bold">
                    <th i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupCrew.table.type">Type</th>
                    <th i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPasengers.table.amount">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPasengers.table.standardCrew">Standard cockpit crew</td>
                    <td>{{calculation.crew.schema.cockpit}}</td>
                  </tr>
                  <tr>
                    <td i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPasengers.table.standardStewards">Standard cabin crew</td>
                    <td>{{calculation.crew.schema.cabin}}</td>
                  </tr>
                  <tr>
                    <td i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPasengers.table.additionalCrew">Additional cockpit crew</td>
                    <td>{{calculation.crew.additional.cabin}}</td>
                  </tr>
                  <tr>
                    <td i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPasengers.table.additionalStewards">Additional cabin crew</td>
                    <td>{{calculation.crew.additional.cockpit}}</td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold" i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPasengers.table.additionalCrewInCabin">Additional crew in the cabin</td>
                    <td>{{calculation.crew.additional.inCabin}}</td>
                  </tr>
                  <!-- <tr class="bg-light-yellow">
                    <td class="font-weight-bold"
                      i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.total">
                      Total
                    </td>
                    <td>{{ calculation.crew.additional.inCabin }}</td>
                  </tr> -->
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="card">
            <div class="card-body">
              <p class="font-weight-bold text-blue h5 mb-3"
                i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPasengers">Passengers</p>
              <div class="row">
                <div class="col-6">
                  <p class="font-weight-bold mb-0"
                    i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.totalPassengers">Total passengers</p>
                  <table class="w-100 table-striped">
                    <thead>
                      <tr class="bg-blue color-white font-weight-bold">
                        <th i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPasengers.table.passengersSeats.type">Type</th>
                        <th i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPasengers.table.passengersSeats.amount">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.adult">Adult</td>
                        <td>{{flight.loading.adult}}</td>
                      </tr>
                      <tr>
                        <td i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.child">Child</td>
                        <td>{{flight.loading.child}}</td>
                      </tr>
                      <tr>
                        <td i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.infant">Infant</td>
                        <td>{{flight.loading.infant}}</td>
                      </tr>
                      <tr class="bg-light-yellow">
                        <td class="font-weight-bold"
                          i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.total">
                          Total
                        </td>
                        <td>{{ flight.loading.adult + flight.loading.child + flight.loading.infant }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col-6">
                  <p class="font-weight-bold mb-0"
                    i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.occupiedSeats">Occupied seats</p>
                  <table class="w-100 table-striped">
                    <thead>
                      <tr class="bg-blue color-white font-weight-bold">
                        <th i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPasengers.table.passengersSeats.type">Type</th>
                        <th i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPasengers.table.passengersSeats.amount">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.business">Business</td>
                        <td>{{flight.loading.business}}</td>
                      </tr>
                      <tr>
                        <td i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.economy">Economy</td>
                        <td>{{flight.loading.economy}}</td>
                      </tr>
                      <tr class="bg-light-yellow">
                        <td class="font-weight-bold"
                          i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.total">
                          Total
                        </td>
                        <td>{{flight.loading.business + flight.loading.economy}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="showTab('flight-tab-documents')" class="bg-white p-3">
        <div class="d-flex flex-column">
          <div>
            <div class="row">
              <div class="col-4">
                <div class="alert alert-danger" role="alert"
                      *ngIf="printDisabled; else printBlock"
                      i18n="wb|Main weight balance tab documents@@wb.main.tab.documents.text.error">
                  Aircraft alignment is not correct: the function of printing and saving documents is not available
                </div>
                <ng-template #printBlock>
                  <div class="form-group">
                    <label i18n="wb|Main weight balance tab documents@@wb.main.tab.documents.editionNumber">Edition number</label>
                    <button class="btn btn-xs btn-ico btn-blue mb-2 w-100"
                            i18n="wb|Main weight balance tab documents@@wb.main.tab.documents.button.createNewEdition"
                            (click)="createDocuments()">Create a new edition of documents</button>
                    <ng-select [items]="documentEditions"
                               bindLabel="edno"
                               bindValue="edno"
                               class="custom-sm w-100 mb-3"
                               (change)="documentText = null; currentDocumentView = null"
                               [(ngModel)]="currentDocumentEditions"
                               [searchable]="false">
                    </ng-select>
                  </div>
                  <label i18n="wb|Main weight balance tab documents@@wb.main.tab.documents.released">Released</label>
                  <div class="input-group mb-3">
                    <input type="text"
                           class="form-control"
                           value="{{getDocumentValue(currentDocumentEditions, 'preparedBy')}}"
                           disabled>
                    <input type="text"
                           class="form-control"
                           value="{{getDocumentValue(currentDocumentEditions, 'preparedAt') | date: 'dd.MM.yyyy HH:mm' }}"
                           disabled>
                  </div>
                  <hr>
                  <div *ngIf="currentDocumentEditions > 0">
                    <p i18n="wb|Main weight balance tab documents@@wb.main.tab.documents.documentType">Document type</p>
                    <div class="list-group">
                      <a *ngFor="let document of documentTypes; let i = index"
                         class="list-group-item list-group-item-action"
                         (click)="loadDocument(document.url); currentDocumentView = document.id"
                         [ngClass]="{'active': currentDocumentView === document.id}">
                        <div class="d-flex align-items-center">
                          <div class="flex-fill">{{document.type}}</div>
                          <!-- TODO Кнопки [disabled] привязать к выходу за границу центровки -->
                          <button class="btn btn-xs btn-ico btn-light-gray mr-1"
                                  (click)="saveDocument(document.url)"><span class="material-icons">
                              file_download
                            </span></button>
                          <!-- TODO Кнопки [disabled] привязать к выходу за границу центровки -->
                          <button class="btn btn-xs btn-ico btn-light-gray"
                                  (click)="printDocument(document.url)"><span class="material-icons">
                              print
                            </span></button>
                        </div>
                      </a>
                    </div>
                    <button #print
                            printSectionId="print-section"
                            ngxPrint
                            hidden="true"
                            [useExistingCss]="true"></button>
                  </div>

                </ng-template>
                <p class="my-3" i18n="wb|Main weight balance tab documents@@wb.main.tab.documents.siForLdm">Supplementary information (SI) for LDM</p>
                <div class="validation-textarea">
                  <textarea name="si" id="si"
                      class="form-control h-100 text-uppercase p-2 pl-4 text-monospace"
                      rows="10"
                      style="min-height: 100px;"
                      [(ngModel)]="calculation.si"
                      (input)="calculation.si = splitIntoLines($event.target.value, $event.target)"></textarea>
                  <div class="check-error">
                    <div>
                      <div *ngFor="let line of verifiedMessage" class="check-error-line">
                        <div class="has-error text-center">
                          <i *ngIf="line[0]" ngbTooltip="{{line[2]}}"
                            class="material-icons text-red">error</i>
                        </div>
                        <div class="text-line text-uppercase">{{line[1]}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-8 p-0">
                <div>
                  <div class="d-flex align-items-center justify-content-between mb-2">
                    <p class="m-0" i18n="wb|Main weight balance tab documents@@wb.main.tab.documents.documentView">Document view</p>
                    <!-- <button class="btn btn-sm btn-blue"
                            i18n="wb|Main weight balance tab documents@@wb.main.tab.documents.button.print"
                            *ngIf="documentText !== ''"
                            [useExistingCss]="true"
                            printSectionId="print-section"
                            ngxPrint>Print</button> -->
                  </div>
                  <textarea rows="38" class="w-100 p-2 border text-monospace" style="font-size: 12px;" readonly>{{ documentText }}</textarea>
                  <div id="print-section" class="d-none">
                    <span class="message-text-wrapping text-monospace m-0">{{ documentText }}</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="d-flex">
              <ng-select [items]="documentTypes"
                         class="custom-sm w-100 mb-3"
                         bindLabel="type"
                         bindValue="url"
                         [(ngModel)]="documentUrl">
              </ng-select>
              <button class="btn btn-sm btn-blue ml-3 flex-shrink-1"
                      i18n="wb|Main weight balance tab documents@@wb.main.tab.documents.button.download"
                      (click)="saveDocument(documentUrl)">Download</button>
              <button class="btn btn-sm btn-blue ml-3 flex-shrink-1"
                      i18n="wb|Main weight balance tab documents@@wb.main.tab.documents.button.create"
                      (click)="loadDocument(documentUrl)">Create</button>
            </div> -->
          </div>
        </div>
    </div>
  </div>
  <div class="col-4">
    <div class="card mb-3">
      <div class="card-body">
        <div class="d-flex flex-column">
          <p class="font-weight-bold text-blue h5 mb-3 flex-grow-1"
            i18n="wb|Main weight balance calculation@@wb.main.calculation.weightAndBalanceValues">
            Weight and balance values
          </p>
          <div class="alert alert-warning" role="alert"
                *ngIf="calculation.fuel.ballast > 0 && deltaBallast.deltaDow !== 0 && deltaBallast.deltaDoi !== 0"
                i18n="wb|Main weight balance calculation@@wb.main.calculation.addDeltaBallast">
              Add the ballast fuel Δ to the current DOW/DOI values
          </div>
          <div class="alert alert-warning" role="alert" *ngIf="ahmData.lastupdate > calculation.ahmLastupdate"
                i18n="wb|Main weight balance calculation@@wb.main.calculation.oldData">
            Changes found in AHM, current calculation uses old data
          </div>
          <div class="alert alert-danger" role="alert" *ngIf="printDisabled"
                i18n="wb|Main weight balance calculation@@wb.main.calculation.error">
            Aircraft alignment is not correct
          </div>
          <div class="alert alert-success fade show" role="alert" *ngIf="calculationCorrect">
            <div class="d-flex justify-content-between">
              <div i18n="wb|Main weight balance calculation@@wb.main.calculation.correct">Aircraft alignment is correct</div>
              <div contenteditable="true"><small><span i18n="wb|Main weight balance calculation@@wb.main.calculation.name">Calculation</span>&nbsp;{{calculation.lastupdate | date: 'dd.MM.yyyy HH:mm'}}</small></div>
            </div>
          </div>
          <div class="d-flex">
            <div class="btn-group btn-group-toggle h-auto flex-grow-1" data-toggle="buttons">
              <label class="btn font-small pt-1 btn-xs"
                     [ngClass]="{'active bg-blue text-white': !chartIsShow,
                                      'bg-light-gray border': chartIsShow}">
                <input type="radio"
                       name="options"
                       id="table"
                       (click)="changeWeightAndBalanceView('table')"><span i18n="wb|Main weight balance calculation@@wb.main.calculation.weightAndBalanceValues.button.table">Table</span>
              </label>
              <label class="btn font-small pt-1 btn-xs"
                     [ngClass]="{'active bg-blue text-white': chartIsShow,
                          'bg-light-gray border': !chartIsShow}">
                <input type="radio"
                       name="options"
                       id="chart"
                       (click)="changeWeightAndBalanceView('chart')"><span i18n="wb|Main weight balance calculation@@wb.main.calculation.weightAndBalanceValues.button.chart">Chart</span>
              </label>
            </div>
              <button class="btn btn-ico btn-light-gray px-3 ml-2 h-auto"
                      style="min-width: auto;"
                      data-toggle="modal"
                      data-target=".bd-example-modal-xl"><i class="material-icons">open_in_new</i></button>
          </div>
        </div>
        <div *ngIf="!chartIsShow">
          <p class="font-weight-bold text-muted mt-3 mb-0" i18n="wb|Main weight balance calculation@@wb.main.calculation.dryPlane">Dry plane</p>
          <table class="w-100 table-striped">
            <tbody>
              <tr>
                <td class="font-weight-bold d-flex align-items-center">DOW</td>
                <td i18n="wb|Main weight balance calculation@@wb.main.calculation.dryOperationWeight">Dry operation weight</td>
                <td class="font-weight-bold">{{ calculation.dow }}</td>
              </tr>
              <tr>
                <td class="font-weight-bold d-flex align-items-center">DOI</td>
                <td i18n="wb|Main weight balance calculation@@wb.main.calculation.dryOperationIndex">Dry operating index</td>
                <td class="font-weight-bold">{{ calculation.doi }}</td>
              </tr>
            </tbody>
          </table>
          <p class="font-weight-bold text-muted mt-3 mb-0">MACZFW <span class="text-blue">{{ calculation.maczfw ? calculation.maczfw.toFixed(2) : '' }}</span></p>
          <table class="w-100 table-striped">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col" i18n="wb|Main weight balance calculation@@wb.main.calculation.curent">Current</th>
                <th scope="col" i18n="wb|Main weight balance calculation@@wb.main.calculation.max">Max</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="font-weight-bold d-flex align-items-center">LIZFW</td>
                <td i18n="wb|Main weight balance calculation@@wb.main.calculation.loadedIndexAtZeroFuelWeight">Loaded index at zero fuel weight</td>
                <td class="font-weight-bold">{{ calculation.lizfw ? calculation.lizfw.toFixed(2) : '' }}</td>
                <td></td>
              </tr>
              <tr>
                <td class="font-weight-bold d-flex align-items-center">TTL</td>
                <td i18n="wb|Main weight balance calculation@@wb.main.calculation.totalTrafficLoad">Total traffic load</td>
                <td class="font-weight-bold">{{ calculation.ttl }}</td>
                <td></td>
              </tr>
              <tr>
                <td class="font-weight-bold d-flex align-items-center"
                    [ngClass]="{'text-red': calculation.zfw > ahmData.zeroFuelWeight}">ZFW</td>
                <td i18n="wb|Main weight balance calculation@@wb.main.calculation.zeroFuelWeight">Zero fuel weight</td>
                <td class="font-weight-bold"
                    [ngClass]="{'text-red': calculation.zfw > ahmData.zeroFuelWeight}">{{ calculation.zfw }}</td>
                <td>{{ ahmData.zeroFuelWeight }}</td>
              </tr>
            </tbody>
          </table>
          <p class="font-weight-bold text-muted mt-3 mb-0">MACTOW <span class="text-blue">{{ calculation.mactow ? calculation.mactow.toFixed(2) : '' }}</span></p>
          <table class="w-100 table-striped">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col" i18n="wb|Main weight balance calculation@@wb.main.calculation.curent">Current</th>
                <th scope="col" i18n="wb|Main weight balance calculation@@wb.main.calculation.max">Max</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="font-weight-bold">FOB</td>
                <td i18n="wb|Main weight balance calculation@@wb.main.calculation.fuelOnBoard">Fuel on board</td>
                <td class="font-weight-bold">{{ calculation.fuel.onBoard }}</td>
                <td></td>
              </tr>
              <tr>
                <td class="font-weight-bold">Taxi</td>
                <td i18n="wb|Main weight balance calculation@@wb.main.calculation.taxiFuel">Taxi fuel</td>
                <td class="font-weight-bold">{{ calculation.fuel.taxi }}</td>
                <td></td>
              </tr>
              <tr>
                <td class="font-weight-bold">TOF</td>
                <td i18n="wb|Main weight balance calculation@@wb.main.calculation.takeOffFuel">Take off fuel</td>
                <td class="font-weight-bold">{{ calculation.fuel.onBoard - calculation.fuel.taxi }}</td>
                <td></td>
              </tr>
              <tr>
                <td class="font-weight-bold"
                    [ngClass]="{'text-red': calculation.tow > calculation.mtow}">TOW
                </td>
                <td i18n="wb|Main weight balance calculation@@wb.main.calculation.takeOffWeightActual">
                  Take off weight actual
                </td>
                <td [ngClass]="{'text-red': calculation.tow > calculation.mtow}"
                    class="font-weight-bold">{{ calculation.tow }}</td>
                <td>{{ calculation.mtow }}</td>
              </tr>
              <tr>
                <td class="font-weight-bold">LITOW</td>
                <td i18n="wb|Main weight balance calculation@@wb.main.calculation.loadedIndexAtTakeOffWeight">Loaded index at take off weight</td>
                <td class="font-weight-bold">{{ calculation.litow ? calculation.litow.toFixed(2) : '' }}</td>
                <td></td>
              </tr>
            </tbody>
          </table>
          <p class="font-weight-bold text-muted mt-3 mb-0">MACLAW <span class="text-blue">{{ calculation.maclaw ? calculation.maclaw.toFixed(2) : '' }}</span></p>
          <table class="w-100 table-striped">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col" i18n="wb|Main weight balance calculation@@wb.main.calculation.curent">Current</th>
                <th scope="col" i18n="wb|Main weight balance calculation@@wb.main.calculation.max">Max</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="font-weight-bold" [ngClass]="{'text-red': calculation.lw > calculation.mlw}">LAW</td>
                <td i18n="wb|Main weight balance calculation@@wb.main.calculation.landingWeightActual">Landing weight actual </td>
                <td [ngClass]="{'text-red': calculation.lw > calculation.mlw}"
                    class="font-weight-bold">{{ calculation.lw }}</td>
                <td>{{ calculation.mlw }}</td>
              </tr>
              <tr>
                <td class="font-weight-bold">LILAW</td>
                <td i18n="wb|Main weight balance calculation@@wb.main.calculation.loadedIndexAtLandingWeight">Loaded index at landing weight</td>
                <td class="font-weight-bold">{{ calculation.lilaw ? calculation.lilaw.toFixed(2) : '' }}</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div [ngStyle]="{'height': !chartIsShow ? '0' : 'auto'}" style="overflow: hidden;">
          <div id="gravityCentreBlock" class="mt-3" #gravityCentreBlock></div>
          <table class="w-100 table-striped mt-3">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col" i18n="wb|Main weight balance calculation@@wb.main.calculation.curent">Current</th>
                <th scope="col" i18n="wb|Main weight balance calculation@@wb.main.calculation.max">Max</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="font-weight-bold d-flex align-items-center"
                    [ngClass]="{'text-red': calculation.zfw > ahmData.zeroFuelWeight}">ZFW</td>
                <td i18n="wb|Main weight balance calculation@@wb.main.calculation.zeroFuelWeight">Zero fuel weight</td>
                <td class="font-weight-bold"
                    [ngClass]="{'text-red': calculation.zfw > ahmData.zeroFuelWeight}">{{ calculation.zfw }}</td>
                <td>{{ ahmData.zeroFuelWeight }}</td>
              </tr>
              <tr>
                <td class="font-weight-bold"
                    [ngClass]="{'text-red': calculation.tow > calculation.mtow}">TOW
                </td>
                <td i18n="wb|Main weight balance calculation@@wb.main.calculation.takeOffWeightActual">
                  Take off weight actual
                </td>
                <td [ngClass]="{'text-red': calculation.tow > calculation.mtow}"
                    class="font-weight-bold">{{ calculation.tow }}</td>
                <td>{{ calculation.mtow }}</td>
              </tr>
              <tr>
                <td class="font-weight-bold" [ngClass]="{'text-red': calculation.lw > calculation.mlw}">LAW</td>
                <td i18n="wb|Main weight balance calculation@@wb.main.calculation.landingWeightActual">Landing weight actual </td>
                <td [ngClass]="{'text-red': calculation.lw > calculation.mlw}"
                    class="font-weight-bold">{{ calculation.lw }}</td>
                <td>{{ calculation.mlw }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- <div class="card">
      <div class="card-body">
        <div class="d-flex align-items-center justify-content-between mb-3">
          <p class="font-weight-bold text-blue h5 m-0"
            i18n="wb|Main weight balance calculation@@wb.main.calculation.centureOfGravity">
            Centre of gravity
          </p>
          <button class="btn btn-xs btn-ico btn-light-gray"
          data-toggle="modal"
          data-target=".bd-example-modal-xl"><i class="material-icons">open_in_new</i></button>
        </div>
        <div id="gravityCentreBlock" #gravityCentreBlock></div>
      </div>
    </div> -->
  </div>
</div>
  <div class="modal fade bd-example-modal-xl" data-backdrop="static" tabindex="-1" role="dialog"
       aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xxl">
      <div class="modal-content bg-light-gray">
        <div class="modal-header">
          <h5 class="modal-title">
            <span i18n="wb|Main weight balance calculation@@wb.main.calculation.centureOfGravity">Centre of gravity</span>
          </h5>
          <button #airportFormFade type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body bg-white">
          <div class="text-center" id="gravityCentreBlockBig" #gravityCentreBlockBig></div>
        </div>
        <div class="modal-footer">
          <button type="button"
                  class="btn btn-secondary btn-sm"
                  data-dismiss="modal"
                  i18n="wb|Main weight balance - modal button close@@wb.main.calculation.button.close">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal -->
<div class="modal fade bd-example-modal-xl mw-100" data-backdrop="static" tabindex="-1" role="dialog"
aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xxl load--max-width">
    <div class="modal-content bg-light-gray">
      <form #flightForm="ngForm">
        <!--<div class="loading" *ngIf="loading">
          <div class="cssload-spin-box"></div>
        </div>-->
        <div class="modal-header">
          <h5 class="modal-title"
              id="exampleModalScrollableTitle">
            <span i18n="sch|Flight card - header name@@sch.flightCard.header.name">Flight card</span>
          </h5>
          <button #closebutton type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-nav pl-3 pr-3 pb-1">
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
              <a class="nav-link active" id="route-tab"
                i18n="sch|Flight card tabs - route@@sch.flightCard.tabs.route"
                data-toggle="tab" href="#route" role="tab">Route</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="loading-tab"
                i18n="sch|Flight card tabs - payload departure@@sch.flightCard.tabs.payloadDeparture"
                data-toggle="tab" href="#loading" role="tab">Payload Departure</a>
            </li>
          </ul>
        </div>
        <div class="modal-body">
          <div class="tab-content pt-3" id="myTabContent">
            <div class="tab-pane fade show active" id="route" role="tabpanel" aria-labelledby="route-tab">
              <div class="row mb-2">
                <div class="pl-0" class="col-8">
                  <div class="card bg-none">
                    <div class="card-body p-3 bg-white rounded">
                      <p class="m-0 h6 card__header--important">
                        <i class="material-icons">apartment</i>
                        <span i18n="sch|Flight card tab route - group airline name@@sch.flightCard.tab.route.groupAirline.name">Airline</span>
                      </p>
                      <div class="row">
                        <div class="col-2 pr-0">
                          <label class="col-form-label col-form-label-sm">
                            <span i18n="sch|Flight card tab route - group airline iata@@sch.flightCard.tab.route.groupAirline.iata">IATA</span><span class="required-asterisk">*</span>
                          </label>
                          <ng-select class="form-control custom-sm p-0"
                            [items]="airlinesSortIata"
                            bindLabel="iata"
                            bindValue="id"
                            [(ngModel)]="flight.airlineId"
                            #airlineIata="ngModel"
                            name="airlineIata"
                            [virtualScroll]="true"
                            [loading]="selectLoadAnimation.airlines"
                            required>
                          </ng-select>
                          <div *ngIf="!airlineIata?.valid && (airlineIata?.dirty || airlineIata?.touched)"
                            class="invalid-feedback d-block">
                            <div *ngIf="airlineIata.errors.required"
                                i18n="sch|Flight card tab route - group airline iata req@@sch.flightCard.tab.route.groupAirline.iata.req">
                              IATA is required
                            </div>
                          </div>
                        </div>
                        <div class="col-2 pr-0">
                          <label class="col-form-label col-form-label-sm">
                            <span i18n="sch|Flight card tab route - group airline icao@@sch.flightCard.tab.route.groupAirline.icao">ICAO</span><span class="required-asterisk">*</span>
                          </label>
                          <ng-select class="form-control custom-sm p-0"
                            [items]="airlinesSortIcao"
                            bindLabel="icao"
                            bindValue="id"
                            [(ngModel)]="flight.airlineId"
                            name="airlineIcao"
                            #airlineIcao="ngModel"
                            [virtualScroll]="true"
                            [loading]="selectLoadAnimation.airlines"
                            required>
                          </ng-select>
                          <div *ngIf="!airlineIcao?.valid && (airlineIcao?.dirty || airlineIcao?.touched)"
                            class="invalid-feedback d-block">
                            <div *ngIf="airlineIcao.errors.required"
                                i18n="sch|Flight card tab route - group airline icao req@@sch.flightCard.tab.route.groupAirline.icao.req">
                              ICAO is required
                            </div>
                          </div>
                        </div>
                        <div class="col-5 pr-0">
                          <label class="col-form-label col-form-label-sm">
                            <span i18n="sch|Flight card tab route - group airline name@@sch.flightCard.tab.route.groupAirline.nameAirline">Name</span><span class="required-asterisk">*</span>
                          </label>
                          <ng-select class="form-control custom-sm p-0"
                            [items]="references.airlines"
                            bindLabel="name"
                            bindValue="id"
                            [(ngModel)]="flight.airlineId"
                            name="airlineName"
                            #airlineName="ngModel"
                            [virtualScroll]="true"
                            [loading]="selectLoadAnimation.airlines"
                            required>
                          </ng-select>
                          <div *ngIf="!airlineName?.valid && (airlineName?.dirty || airlineName?.touched)"
                            class="invalid-feedback d-block">
                            <div *ngIf="airlineName.errors.required"
                                i18n="sch|Flight card tab route - group airline name req@@sch.flightCard.tab.route.groupAirline.name.req">
                              Name is required
                            </div>
                          </div>
                        </div>
                        <div class="col-3">
                          <label class="col-form-label col-form-label-sm">
                            <span i18n="sch|Flight card tab route - group airline flight@@sch.flightCard.tab.route.groupAirline.flight">Flight</span><span class="required-asterisk">*</span>
                          </label>
                          <input type="text"
                                class="form-control form-control-sm"
                                name="flightinput"
                                [(ngModel)]="flight.flightNumber"
                                #flightinput="ngModel"
                                required>
                          <div *ngIf="!flightinput?.valid && (flightinput?.dirty || flightinput?.touched)"
                            class="invalid-feedback d-block">
                            <div *ngIf="flightinput.errors.required"
                                i18n="sch|Flight card tab route - group airline flight req@@sch.flightCard.tab.route.groupAirline.flight.req">
                              Flight code is required
                            </div>
                            <div *ngIf="flightinput.errors.pattern"
                                i18n="sch|Flight card tab route - group airline flight en only@@sch.flightCard.tab.route.groupAirline.flight.enOnlyNumber">
                              Flight code is English only and number
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-4 pl-0">
                  <div class="card bg-none">
                    <div class="card-body p-3 bg-white rounded">
                      <p class="m-0 h6 card__header--important">
                        <i class="material-icons">flight_takeoff</i>
                        <span i18n="sch|Flight card tab route - group departure name@@sch.flightCard.tab.route.groupDeparture.name">Departure</span>
                      </p>
                      <div class="row">
                        <div class="col-6 pr-1">
                          <label for="departureType"
                                class="col-form-label col-form-label-sm"
                                i18n="sch|Flight card tab route - group departure tail@@sch.flightCard.tab.route.groupDeparture.tail">
                            A/C registration
                          </label>
                          <ng-select class="form-control custom-sm p-0"
                                    [items]="references.tails"
                                    bindLabel="tail"
                                    bindValue="id"
                                    name="tail"
                                    id="tail"
                                    [(ngModel)]="flight.tailId"
                                    [loading]="selectLoadAnimation.tails"
                                    (change)="findAircraftTypeByTail()"
                                    [searchFn]="customSelectSearchTail"
                                    [virtualScroll]="true">
                          </ng-select>
                        </div>
                        <div class="col-6 pl-0 pr-1">
                          <label for="departureTail"
                                class="col-form-label col-form-label-sm"
                                i18n="sch|Flight card tab route - group departure type@@sch.flightCard.tab.route.groupDeparture.type">
                            A/C type
                          </label>
                          <ng-select class="form-control custom-sm p-0"
                                    [items]="references.aircraft_types"
                                    bindLabel="iata"
                                    bindValue="id"
                                    name="aircraftTypeIdD"
                                    id="aircraftTypeIdD"
                                    [(ngModel)]="flight.aircraftTypeId"
                                    [loading]="selectLoadAnimation.aircraft_types"
                                    [virtualScroll]="true">
                          </ng-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="my-1 p-0 float-right">
                <button type="button"
                        class="btn btn-sm btn-blue mr-1"
                        (click)="addRoutePoint()">
                  <i class="material-icons">add_circle_outline</i>
                  <span i18n="sch|Flight card tab route - button add route point@@sch.flightCard.tab.route.button.addRoutePoint">
                    Add route point
                  </span>
                </button>
                <button type="button"
                        class="btn btn-sm btn-red"
                        (click)="deleteRoute()">
                  <i class="material-icons">delete</i>
                  <span i18n="sch|Flight card tab route - button delete route point@@sch.flightCard.tab.route.button.deleteRoutePoint">
                    Delete
                  </span>
                </button>
              </div>
              <table class="table table-borderless table-hover mt-3">
                <thead>
                  <tr class="text-light type-head table__main-header">
                    <th scope="col"
                        colspan="4"
                        class="text-center font-large head-border-right-bold"
                        style="border-bottom-left-radius: 0;"
                        i18n="sch|Flight card tab route - route table column arrival@@sch.flightCard.tab.route.table.column.arrival">
                      Arrival
                    </th>
                    <th scope="col"
                        class="text-center font-large align-middle head-border-right-bold"
                        i18n="sch|Flight card tab route - route table column destination@@sch.flightCard.tab.route.table.column.destination">
                      Destination
                    </th>
                    <th scope="col"
                        colspan="3"
                        class="text-center font-large"
                        style="border-bottom-right-radius: 0;"
                        i18n="sch|Flight card tab route - route table column departure@@sch.flightCard.tab.route.table.column.departure">
                      Departure
                    </th>
                  </tr>
                  <tr class="text-muted detail-head table__mail-header--subhead">
                    <th class="font-small text-center text-uppercase head-border-top" style="border-top-left-radius: 0;">
                    </th>
                    <th class="font-small text-center text-uppercase head-border-top">
                      <span i18n="sch|Flight card tab route - route table column schedule time arrival@@sch.flightCard.tab.route.table.column.scheduleTimeArrival">STA</span><span class="required-asterisk">*</span></th>
                    <th class="font-small text-center text-uppercase head-border-top"
                        i18n="sch|Flight card tab route - route table column estimated time arrival@@sch.flightCard.tab.route.table.column.estimatedTimeArrival">
                      ETA
                    </th>
                    <th class="font-small text-center text-uppercase head-border-top head-border-right-bold"
                        i18n="sch|Flight card tab route - route table column actual time arrival@@sch.flightCard.tab.route.table.column.actualTimeArrival">
                      ATA
                    </th>
                    <th class="font-small text-center text-uppercase head-border-top head-border-right-bold"
                        i18n="sch|Flight card tab route - route table column city\airport@@sch.flightCard.tab.route.table.column.cityAirport">
                      City\Airport
                    </th>
                    <th class="font-small text-center text-uppercase head-border-top">
                      <span i18n="sch|Flight card tab route - route table column schedule time departure@@sch.flightCard.tab.route.table.column.scheduleTimeDeparture">STD</span><span class="required-asterisk">*</span></th>
                    <th class="font-small text-center text-uppercase head-border-top"
                        i18n="sch|Flight card tab route - route table column estimated time departure@@sch.flightCard.tab.route.table.column.estimatedTimeDeparture">
                      ETD
                    </th>
                    <th class="font-small text-center text-uppercase head-border-top"
                        i18n="sch|Flight card tab route - route table column actual time departure@@sch.flightCard.tab.route.table.column.actualTimeDeparture">
                      ATD
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let route of flight.route; let i = index" class="route-date">
                    <td class="text-center">
                      <div class="input-group-xs">
                        <input type="checkbox" name="checkboxRoute" value="{{i}}" (click)="addToDeleteQueue(i)"
                          aria-label="Checkbox for following text input">
                      </div>
                    </td>
                    <td class="text-center body-border__right--gray-normal">
                      <div class="input-group-sm">
                        <input type="time" class="form-control my-1" [ngModel]="route.dtArrivalScheduled | date: 'HH:mm'"
                          (input)="route.dtArrivalScheduled = parseTime(Arrival_date_0, $event.target.value)"
                          name="dtArrivalScheduledTime{{i}}" #Arrival_time_0 [disabled]="i===0"
                          #dtArrivalScheduledTime{{i}}="ngModel" [required]="i!==0">
                        <input type="date" class="form-control my-1"
                          [ngModel]="route.dtArrivalScheduled | date: 'yyyy-MM-dd'"
                          (input)="route.dtArrivalScheduled = parseDate($event.target.value, Arrival_time_0.value)"
                          name="dtArrivalScheduledDate{{i}}" #Arrival_date_0 [disabled]="i===0"
                          #dtArrivalScheduledDate{{i}}="ngModel" [required]="i!==0">
                      </div>
                    </td>
                    <td class="text-center body-border__right--gray-normal">
                      <div class="input-group-sm">
                        <input type="time" class="form-control my-1" [ngModel]="route.dtArrivalEstimated | date: 'HH:mm'"
                          (input)="route.dtArrivalEstimated = parseTime(Arrival_date_1, $event.target.value)"
                          name="dtArrivalEstimatedTime{{i}}" #Arrival_time_1 [disabled]="i===0">
                        <input type="date" class="form-control my-1"
                          [ngModel]="route.dtArrivalEstimated | date: 'yyyy-MM-dd'"
                          (input)="route.dtArrivalEstimated = parseDate($event.target.value, Arrival_time_1.value)"
                          name="dtArrivalEstimatedDate{{i}}" #Arrival_date_1 [disabled]="i===0">
                      </div>
                    </td>
                    <td class="text-center body-border__right--gray-normal">
                      <div class="input-group-sm">
                        <input type="time" class="form-control my-1" [ngModel]="route.dtArrivalFact | date: 'HH:mm'"
                          (input)="route.dtArrivalFact = parseTime(Arrival_date_2, $event.target.value)"
                          name="dtArrivalFactTime{{i}}" #Arrival_time_2 [disabled]="i===0">
                        <input type="date" class="form-control my-1" [ngModel]="route.dtArrivalFact | date: 'yyyy-MM-dd'"
                          (input)="route.dtArrivalFact = parseDate($event.target.value, Arrival_time_2.value)"
                          name="dtArrivalFactDate{{i}}" #Arrival_date_2 [disabled]="i===0">
                      </div>
                    </td>
                    <td class="text-center">
                      <div class="">
                        <ng-select class="form-control airports-long p-0"
                                  [items]="airportsSortIata"
                                  bindLabel="iata"
                                  bindValue="id"
                                  [(ngModel)]="route.airportId"
                                  #airlineId{{i}}="ngModel"
                                  name="airlineId{{i}}"
                                  [virtualScroll]="true"
                                  [loading]="selectLoadAnimation.airports"
                                  (scroll)="onScrollNgSelect($event, 'airports')"
                                  (scrollToEnd)="onScrollToEndNgSelect('airports')"
                                  [searchFn]="customSelectSearch"
                                  appendTo="body"
                                  required>
                          <ng-template ng-option-tmp let-item="item">
                            <div class="font-small">
                              <span class="font-weight-bold">{{item.iata}}</span>&nbsp;{{item.name}}
                            </div>
                          </ng-template>
                        </ng-select>
                      </div>
                    </td>
                    <td class="text-center body-border__right--gray-normal">
                      <div class="input-group-sm">
                        <input type="time" class="form-control my-1"
                          [ngModel]="route.dtDepartureScheduled | date: 'HH:mm'"
                          (input)="route.dtDepartureScheduled = parseTime(Departure_date_0, $event.target.value)"
                          name="dtDepartureScheduledTime{{i}}" #Departure_time_0 [disabled]="i===flight.route.length-1"
                          #dtDepartureScheduledTime{{i}}="ngModel" [required]="i!==flight.route.length-1">
                        <input type="date" class="form-control my-1"
                          [ngModel]="route.dtDepartureScheduled | date: 'yyyy-MM-dd'"
                          (input)="route.dtDepartureScheduled = parseDate($event.target.value, Departure_time_0.value)"
                          name="dtDepartureScheduledDate{{i}}" #Departure_date_0 [disabled]="i===flight.route.length-1"
                          #dtDepartureScheduledDate{{i}}="ngModel" [required]="i!==flight.route.length-1">
                      </div>
                    </td>
                    <td class="text-center body-border__right--gray-normal">
                      <div class="input-group-sm">
                        <input type="time" class="form-control my-1"
                          [ngModel]="route.dtDepartureEstimated | date: 'HH:mm'"
                          (input)="route.dtDepartureEstimated = parseTime(Departure_date_1, $event.target.value)"
                          name="dtDepartureEstimatedTime{{i}}" #Departure_time_1 [disabled]="i===flight.route.length-1">
                        <input type="date" class="form-control my-1"
                          [ngModel]="route.dtDepartureEstimated | date: 'yyyy-MM-dd'"
                          (input)="route.dtDepartureEstimated = parseDate($event.target.value, Departure_time_1.value)"
                          name="dtDepartureEstimatedDate{{i}}" #Departure_date_1 [disabled]="i===flight.route.length-1">
                      </div>
                    </td>
                    <td class="text-center body-border__right--gray-normal">
                      <div class="input-group-sm">
                        <input type="time" class="form-control my-1" [ngModel]="route.dtDepartureFact | date: 'HH:mm'"
                          (input)="route.dtDepartureFact = parseTime(Departure_date_2, $event.target.value)"
                          name="dtDepartureFactTime{{i}}" #Departure_time_2 [disabled]="i===flight.route.length-1">
                        <input type="date" class="form-control my-1"
                          [ngModel]="route.dtDepartureFact | date: 'yyyy-MM-dd'"
                          (input)="route.dtDepartureFact = parseDate($event.target.value, Departure_time_2.value)"
                          name="dtDepartureFactDate{{i}}" #Departure_date_2 [disabled]="i===flight.route.length-1">
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="tab-pane fade" id="loading" role="tabpanel" aria-labelledby="loading-tab">
              <table class="table table-borderless table-hover">
                <thead>
                  <tr class="text-light type-head table__main-header">
                    <th scope="col"
                        colspan=""
                        class="head-border-right-bold font-large text-center"
                        style="border-bottom-left-radius: 0;"
                        i18n="sch|Flight card tab loading - table loading dep column destination@@sch.flightCard.tab.loading.table.loadingDeparture.column.destination">
                      Destination
                    </th>
                        <th scope="col"
                            colspan="6"
                            class="head-border-right-bold font-large text-center"
                            i18n="sch|Flight card tab loading - table loading dep column passengers@@sch.flightCard.tab.loading.table.loading.loadingDeparture.passengers">
                          Passengers
                        </th>
                        <th scope="col"
                            colspan="3"
                            class="head-border-right-bold font-large text-center"
                            i18n="sch|Flight card tab loading - table loading dep column leggage@@sch.flightCard.tab.loading.table.loading.loadingDeparture.baggage">
                          Baggage
                        </th>
                        <th scope="col"
                            colspan="0"
                            class="head-border-right-bold font-large text-center"
                            i18n="sch|Flight card tab loading - table loading dep column cargo@@sch.flightCard.tab.loading.table.loading.loadingDeparture.cargo">
                          Cargo
                        </th>
                        <th scope="col"
                            class="font-large text-center"
                            style="border-bottom-right-radius: 0;"
                            i18n="sch|Flight card tab loading - table loading dep column mail@@sch.flightCard.tab.loading.table.loading.loadingDeparture.mail">
                          Mail
                        </th>
                      </tr>
                      <tr class="text-muted detail-head table__mail-header--subhead head-border-top">
                        <th class="text-center text-uppercase font-small head-border-right-bold"
                          style="border-top-left-radius: 0;"
                          i18n="sch|Flight card tab loading - table loading dep column city\airport@@sch.flightCard.tab.loading.table.loading.loadingDeparture.cityAirport">
                          CITY\AIRPORT
                        </th>
                        <th colspan="2"
                            class="text-center text-uppercase font-small"
                            i18n="sch|Flight card tab loading - table loading dep column adult@@sch.flightCard.tab.loading.table.loading.loadingDeparture.adult">
                          Adl
                        </th>
                        <th class="text-center text-uppercase font-small"
                            i18n="sch|Flight card tab loading - table loading dep column child@@sch.flightCard.tab.loading.table.loading.loadingDeparture.child">
                          Chd
                        </th>
                        <th class="text-center text-uppercase font-small"
                            i18n="sch|Flight card tab loading - table loading dep column infant@@sch.flightCard.tab.loading.table.loading.loadingDeparture.infant">
                          Inf
                        </th>
                        <th class="text-center text-uppercase font-small"
                            i18n="sch|Flight card tab loading - table loading dep column business@@sch.flightCard.tab.loading.table.loading.loadingDeparture.business">
                          Business
                        </th>
                        <th class="text-center text-uppercase font-small head-border-right-bold"
                            i18n="sch|Flight card tab loading - table loading dep column economy@@sch.flightCard.tab.loading.table.loading.loadingDeparture.economy">
                          Economy
                        </th>
                        <th class="text-center text-uppercase font-small"
                            i18n="sch|Flight card tab loading - table loading dep column all@@sch.flightCard.tab.loading.table.loading.loadingDeparture.all">
                          All, kg
                        </th>
                        <th class="text-center text-uppercase font-small"
                            i18n="sch|Flight card tab loading - table loading dep column paid@@sch.flightCard.tab.loading.table.loading.loadingDeparture.paid">
                          Paid, kg
                        </th>
                        <th class="text-center text-uppercase font-small head-border-right-bold"
                            i18n="sch|Flight card tab loading - table loading dep column units@@sch.flightCard.tab.loading.table.loading.loadingDeparture.units">
                          Amount
                        </th>
                        <th class="text-center text-uppercase font-small head-border-right-bold"
                            i18n="sch|Flight card tab loading - table loading dep column weight kg@@sch.flightCard.tab.loading.table.loading.loadingDeparture.weightKg">
                          Weight/kg
                        </th>
                        <th class="text-center text-uppercase font-small"
                            style="border-top-right-radius: 0;"
                            i18n="sch|Flight card tab loading - table loading dep column weight kg 2@@sch.flightCard.tab.loading.table.loading.loadingDeparture.weightKg2">
                          Weight/kg
                        </th>
                      </tr>
                    </thead>
                    <tbody *ngFor="let route of flight.route; let i = index">
                      <tr *ngIf="flight.isDeparture(route.airportId)">
                        <td class="text-center tbody-border-right-bold">
                          <div class="input-group-sm load__airport" *ngIf="route.airportId">
                            {{route.airportIata}}
                          </div>
                        </td>
                        <td class="text-center body-border__right--gray-normal">
                          <div class="input-group-sm load__poeple-count-l">
                            <div class=""><label class="col-form-label col-form-label-sm mr-2">&nbsp;<span
                                      i18n="sch|Flight card tab loading - table loading dep column supply@@sch.flightCard.tab.loading.table.loading.loadingDeparture.supply">SP</span></label>
                            </div>
                            <div><label class="col-form-label col-form-label-sm mr-2">&nbsp;<span
                                      i18n="sch|Flight card tab loading - table loading dep column transit@@sch.flightCard.tab.loading.table.loading.loadingDeparture.transit">TR</span></label>
                            </div>
                            <div><label class="col-form-label col-form-label-sm mr-2">&nbsp;<span
                                      i18n="sch|Flight card tab loading - table loading dep column transfer@@sch.flightCard.tab.loading.table.loading.loadingDeparture.transfer">TF</span></label>
                            </div>
                            <div><label class="col-form-label col-form-label-sm mr-2"
                                     i18n="sch|Flight card tab loading - table loading dep column selft@@sch.flightCard.tab.loading.table.loading.loadingDeparture.self">SLF</label>
                            </div>
                          </div>
                        </td>
                        <td class="text-center body-border__right--gray-normal">
                          <div class="input-group-sm load__poeple-count-l">
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                    name="DepartureAdult_0_{{i}}"
                                    class="form-control"
                                    placeholder=""
                                    [(ngModel)]="route.adult[0]">
                            </div>
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                    name="DepartureAdult_1_{{i}}"
                                    class="form-control"
                                    placeholder=""
                                    [(ngModel)]="route.adult[1]">
                            </div>
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                    name="DepartureAdult_2_{{i}}"
                                    class="form-control"
                                    placeholder=""
                                    [(ngModel)]="route.adult[2]">
                            </div>
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                    name="DepartureAdult_3_{{i}}"
                                    class="form-control"
                                    placeholder=""
                                    [(ngModel)]="route.adult[3]">
                            </div>
                          </div>
                        </td>
                        <td class="text-center body-border__right--gray-normal">
                          <div class="input-group-sm load__poeple-count-l">
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                    name="DepartureChild_0_{{i}}"
                                    class="form-control"
                                    placeholder=""
                                    [(ngModel)]="route.child[0]">
                            </div>
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                    name="DepartureChild_1_{{i}}"
                                    class="form-control"
                                    placeholder=""
                                    [(ngModel)]="route.child[1]">
                            </div>
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                    name="DepartureChild_2_{{i}}"
                                    class="form-control"
                                    placeholder=""
                                    [(ngModel)]="route.child[2]">
                            </div>
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                    name="DepartureChild_3_{{i}}"
                                    class="form-control"
                                    placeholder=""
                                    [(ngModel)]="route.child[3]">
                            </div>
                          </div>
                        </td>
                        <td class="text-center body-border__right--gray-normal">
                          <div class="input-group-sm load__poeple-count-l">
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                    name="DepartureInfant_0_{{i}}"
                                    class="form-control"
                                    placeholder=""
                                    [(ngModel)]="route.infant[0]">
                            </div>
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                    name="DepartureInfant_1_{{i}}"
                                    class="form-control"
                                    placeholder=""
                                    [(ngModel)]="route.infant[1]">
                            </div>
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                    name="DepartureInfant_1_{{i}}"
                                    class="form-control"
                                    placeholder=""
                                    [(ngModel)]="route.infant[2]">
                            </div>
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                    name="DepartureInfant_3_{{i}}"
                                    class="form-control"
                                    placeholder=""
                                    [(ngModel)]="route.infant[3]">
                            </div>
                          </div>
                        </td>
                        <td class="text-center body-border__right--gray-normal">
                          <div class="input-group-sm load__poeple-count-l">
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                    name="DepartureBusiness_0_{{i}}"
                                    class="form-control"
                                    placeholder=""
                                    [(ngModel)]="route.business[0]">
                            </div>
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                    name="DepartureBusiness_1_{{i}}"
                                    class="form-control"
                                    placeholder=""
                                    [(ngModel)]="route.business[1]">
                            </div>
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                    name="DepartureBusiness_2_{{i}}"
                                    class="form-control"
                                    placeholder=""
                                    [(ngModel)]="route.business[2]">
                            </div>
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                    name="DepartureBusiness_3_{{i}}"
                                    class="form-control"
                                    placeholder=""
                                    [(ngModel)]="route.business[3]">
                            </div>
                          </div>
                        </td>
                        <td class="text-center body-border__right--gray-normal">
                          <div class="input-group-sm load__poeple-count-l">
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                    name="DepartureEconomy_0_{{i}}"
                                    class="form-control"
                                    placeholder=""
                                    [(ngModel)]="route.economy[0]">
                            </div>
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                    name="DepartureEconomy_1_{{i}}"
                                    class="form-control"
                                    placeholder=""
                                    [(ngModel)]="route.economy[1]">
                            </div>
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                    name="DepartureEconomy_2_{{i}}"
                                    class="form-control"
                                    placeholder=""
                                    [(ngModel)]="route.economy[2]">
                            </div>
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                    name="DepartureEconomy_3_{{i}}"
                                    class="form-control"
                                    placeholder=""
                                    [(ngModel)]="route.economy[3]">
                            </div>
                          </div>
                        </td>
                        <td class="text-center body-border__right--gray-normal">
                          <div class="input-group-sm load__poeple-count-l">
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                name="DepartureBaggageWeight_0_{{i}}"
                                class="form-control"
                                placeholder=""
                                [(ngModel)]="route.baggageWeight[0]">
                            </div>
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                name="DepartureBaggageWeight_1_{{i}}"
                                class="form-control"
                                placeholder=""
                                [(ngModel)]="route.baggageWeight[1]">
                            </div>
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                name="DepartureBaggageWeight_2_{{i}}"
                                class="form-control"
                                placeholder=""
                                [(ngModel)]="route.baggageWeight[2]">
                            </div>
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                name="DepartureBaggageWeight_3_{{i}}"
                                class="form-control"
                                placeholder=""
                                [(ngModel)]="route.baggageWeight[3]">
                            </div>
                          </div>
                        </td>
                        <td class="text-center body-border__right--gray-normal">
                          <div class="input-group-sm load__poeple-count-l">
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                    name="DepartureBaggagePayed_0_{{i}}"
                                    class="form-control"
                                    placeholder=""
                                    [(ngModel)]="route.baggagePayed[0]">
                            </div>
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                    name="DepartureBaggagePayed_1_{{i}}"
                                    class="form-control"
                                    placeholder=""
                                    [(ngModel)]="route.baggagePayed[1]">
                            </div>
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                    name="DepartureBaggagePayed_2_{{i}}"
                                    class="form-control"
                                    placeholder=""
                                    [(ngModel)]="route.baggagePayed[2]">
                            </div>
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                    name="DepartureBaggagePayed_3_{{i}}"
                                    class="form-control"
                                    placeholder=""
                                    [(ngModel)]="route.baggagePayed[3]">
                            </div>
                          </div>
                        </td>
                        <td class="text-center body-border__right--gray-normal">
                          <div class="input-group-sm load__poeple-count-l">
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                    name="DepartureBaggageUnit_0_{{i}}"
                                    class="form-control"
                                    placeholder=""
                                    [(ngModel)]="route.baggageUnit[0]">
                            </div>
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                    name="DepartureBaggageUnit_1_{{i}}"
                                    class="form-control"
                                    placeholder=""
                                    [(ngModel)]="route.baggageUnit[1]">
                            </div>
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                    name="DepartureBaggageUnit_2_{{i}}"
                                    class="form-control"
                                    placeholder=""
                                    [(ngModel)]="route.baggageUnit[2]">
                            </div>
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                    name="DepartureBaggageUnit_3_{{i}}"
                                    class="form-control"
                                    placeholder=""
                                    [(ngModel)]="route.baggageUnit[3]">
                            </div>
                          </div>
                        </td>
                        <td class="text-center body-border__right--gray-normal">
                          <div class="input-group-sm load__poeple-count-l">
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                    name="DepartureCargo_0_{{i}}"
                                    class="form-control"
                                    placeholder=""
                                    [(ngModel)]="route.cargo[0]">
                            </div>
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                    name="DepartureCargo_1_{{i}}"
                                    class="form-control"
                                    placeholder=""
                                    [(ngModel)]="route.cargo[1]">
                            </div>
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                    name="DepartureCargo_2_{{i}}"
                                    class="form-control"
                                    placeholder=""
                                    [(ngModel)]="route.cargo[2]">
                            </div>
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                    name="DepartureCargo_3_{{i}}"
                                    class="form-control"
                                    placeholder=""
                                    [(ngModel)]="route.cargo[3]">
                            </div>
                          </div>
                        </td>
                        <td class="text-center body-border__right--gray-normal">
                          <div class="input-group-sm load__poeple-count-l">
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                    name="DepartureMail_0_{{i}}"
                                    class="form-control"
                                    placeholder=""
                                    [(ngModel)]="route.mail[0]">
                            </div>
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                    name="DepartureMail_1_{{i}}"
                                    class="form-control"
                                    placeholder=""
                                    [(ngModel)]="route.mail[1]">
                            </div>
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                    name="DepartureMail_2_{{i}}"
                                    class="form-control"
                                    placeholder=""
                                    [(ngModel)]="route.mail[2]">
                            </div>
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                    name="DepartureMail_3_{{i}}"
                                    class="form-control"
                                    placeholder=""
                                    [(ngModel)]="route.mail[3]">
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
            </div>
          </div>
        </div>

        <!-- Кнопки карточки рейса -->
        <div class="modal-footer">
          <div>
            <button type="button"
                    class="btn btn-sm btn-blue"
                    *ngIf="usesAction === 'addFlight'"
                    (click)="addFlight()">
              <span i18n="sch|Flight card - button append@@sch.flightCard.button.append">Append</span>
            </button>
            <button type="button"
                    class="btn btn-sm btn-blue"
                    *ngIf="usesAction === 'editFlight'"
                    (click)="saveFlight()">
              <span i18n="sch|Flight card - button save@@sch.flightCard.button.save">Save</span>
            </button>
          </div>
          <button type="button"
                  class="btn btn-sm btn-gray-blue-dark"
                  data-dismiss="modal">
            <span i18n="sch|Flight card - button close@@sch.flightCard.button.close">Close</span>
          </button>
        </div>
        <!-- Сообщение об ошибке -->
        <!--<div *ngIf="error.errorMessage !== ''" class="ms-modal-message">
          <div class="modal-content w-50">
            <div class="modal-header" [ngClass]="{'bg-info': error.errorType == 'info',
                                                'bg-warning': error.errorType == 'warning',
                                                'bg-danger': error.errorType == 'error'}">
              <h5 *ngIf="error.errorType == 'info'"
                  class="modal-title"
                  i18n="sch|Modal error - header information@@sch.modal.errMes.header.inf">
                Information
              </h5>
              <h5 *ngIf="error.errorType == 'warning'"
                  class="modal-title"
                  i18n="sch|Modal error - header warning@@sch.modal.errMes.header.warn">
                Warning
              </h5>
              <h5 *ngIf="error.errorType == 'error'"
                  class="modal-title"
                  i18n="sch|Modal error - header error@@sch.modal.errMes.header.err">
                Error
              </h5>
              <button type="button"
                      class="close"
                      (click)="clearErrorMess()"
                      aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body" style="white-space: pre-line">
              {{error.errorMessage}}
            </div>
            <div class="modal-footer">
              <button type="button"
                      class="btn btn-sm btn-secondary"
                      (click)="clearErrorMess()"
                      i18n="sch|Modal error - button no@@sch.modal.errMes.button.no">
                Edit
              </button>
            </div>
          </div>
        </div>-->
      </form>
    </div>
  </div>
</div>

<div *ngIf="error.errorMessage" class="ms-modal-message">
  <div class="modal-content w-50">
    <div class="modal-header" [ngClass]="{'bg-info': error.errorType == 'info',
                                    'bg-warning': error.errorType == 'warning',
                                    'bg-danger': error.errorType == 'error'}">
      <h5 *ngIf="error.errorType == 'info'" class="modal-title"
        i18n="cmn|Modal message error - header information@@cmd.main.modalMessage.errMes.header.inf">
        Information
      </h5>
      <h5 *ngIf="error.errorType == 'warning'" class="modal-title"
        i18n="cmn|Modal message error - header watning@@cmd.main.modalMessage.errMes.header.warn">
        Warning
      </h5>
      <h5 *ngIf="error.errorType == 'error'" class="modal-title"
        i18n="cmn|Modal message error - header error@@cmd.main.modalMessage.errMes.header.err">
        Error
      </h5>
      <button type="button" class="close" (click)="clearErrorMess()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="white-space: pre-line">
      {{error.errorMessage}}
    </div>
    <div class="modal-footer">
      <button type="button" *ngIf="modalType==='setAnswer'" class="btn btn-sm btn-blue" (click)="userAnswer = true"
        i18n="cmn|Modal message error - button yes@@cmd.main.modalMessage.errMes.button.yes">
        Yes
      </button>
      <button type="button" *ngIf="modalType==='setAnswer'" class="btn btn-sm btn-gray-blue-dark"
        (click)="userAnswer = false" i18n="cmn|Modal message error - button no@@cmd.main.modalMessage.errMes.button.no">
        No
      </button>
      <button type="button" *ngIf="modalType!=='setAnswer'" class="btn btn-sm btn-gray-blue-dark"
        (click)="error.errorMessage = null"
        i18n="cmn|Modal create message error - button close@@cmn.modalCreateMessage.buttons.close">
        Close
      </button>
    </div>
  </div>
</div>

export function drawGravity(width, height, data, chartData, ahmData, canvas) {
  // Фон
  canvas.clearRect(0, 0, width, height);

  canvas.fillStyle = '#f1f1f1';
  canvas.fillRect(0, 0, width, height);

  let printDisabled = false;

  let minWeight = 999999999;
  let maxWeight = 0;
  // let stepWeight = 5000;

  let minIndex = 100;
  let maxIndex = 0;

  for (const key in data.aft) {
    data.aft[key].forEach(element => {
      if (element.index < minIndex) minIndex = element.index;
      if (element.weight < minWeight) minWeight = element.weight;

      if (element.index > maxIndex) maxIndex = element.index;
      if (element.weight > maxWeight) maxWeight = element.weight;
    });
    data.fwd[key].forEach(element => {
      if (element.index < minIndex) minIndex = element.index;
      if (element.weight < minWeight) minWeight = element.weight;

      if (element.index > maxIndex) maxIndex = element.index;
      if (element.weight > maxWeight) maxWeight = element.weight
    });
  }

  let stepIndex;
  let rangeIndex = maxIndex - minIndex;
  if (rangeIndex < 70) {
    stepIndex = 5;
  } else if (rangeIndex < 200) {
    stepIndex = 10;
  } else if (rangeIndex < 400) {
    stepIndex = 20;
  } else {
    stepIndex = 50;
  }

  // Округляем максимальный и минимальный индексы
  // minIndex = Math.floor(minIndex / stepIndex) * stepIndex - stepIndex;
  // maxIndex = Math.ceil(maxIndex / stepIndex) * stepIndex + stepIndex;

  let maxI = Math.ceil(maxIndex / stepIndex) * stepIndex;

  if (maxIndex >= maxI - stepIndex / 3) {
    maxIndex = maxI + stepIndex;
  } else {
    maxIndex = maxI;
  }

  let minI = Math.floor(minIndex / stepIndex) * stepIndex;

  if (minIndex >= minI - stepIndex / 3) {
    minIndex = minI - stepIndex;
  } else {
    minIndex = minI;
  }


  let stepWeight;
  let rangeWeight = maxWeight - minWeight;
  if (rangeWeight < 50000) {
    stepWeight = 5000;
  } else if (rangeWeight < 100000) {
    stepWeight = 10000;
  } else if (rangeWeight < 200000) {
    stepWeight = 20000;
  } else {
    stepWeight = 50000;
  }

  // Округляем максимальный и минимальный веса

  //minWeight = Math.floor(minWeight / stepWeight) * stepWeight - stepWeight;

  let maxW = Math.ceil(maxWeight / stepWeight) * stepWeight;

  if (maxWeight >= maxW - stepWeight / 2) {
    maxWeight = maxW + stepWeight;
  } else {
    maxWeight = maxW;
  }

  let minW = Math.floor(minWeight / stepWeight) * stepWeight;

  if (minWeight >= minW - stepWeight / 3) {
    minWeight = minW - stepWeight;
  } else {
    minWeight = minW;
  }

  let minMac = getMac(ahmData.c, ahmData.k, ahmData.referenceArm, ahmData.lemacLerc, ahmData.macRc, minIndex, minWeight) - 10;
  let maxMac = getMac(ahmData.c, ahmData.k, ahmData.referenceArm, ahmData.lemacLerc, ahmData.macRc, maxIndex, maxWeight) + 10;

  let stepMac;
  let rangeMac = maxMac - minMac;
  if (rangeMac < 40) {
    stepMac = 1;
  } else if (rangeMac < 80) {
    stepMac = 2;
  } else if (rangeMac < 160) {
    stepMac = 4;
  } else if (rangeMac < 500) {
    stepMac = 10;
  } else {
    stepMac = 20;
  }


  let stepX = +((width - 50) / (maxIndex - minIndex)).toFixed(2);
  let stepY = +(height / ((maxWeight - minWeight) / stepWeight)).toFixed(2);

  let mac = minMac;

  canvas.fillStyle = '#fff';
  canvas.strokeStyle = '#000';
  canvas.font = "12px Arial";
  canvas.lineWidth = 1;

  // Всякие там полосочки для красоты
  canvas.beginPath();

  canvas.fillRect(0, 0, width, 30);

  canvas.moveTo(50, 30);
  canvas.lineTo(50, height - 30);
  canvas.stroke();

  canvas.moveTo(50, 30);
  canvas.lineTo(width, 30);
  canvas.stroke();

  canvas.moveTo(width, 30);
  canvas.lineTo(width, height - 30);
  canvas.stroke();

  canvas.moveTo(50, height - 30);
  canvas.lineTo(width, height - 30);
  canvas.stroke();

  canvas.closePath();

  let y = height - stepY;
  let weight = maxWeight - stepWeight;

  canvas.fillStyle = '#000';
  canvas.strokeStyle = '#bbb';

  // Рисуем направляющие MAC
  while (mac <= maxMac) {
    let minIn = stepX * (getIndex(ahmData.c, ahmData.k, ahmData.referenceArm, ahmData.lemacLerc, ahmData.macRc, mac, minWeight) - minIndex) + 50;
    let maxIn = stepX * (getIndex(ahmData.c, ahmData.k, ahmData.referenceArm, ahmData.lemacLerc, ahmData.macRc, mac, maxWeight) - minIndex) + 50;
    canvas.beginPath();
    canvas.moveTo(minIn, height);
    canvas.lineTo(maxIn, 30);
    canvas.stroke();

    canvas.moveTo(maxIn, 20);
    canvas.lineTo(maxIn, 30);
    canvas.stroke();
    if (maxIn > 50) canvas.fillText((mac).toString(), maxIn - 10, 10);

    mac += stepMac;
    canvas.closePath();
  }

  canvas.fillStyle = '#fff';
  canvas.strokeStyle = '#000';
  canvas.font = "12px Arial";
  canvas.lineWidth = 1;

  // Всякие там полосочки для красоты
  canvas.beginPath();

  canvas.fillRect(0, height - 30, width, 30);
  canvas.fillRect(0, 0, 50, height);

  canvas.fillStyle = '#000';
  canvas.strokeStyle = '#bbb';

  // Напрявляющие веса
  while (weight > minWeight) {

    canvas.beginPath();
    canvas.moveTo(50, y);
    canvas.lineTo(width, y);
    canvas.stroke();
    canvas.fillText((maxWeight + minWeight - weight).toString(), 0, y);

    weight -= stepWeight;
    y -= stepY;
    canvas.closePath();
  }

  // Пордписываем ось с индексами (сверху и снизу)
  let index = minIndex;
  let position = 0;
  let end = maxIndex - minIndex;
  while (position < end) {
    canvas.beginPath();
    let x = position * stepX + 50;

    canvas.moveTo(x, height - 20);
    canvas.lineTo(x, height - 30);
    canvas.stroke();
    canvas.fillText((index).toString(), x - stepIndex, height);

    index += stepIndex;
    position += stepIndex;
    canvas.closePath();
  }

  // Рисуем колодец
  for (const key in data.aft) {
    if (Object.prototype.hasOwnProperty.call(data.aft, key)) {
      canvas.beginPath();
      canvas.lineWidth = 3;
      let arr = [...data.aft[key]];

      switch (key) {
        case 'zeroFuel':
          canvas.strokeStyle = '#c51b1b';
          break;
        case 'takeOff':
          canvas.strokeStyle = '#1f688e';
          break;
        case 'landing':
          canvas.strokeStyle = '#5b9b5d';
          break;
      }

      let start = [0, 0];

      for (let i = 0; i < arr.length; i++) {
        let x = +(stepX * (arr[i].index - minIndex) + 50).toFixed(2);
        let y = height - Math.floor(height / (maxWeight - minWeight) * (arr[i].weight - minWeight));
        if (i === 0) {
          canvas.moveTo(x, y);
          start = [x, y]
        } else {
          canvas.lineTo(x, y);
        }
      }
      arr = [...data.fwd[key]];
      for (let i = arr.length - 1; i >= 0; i--) {
        let x = +(stepX * (arr[i].index - minIndex) + 50).toFixed(2);
        let y = height - Math.floor(height / (maxWeight - minWeight) * (arr[i].weight - minWeight));
        canvas.lineTo(x, y);
      }
      canvas.lineTo(start[0], start[1]);
      canvas.stroke();
      canvas.closePath();
    }
  }

  // Рисуем график
  canvas.strokeStyle = '#2b99d2';
  canvas.lineWidth = 1;
  canvas.beginPath();
  let controlPoints = [];
  for (let i = 0; i < chartData.length; i++) {
    let x = +(stepX * (chartData[i].index - minIndex) + 50).toFixed(2);
    let y = height - Math.floor(height / (maxWeight - minWeight) * (chartData[i].weight - minWeight));

    if (i === 0) {
      canvas.moveTo(x, y);
    } else {
      canvas.lineTo(x, y);
    }

    canvas.stroke();

    if (chartData[i].type === "takeoff" || chartData[i].type === "landing" || chartData[i].type === "zerofuel") {
      controlPoints.push(chartData[i]);
    }
  }

  canvas.closePath();

  // Контрольные точки и метки на колодце
  printDisabled = false;
  controlPoints.forEach(point => {
    let x = +(stepX * (point.index - minIndex) + 50).toFixed(2);
    let y = height - Math.floor(height / (maxWeight - minWeight) * (point.weight - minWeight));

    switch (point.type) {
      case 'takeoff':
        canvas.fillStyle = '#1f688e';
        break;
      case 'landing':
        canvas.fillStyle = '#5b9b5d';
        break;
      case 'zerofuel':
        canvas.fillStyle = '#c51b1b';
        break;
    }

    canvas.beginPath();
    canvas.arc(x, y, 4, 0, 2 * Math.PI);
    canvas.fill();
    canvas.closePath();

    canvas.strokeStyle = '#000';
    canvas.fillStyle = '#000';
    canvas.fillText((point.index.toFixed(2)).toString(), x + 3, y - 5);

    canvas.beginPath();

    x = +(stepX * (point.fwd - minIndex) + 50).toFixed(2);
    canvas.moveTo(x - 5, y);
    canvas.lineTo(x + 5, y);
    canvas.stroke();
    canvas.fillText((point.fwd.toFixed(2)).toString(), x - 45, y + 5);

    x = +(stepX * (point.aft - minIndex) + 50).toFixed(2);
    canvas.moveTo(x - 5, y);
    canvas.lineTo(x + 5, y);
    canvas.stroke();
    canvas.fillText((point.aft.toFixed(2)).toString(), x + 15, y + 5);

    canvas.closePath();

    // Проверка, что точки не вышли за границы колодца
    if (point.index < point.fwd || point.index > point.aft) {
      printDisabled = true;
    }

  });

  return printDisabled;

}

export function createCanvas(width, height, to) {
  var canvas = document.createElement('canvas');
  canvas.width  = width;
  canvas.height = height;
  to.innerHTML = '';
  to.appendChild(canvas);
  return (canvas as HTMLCanvasElement).getContext('2d');
}

function getIndex(C, K, refSta, LEMAC, macRc, mac, weight) {
  return Number(((weight / C) * (mac * macRc / 100 + LEMAC - refSta) + K).toFixed(2));
}

function getMac(C, K, refSta, LEMAC, macRc, index, weight) {
  return Number((((C * (index - K)) / weight + refSta - LEMAC) / (macRc / 100)).toFixed())
}

import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';
import { GlobalSettings } from '../settings/global-settings';
import { AhmData, Calculation, Flight } from './weight-balance';

@Injectable({
  providedIn: 'root'
})

export class WeightBalanceModuleRestApiService {

  constructor(private http: HttpClient, private globalSettings: GlobalSettings) {
    globalSettings.loadDefaultConfig();
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  // Ahms
  getAhms(): Promise<AhmData[]> {
    return this.http.get<AhmData[]>(this.globalSettings.apiWeightBalanceURL +
                                              '/ahm_data')
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  getAhm(id): Promise<AhmData> {
    return this.http.get<AhmData>(this.globalSettings.apiWeightBalanceURL +
                                              '/ahm_data/' + id)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  // Flights
  getFlights(filterParams?): Promise<Flight[]> {
    let url = '/flights';
    if (filterParams) {
      var params = [];
      for (const key in filterParams) {
        if ((key == 'start' || key == 'finish') && filterParams[key]) {
          params.push(key + '=' + filterParams[key].toISOString());
        } else if (filterParams[key]) {
          params.push(key + '=' + filterParams[key]);
        }
      }
      if (params.length > 0) {
        url += '?' + params.join('&');
      }
    }
    return this.http.get<Flight[]>(this.globalSettings.apiWeightBalanceURL + url)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  getFlight(id): Promise<Flight> {
    return this.http.get<Flight>(this.globalSettings.apiWeightBalanceURL +
                                              '/flights/' + id)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  addFlight(flight: any): Observable<HttpResponse<any>> {
    this.httpOptions['observe'] = 'response';
    return this.http.post<HttpResponse<any>>(this.globalSettings.apiWeightBalanceURL +
                                            '/flights',
                                            JSON.stringify(flight),
                                            this.httpOptions)
    .pipe(
      map(resp => {
        console.log('rest from API:', resp);
        return resp;
      }),
      retry(1),
      catchError(this.handleError)
    );
  }

  updateFlight(flight: Flight): Observable<Flight> {
   return this.http.put<Flight>(this.globalSettings.apiWeightBalanceURL +
                                          '/flights/' +
                                          flight.id,
                                          JSON.stringify(flight),
                                          this.httpOptions)
   .pipe(
     retry(1),
     catchError(this.handleError)
   );
  }

  // calculate
  calculate(calculate): Observable<Calculation> {
    return this.http.post<Calculation>(this.globalSettings.apiWeightBalanceURL +
                                              '/calculate',
                                              JSON.stringify(calculate))
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // chart data
  getChart(calculate): Promise<any> {
    return this.http.post<any>(this.globalSettings.apiWeightBalanceURL +
                                              '/chart',
                                              JSON.stringify(calculate))
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  // get delta ballast
  getDeltaBallast(calculate): Promise<any> {
    return this.http.post<any>(this.globalSettings.apiWeightBalanceURL +
                                              '/ballast_delta',
                                              JSON.stringify(calculate))
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  // calculations
  getCalculation(flightId): Promise<Calculation> {
    return this.http.get<Calculation>(this.globalSettings.apiWeightBalanceURL +
                                              '/calculations/' + flightId)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  addCalculate(calculate: any): Observable<HttpResponse<any>> {
    this.httpOptions['observe'] = 'response';
    return this.http.post<HttpResponse<any>>(this.globalSettings.apiWeightBalanceURL +
                                              '/calculations',
                                              JSON.stringify(calculate),
                                              this.httpOptions)
      .pipe(
        map(resp => {
          console.log('rest from API:', resp);
          return resp;
        }),
        retry(1),
        catchError(this.handleError)
      );
  }

  updateCalculate(id, calculate): Promise<Calculation> {
    console.log('update calculate', id, calculate);
    return this.http.put<Calculation>(this.globalSettings.apiWeightBalanceURL +
                                              '/calculations/' + id,
                                              JSON.stringify(calculate))
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  createDocuments(flightId) {
    return this.http.post(this.globalSettings.apiWeightBalanceURL +
                          '/documents/' + flightId + '/create',
                          JSON.stringify(flightId))
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  loadDocument(flightId, edition, url): Promise<any> {
    // return Observable.of('TODO: change to real data');
    return this.http.get<any>(this.globalSettings.apiWeightBalanceURL +
                          '/documents/' + flightId + '/editions/' + edition + url)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  getDocumentEditions(flightId): Promise<[]> {
    // return new Promise(resolve => {
    //     let data: Array<number> = [1,2,3];
    //     resolve(data);
    // });
    return this.http.get<[]>(this.globalSettings.apiWeightBalanceURL +
                          '/documents/' + flightId + '/editions')
    .pipe(
      retry(1),
      catchError(this.handleError)
    ).toPromise();
  }

  getLoadsheet(url, id): Observable<any> {
    return this.http.get(this.globalSettings.apiWeightBalanceURL + url + id)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  saveDocument(flightId, edition, url) {
    let params = new HttpParams();
    params = params.append('export', 'pdf');
    return this.http.get(this.globalSettings.apiWeightBalanceURL +
      '/documents/' + flightId + '/editions/' + edition + url, { params, responseType: 'blob' })
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // ahmData
  getAhmData(tailId): Promise<AhmData> {
    return this.http.get<AhmData>(this.globalSettings.apiWeightBalanceURL +
                                              '/ahm_data_by_tail/' + tailId)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  // reference
  getReference(name): Promise<any[]> {
    return this.http.get<any[]>(this.globalSettings.apiWeightBalanceURL +
                                              '/master_data/' + name)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  // Error handling
  handleError(error) {
    let errorMessage = '';
    let errorDetail: any = null;
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorDetail = error.error;
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    if (errorDetail) {
      return throwError(errorDetail);
    } else {
      return throwError(errorMessage);
    }
  }
}

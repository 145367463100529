<div *ngIf="activeWindow.includes('ahm-card')" class="mb-3 p-3 aero-top-panel shadow">
  <button class="btn btn-sm btn-ico btn-light-gray"
          (click)="loadAhms()">
    <i class="material-icons">arrow_back_ios</i>
    <span i18n="adm|Weight and balance - button ahm list@@adm.wb.main.button.ahmList">AHM list</span>
  </button>
</div>
<div class="weight-balance-content" *ngIf="activeWindow == 'ahm-list'">
  <div class="row">
    <div class="col-8 pr-0">
      <div class="card">
        <div class="card-body p-2">
          <div class="d-flex justify-content-between align-items-center mb-2">
            <div>
              <p class="font-weight-bold text-blue h5 mb-0">AHM</p>
            </div>
            <div>
              <button class="btn btn-sm btn-ico btn-red mr-2" [disabled]="ahm.id === undefined" (click)="deleteAhm()">
                <i class="material-icons">delete</i>
                <span i18n="adm|Weight and balance - button delete@@adm.wb.main.button.delete">Delete</span>
              </button>
              <button class="btn btn-sm btn-ico btn-light-gray mr-2" [disabled]="ahm.id === undefined" (click)="copyAhm(modalAhm)">
                <i class="material-icons">file_copy</i>
                <span i18n="adm|Weight and balance - button copy@@adm.wb.main.button.copy">Copy</span>
              </button>
              <button class="btn btn-sm btn-ico btn-light-gray mr-2" [disabled]="ahm.id === undefined" (click)="editAhm(modalAhm)">
                <i class="material-icons">edit</i>
                <span i18n="adm|Weight and balance - button edit@@adm.wb.main.button.edit">Edit</span>
              </button>
              <button class="btn btn-sm btn-ico btn-blue" (click)="createAhm(modalAhm)">
                <i class="material-icons">add</i>
                <span i18n="adm|Weight and balance - button add@@adm.wb.main.button.add">Add</span>
              </button>
            </div>
          </div>
          <div class="input-group-prepend mb-3">
            <div class="custom-control custom-switch d-flex" style="min-width: fit-content;">
              <input type="checkbox"
                    class="custom-control-input"
                    id="showOutdateRevisions"
                    name="showOutdateRevisions"
                    [checked]="showRemovedAHM"
                    (click)="showArchiveAhm()">
              <label class="custom-control-label" for="showOutdateRevisions"
                  i18n="adm|Weight and balance@@adm.wb.main.checkbox.showOutdateRevisions">
                Show outdate revisions
              </label>
            </div>
          </div>
          <table class="contenteditable w-100">
            <thead class="bg-blue color-white">
              <tr>
                <th i18n="adm|Weight and balance - table airline@@adm.wb.main.table.airline">Airline</th>
                <th i18n="adm|Weight and balance - table aircraft@@adm.wb.main.table.aircraft">Aircraft</th>
                <th i18n="adm|Weight and balance - table revision@@adm.wb.main.table.revision">Revision</th>
                <th i18n="adm|Weight and balance - table release date@@adm.wb.main.table.releaseDate">Release date</th>
                <th i18n="adm|Weight and balance - table completed by@@adm.wb.main.table.completedBy">Completed by</th>
                <th i18n="adm|Weight and balance - table reason changes overview@@adm.wb.main.table.reasonChangesOverview">Reason/Changes overview</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of ahms"
                  (click)="selectAhm(item.id)" (dblclick)="editAhm(modalAhm)"
                  [ngClass]="{'bg-gray': item.id == ahm.id}" class="cursor-default">
                <td>{{ item.airlineName }}</td>
                <td>{{ item.aircraftTypeName }}</td>
                <td>{{ item.revision }}</td>
                <td>{{ item.dtRangeStart | date: 'dd.MM.yyyy' }}</td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-4" *ngIf="ahm.id !== undefined">
      <div class="card">
        <div class="card-body p-2">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <p class="font-weight-bold text-blue h6 mb-0"
               i18n="adm|Weight and balance@@adm.wb.main.groupTail">Tail</p>
            <div>
              <button class="btn btn-xs btn-ico btn-red mr-2" [disabled]="!selectedAhmDataId" (click)="deleteAhmData(selectedAhmDataId)">
                <i class="material-icons">delete</i>
              </button>
              <button class="btn btn-xs btn-ico btn-light-gray mr-2" [disabled]="!selectedAhmDataId" (click)="copyAhmData(selectedAhmDataId)">
                <i class="material-icons">file_copy</i>
              </button>
              <button class="btn btn-xs btn-ico btn-light-gray mr-2" [disabled]="!selectedAhmDataId" (click)="editAhmData(selectedAhmDataId)">
                <i class="material-icons">edit</i>
              </button>
              <button class="btn btn-xs btn-ico btn-blue" (click)="createAhmData()">
                <i class="material-icons">add</i>
              </button>
            </div>
          </div>
          <table class="contenteditable w-100" *ngIf="ahmsData.length > 0">
            <thead class="bg-blue color-white">
              <tr>
                <th i18n="adm|Weight and balance - table ac registration@@adm.wb.main.table.acRegistration">A/C registration</th>
                <th i18n="adm|Weight and balance - table last update@@adm.wb.main.table.lastUpdate">Last update</th>
              </tr>
            </thead>
            <tbody>
              <tr (click)="selectedAhmDataId = ahm.id"
                  *ngFor="let ahm of ahmsData"
                  class="cursor-default"
                  [ngClass]="{'bg-gray': selectedAhmDataId == ahm.id}"
                  (dblclick)="editAhmData(selectedAhmDataId)">
                <td>{{ ahm.tailName }}</td>
                <td>{{ ahm.lastupdate | date: 'dd.MM.yyyy HH:mm' }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="card weight-balance-content" *ngIf="activeWindow == 'ahm-card-data'">
  <div class="card-body">
    <div class="d-flex justify-content-between mb-3">
      <div>
        <p class="font-weight-bold text-blue h5 mb-3" i18n="adm|Weight and balance - ahmData input data AHM@@adm.wb.main.ahmData.inputDataAHM">Input data from AHM</p>
        <p>
          <span i18n="adm|Weight and balance - ahmData airline@@adm.wb.main.ahmData.release">Release:</span>
          <span class="font-weight-bold mr-2"> {{ ahm.dtRangeStart | date: 'dd.MM.yyyy' }}</span>
          <span i18n="adm|Weight and balance - ahmData airline@@adm.wb.main.ahmData.revision">Revision:</span>
          <span class="font-weight-bold mr-2"> {{ ahm.revision }}</span>
          <span i18n="adm|Weight and balance - ahmData airline@@adm.wb.main.ahmData.airline">Airline:</span>
          <span class="font-weight-bold mr-2"> {{ ahmData.airlineIata }}</span>
          <span i18n="adm|Weight and balance - ahmData airline@@adm.wb.main.ahmData.aircraft">Aircraft:</span>
          <span class="font-weight-bold mr-2"> {{ ahm.aircraftTypeName }}</span>
          <span i18n="adm|Weight and balance - ahmData acRegistration@@adm.wb.main.ahmData.acRegistration">A/C registration:</span>
          <span class="font-weight-bold"> {{ ahmData.tailName }}</span>
        </p>
      </div>
        <div class="float-right">
          <button class="btn btn-sm btn-blue"
                  *ngIf="userAction === 'add-ahm-data'"
                  (click)="addAhmData()"
                  i18n="adm|Weight and balance - button add ahm@@adm.wb.main.button.addAhm">Add AHM</button>
          <button class="btn btn-sm btn-light-gray mr-3"
                  type="button"
                  *ngIf="userAction === 'edit-ahm-data'"
                  (click)="exportAhmData()"
                  i18n="adm|Weight and balance - button export@@adm.wb.main.button.exportAhm">Export to Excel</button>
          <button class="btn btn-sm btn-blue"
                  *ngIf="userAction === 'edit-ahm-data'"
                  (click)="saveAhmData()"
                  i18n="adm|Weight and balance - button save ahm@@adm.wb.main.button.saveAhm">Save AHM</button>
        </div>
    </div>
    <ul class="nav nav-tabs" id="myTab1" role="tablist">
      <li class="nav-item">
        <a class="nav-link active" id="standard-units-tab" data-toggle="tab"
          i18n="adm|Weight and balance - tab standard units@@adm.wb.main.tab.standardUnits"
          href="#standard-units" role="tab">Standard units and codes</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="flight-information-tab" data-toggle="tab"
          i18n="adm|Weight and balance - tab flight information@@adm.wb.main.tab.flightInformation"
          href="#flight-information" role="tab">Flight information</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="centre-charts-tab" data-toggle="tab"
          i18n="adm|Weight and balance - tab centre charts@@adm.wb.main.tab.centreCharts"
          href="#centre-charts" role="tab">Centre of gravity charts</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="fuel-tab" data-toggle="tab"
          i18n="adm|Weight and balance - tab fuel@@adm.wb.main.tab.fuel"
          href="#fuel" role="tab">Fuel</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="holds-compartments-tab" data-toggle="tab"
          i18n="adm|Weight and balance - tab holds and compartments@@adm.wb.main.tab.holdsAndCompartments"
          href="#holds-compartments" role="tab">Holds and compartments</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="cabin-equipment-tab" data-toggle="tab"
          i18n="adm|Weight and balance - tab holds and compartments@@adm.wb.main.tab.cabinAndEquipment"
          href="#cabin-equipment" role="tab">Cabin and equipment</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="crew-composition-tab" data-toggle="tab"
          i18n="adm|Weight and balance - tab crew composition@@adm.wb.main.tab.crewComposition"
          href="#crew-composition" role="tab">Crew composition</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="stabilizer-trim-tab" data-toggle="tab"
          i18n="adm|Weight and balance - tab stabilizer trim@@adm.wb.main.tab.stabilizer"
          href="#stabilizer-trim" role="tab">Stabilizer Trim</a>
      </li>
    </ul>

    <div class="tab-content pt-3" id="myTabContent1">
      <div class="tab-pane fade show active" id="standard-units" role="tabpanel" aria-labelledby="standard-units-tab">
        <div class="row">
          <div class="col-6">
            <div class="card mb-4">
              <div class="card-body">
                <p class="font-weight-bold text-blue h5 mb-3"
                   i18n="adm|Weight and balance@@adm.wb.tab.standartInits.groupUnits">Units of measure</p>
                <div class="row">
                  <div class="col-6">
                    <div class="input-group flex-nowrap mb-3">
                      <div class="input-group-prepend custom-sm">
                        <span class="input-group-text font-small py-0"
                              i18n="adm|Weight and balance - standard units weight@@adm.wb.tab.standartInits.groupUnits.weight">
                          Weight
                        </span>
                      </div>
                      <ng-select [items]="units.weight" class="w-100 custom-sm" bindLabel="englishName"
                        bindValue="englishName" name="unitsWeight" id="unitsWeight" [(ngModel)]="ahmData.units.weight">
                      </ng-select>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="input-group flex-nowrap mb-3">
                      <div class="input-group-prepend custom-sm">
                        <span class="input-group-text font-small py-0"
                              i18n="adm|Weight and balance - standard units weight@@adm.wb.tab.tab.standartInits.groupUnits.length">
                          Length
                        </span>
                      </div>
                      <ng-select [items]="units.length" class="w-100 custom-sm" bindLabel="englishName"
                        bindValue="englishName" name="unitsLength" id="unitsLength" [(ngModel)]="ahmData.units.length">
                      </ng-select>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="input-group flex-nowrap">
                      <div class="input-group-prepend custom-sm">
                        <span class="input-group-text font-small py-0"
                              i18n="adm|Weight and balance - standard units weight@@adm.wb.tab.standartInits.groupUnits.volume">
                          Volume
                        </span>
                      </div>
                      <ng-select [items]="units.volume" class="w-100 custom-sm" bindLabel="englishName"
                        bindValue="englishName" name="unitsVolume" id="unitsVolume" [(ngModel)]="ahmData.units.volume">
                      </ng-select>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="input-group flex-nowrap">
                      <div class="input-group-prepend custom-sm">
                        <span class="input-group-text font-small py-0"
                              i18n="adm|Weight and balance - standard units liquid volume@@adm.wb.tab.standartInits.groupUnits.liquidVolume">
                          Liquid volume
                        </span>
                      </div>
                      <ng-select [items]="units.liquidVolume" class="w-100 custom-sm" bindLabel="englishName"
                        bindValue="englishName" name="unitsLiquidVolume" id="unitsLiquidVolume"
                        [(ngModel)]="ahmData.units.liquidVolume">
                      </ng-select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <p class="font-weight-bold text-blue h5 mb-3" i18n="adm|Weight and balance@@adm.wb.tab.standartInits.groupCrew">Crew weight</p>
                <div class="row mb-3">
                  <div class="col-6">
                    <p i18n="adm|Weight and balance@@adm.wb.tab.standartInits.groupCrew.cockpit">Cockpit</p>
                    <div class="input-group flex-nowrap mb-3">
                      <div class="input-group-prepend custom-sm">
                        <span class="input-group-text font-small py-0"
                          i18n="adm|Weight and balance - standard units crew weight@@adm.wb.tab.standartInits.groupCrew.crewWeight">
                          Crew weight
                        </span>
                      </div>
                      <input type="number" class="w-100 form-control custom-sm" name="cockpitCrewWeight"
                        id="cockpitCrewWeight" [(ngModel)]="ahmData.standartWeights.crew.cockpit.weight" />
                    </div>
                    <div class="input-group flex-nowrap">
                      <div class="input-group-prepend custom-sm">
                        <span class="input-group-text font-small py-0"
                          i18n="adm|Weight and balance - standard units hand baggage@@adm.wb.tab.standartInits.groupCrew.handBaggage">
                          Hand baggage
                        </span>
                      </div>
                      <input type="number" class="w-100 form-control custom-sm" name="cockpitHandBaggage"
                        id="cockpitHandBaggage" [(ngModel)]="ahmData.standartWeights.crew.cockpit.handLuggage" />
                    </div>
                  </div>
                  <div class="col-6">
                    <p i18n="adm|Weight and balance@@adm.wb.tab.standartInits.groupCrew.cabin">Cabin</p>
                    <div class="input-group flex-nowrap mb-3">
                      <div class="input-group-prepend custom-sm">
                        <span class="input-group-text font-small py-0"
                          i18n="adm|Weight and balance - standard units crew weight@@adm.wb.tab.standartInits.groupCrew.crewWeight">
                          Crew weight
                        </span>
                      </div>
                      <input type="number" class="w-100 form-control custom-sm" name="cabinCrewWeight"
                        id="cabinCrewWeight" [(ngModel)]="ahmData.standartWeights.crew.cabin.weight" />
                    </div>
                    <div class="input-group flex-nowrap">
                      <div class="input-group-prepend custom-sm">
                        <span class="input-group-text font-small py-0"
                          i18n="adm|Weight and balance - standard units hand baggage@@adm.wb.tab.standartInits.groupCrew.handBaggage">
                          Hand baggage
                        </span>
                      </div>
                      <input type="number"
                             class="w-100 form-control custom-sm"
                             name="cabinHandBaggage"
                             id="cabinHandBaggage"
                             [(ngModel)]="ahmData.standartWeights.crew.cabin.handLuggage" />
                    </div>
                  </div>
                </div>
                <p class="m-0"
                   i18n="adm|Weight and balance - standard units hand baggage included@@adm.wb.tab.standartInits.groupCrew.handBaggageIncluded">
                  Hand baggage weight is included in the crew weights
                </p>
                <label class="mr-3">
                  <input type="radio"
                         name="handLuggageIncluded"
                         id="handLuggageIncludedYes"
                         [checked]="ahmData.standartWeights.crew.handLuggageIncluded === true"
                         [value]="true"
                         [(ngModel)]="ahmData.standartWeights.crew.handLuggageIncluded">
                  <span i18n="adm|Weight and balance - yes@@adm.wb.tab.standartInits.groupCrew.button.yes">Yes</span>
                </label>
                <label>
                  <input type="radio"
                         name="handLuggageIncluded"
                         id="handLuggageIncludedNo"
                         [checked]="!ahmData.standartWeights.crew.handLuggageIncluded"
                         [value]="false"
                         [(ngModel)]="ahmData.standartWeights.crew.handLuggageIncluded">
                  <span i18n="adm|Weight and balance - no@@adm.wb.tab.standartInits.groupCrew.button.no">No</span>
                </label>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="card mb-4">
              <div class="card-body">
                <p class="font-weight-bold text-blue h5 mb-3"
                    i18n="adm|Weight and balance - standard units constants@@adm.wb.tab.standartInits.groupConstants">
                  Constants
                </p>
                <div class="row">
                  <div class="col-6">
                    <div class="input-group flex-nowrap mb-3">
                      <div class="input-group-prepend custom-sm">
                        <span class="input-group-text font-small py-0"
                              i18n="adm|Weight and balance - standard units constants@@adm.wb.tab.standartInits.groupConstants.k">
                          K
                        </span>
                      </div>
                      <input type="number" class="w-100 form-control custom-sm" [(ngModel)]="ahmData.k" />
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="input-group flex-nowrap mb-3">
                      <div class="input-group-prepend custom-sm">
                        <span class="input-group-text font-small py-0" i18n="adm|Weight and balance - standard units constants@@adm.wb.tab.standartInits.groupConstants.c">
                          C
                        </span>
                      </div>
                      <input type="number" class="w-100 form-control custom-sm" [(ngModel)]="ahmData.c" />
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="input-group flex-nowrap mb-3">
                      <div class="input-group-prepend custom-sm">
                        <span class="input-group-text font-small py-0"
                          i18n="adm|Weight and balance - standard units reference arm@@adm.wb.tab.standartInits.groupConstants.referenceArm">
                          Reference arm
                        </span>
                      </div>
                      <input type="number" class="w-100 form-control custom-sm" [(ngModel)]="ahmData.referenceArm" />
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="input-group flex-nowrap mb-3">
                      <div class="input-group-prepend custom-sm">
                        <span class="input-group-text font-small py-0"
                          i18n="adm|Weight and balance - weight@@adm.wb.tab.standartInits.groupConstants.macRc">
                          Mac RC
                        </span>
                      </div>
                      <input type="number" class="w-100 form-control custom-sm" [(ngModel)]="ahmData.macRc" />
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="input-group flex-nowrap">
                      <div class="input-group-prepend custom-sm">
                        <span class="input-group-text font-small py-0"
                          i18n="adm|Weight and balance - weight@@adm.wb.tab.standartInits.groupConstants.lemacRc">
                          Lemac RC
                        </span>
                      </div>
                      <input type="number" class="w-100 form-control custom-sm" [(ngModel)]="ahmData.lemacLerc" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="flight-information" role="tabpanel" aria-labelledby="flight-information">
        <div class="row">
          <div class="col-6 mb-4">
            <div class="card h-100 mb-3">
              <div class="card-body">
                <p class="font-weight-bold text-blue h5 mb-3" i18n="adm|Weight and balance - table ac registration@@adm.wb.tab.flightInfo.groupAcReg.table.acRegistration">A/C registration</p>
                <table class="w-100 contenteditable">
                  <tbody>
                    <tr>
                      <td i18n="adm|Weight and balance - table ac registration@@adm.wb.tab.flightInfo.groupAcReg.table.acRegistration">A/C registration</td>
                      <td>
                        <div class="w-100" *ngIf="!selectLoadAnimation.tailsFiltered; else tailsLoad">
                          <ng-select class="w-100 custom-sm"
                                    [items]="references.tailsFiltered"
                                    [virtualScroll]="true"
                                    bindLabel="tail" bindValue="id"
                                    name="ahmDataTailId" [hideSelected]="true"
                                    [searchFn]="customSelectSearch"
                                    [loading]="selectLoadAnimation.tails" (scroll)="onScrollAirlines($event, 'tails')"
                                    (scrollToEnd)="onScrollToEndAirlines('tails')" [(ngModel)]="ahmData.tailId"
                                    #ahmDataTailId="ngModel" placeholder="Enter tail for search">
                            <ng-template ng-option-tmp let-item="item">
                              IATA:&nbsp;<span
                                class="font-weight-bold">{{item.aircraftTypeIata}}</span>,&nbsp;ICAO:&nbsp;{{item.aircraftTypeIcao}}<br />
                              <small class="font-weight-bold">{{item.tail}}</small>
                            </ng-template>
                          </ng-select>
                        </div>
                        <ng-template #tailsLoad>
                          <div class="d-flex align-items-center pl-2 bg-light-gray rounded">
                            <div class="spinner-border spinner-border-sm float-left" role="status" aria-hidden="true">
                            </div>
                            <div class="float-left pl-2" i18n="adm|Weight and balance - loading@@adm.wb.main.loading">
                              Loading...
                            </div>
                          </div>
                        </ng-template>
                      </td>
                    </tr>
                    <tr>
                      <td i18n="adm|Weight and balance - flight information crew standart@@adm.wb.flightInformation.crewStandard">Crew standard</td>
                      <td class="font-weight-bold">{{ ahmData.crewStandart }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-6 mb-4">
            <div class="card">
              <div class="card-body">
                <p class="font-weight-bold text-blue h5 mb-3"
                   i18n="adm|Weight and balance@@adm.wb.tab.flightInfo.groupAircraftMaxWeight">Aircraft maximum weights</p>
                <div class="row">
                  <div class="col-6 mb-3">
                    <div class="input-group flex-nowrap">
                      <div class="input-group-prepend custom-sm">
                        <span class="input-group-text font-small py-0"
                          i18n="adm|Weight and balance - flight information zero fuel weight@@adm.wb.tab.flightInfo.groupAircraftMaxWeight.zeroFuelWeight">
                          Zero fuel weight
                        </span>
                      </div>
                      <input type="number" class="w-100 form-control custom-sm" [(ngModel)]="ahmData.zeroFuelWeight"/>
                    </div>
                  </div>
                  <div class="col-6 mb-3">
                    <div class="input-group flex-nowrap">
                      <div class="input-group-prepend custom-sm">
                        <span class="input-group-text font-small py-0"
                          i18n="adm|Weight and balance - flight information landing weight@@adm.wb.tab.flightInfo.groupAircraftMaxWeight.landingWeight">
                          Landing weight
                        </span>
                      </div>
                      <input type="number" class="w-100 form-control custom-sm" [(ngModel)]="ahmData.landingWeight" />
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="input-group flex-nowrap">
                      <div class="input-group-prepend custom-sm">
                        <span class="input-group-text font-small py-0"
                          i18n="adm|Weight and balance - flight information take off weight@@adm.wb.tab.flightInfo.groupAircraftMaxWeight.takeOffWeight">
                          Take off weight
                        </span>
                      </div>
                      <input type="number" class="w-100 form-control custom-sm" [(ngModel)]="ahmData.takeOffWeight" />
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="input-group flex-nowrap">
                      <div class="input-group-prepend custom-sm">
                        <span class="input-group-text font-small py-0"
                          i18n="adm|Weight and balance - flight information ramp taxi@@adm.wb.tab.flightInfo.groupAircraftMaxWeight.rampTaxi">
                          Ramp/Taxi
                        </span>
                      </div>
                      <input type="number" class="w-100 form-control custom-sm" [(ngModel)]="ahmData.rampTaxiWeight" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="card mb-3">
              <div class="card-body">
                <p class="font-weight-bold text-blue h5 mb-3"
                  i18n="adm|Weight and balance - flight information passenger weights@@adm.wb.tab.flightInfo.groupPassengerWeights">
                  Passenger weights
                </p>
                <table class="w-100 contenteditable mb-3">
                  <thead>
                    <tr class="font-weight-bold bg-blue color-white">
                      <td i18n="adm|Weight and balance - table season@@adm.wb.tab.flightInfo.groupPassengerWeights.table.season">Season</td>
                      <td i18n="adm|Weight and balance - table adult male@@adm.wb.tab.flightInfo.groupPassengerWeights.table.adultMale">Adult / Male</td>
                      <td i18n="adm|Weight and balance - table female@@adm.wb.tab.flightInfo.groupPassengerWeights.table.female">Female</td>
                      <td i18n="adm|Weight and balance - table children@@adm.wb.tab.flightInfo.groupPassengerWeights.table.children">Children</td>
                      <td i18n="adm|Weight and balance - table infant@@adm.wb.tab.flightInfo.groupPassengerWeights.table.infant">Infant</td>
                      <td i18n="adm|Weight and balance - table hand baggage@@adm.wb.tab.flightInfo.groupPassengerWeights.table.handBaggage">Hand baggage</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td i18n="adm|Weight and balance - table winter@@adm.wb.tab.flightInfo.groupPassengerWeights.table.winter">Winter</td>
                      <td>
                        <input type="number" [(ngModel)]="ahmData.standartWeights.passengers.winter.adult">
                      </td>
                      <td>
                        <input type="number" [(ngModel)]="ahmData.standartWeights.passengers.winter.female">
                      </td>
                      <td>
                        <input type="number" [(ngModel)]="ahmData.standartWeights.passengers.winter.child">
                      </td>
                      <td>
                        <input type="number" [(ngModel)]="ahmData.standartWeights.passengers.winter.infant">
                      </td>
                      <td>
                        <input type="number" [(ngModel)]="ahmData.standartWeights.passengers.winter.handLuggage">
                      </td>
                    </tr>
                    <tr>
                      <td i18n="adm|Weight and balance - table summer@@adm.wb.tab.flightInfo.groupPassengerWeights.table.summer">Summer</td>
                      <td>
                        <input type="number" [(ngModel)]="ahmData.standartWeights.passengers.summer.adult">
                      </td>
                      <td>
                        <input type="number" [(ngModel)]="ahmData.standartWeights.passengers.summer.female">
                      </td>
                      <td>
                        <input type="number" [(ngModel)]="ahmData.standartWeights.passengers.summer.child">
                      </td>
                      <td>
                        <input type="number" [(ngModel)]="ahmData.standartWeights.passengers.summer.infant">
                      </td>
                      <td>
                        <input type="number" [(ngModel)]="ahmData.standartWeights.passengers.summer.handLuggage">
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p class="m-0"
                    i18n="adm|Weight and balance - light information hand baggage weight is included@@adm.wb.tab.flightInfo.groupPassengerWeights.handBaggageWeightIncluded">
                  Hand baggage weight is included in the passenger weights
                </p>
                <label class="mr-3">
                  <input type="radio"
                         name="baggageIncluded"
                         id="baggageIncludedYes"
                         [checked]="ahmData.standartWeights.passengers.handLuggageIncluded === true"
                         [value]="true"
                         [(ngModel)]="ahmData.standartWeights.passengers.handLuggageIncluded">
                  <span i18n="adm|Weight and balance - yes@@adm.wb.tab.flightInfo.groupPassengerWeights.button.yes">Yes</span>
                </label>
                <label>
                  <input type="radio" name="baggageIncluded" id="baggageIncludedNo"
                    [checked]="ahmData.standartWeights.passengers.handLuggageIncluded === false" [value]="false"
                    [(ngModel)]="ahmData.standartWeights.passengers.handLuggageIncluded">
                  <span i18n="adm|Weight and balance - no@@adm.wb.tab.flightInfo.groupPassengerWeights.button.no">No</span>
                </label>
                <div class="d-flex align-items-center mt-3">
                  <p class="mb-0 mr-3 text-nowrap"
                    i18n="adm|Weight and balance - flight information passenger weight includes@@adm.wb.tab.flightInfo.groupPassengerWeights.passengerWeightIncludes">
                    Passenger weight includes carry-on weight
                  </p>
                  <div class="input-group w-auto">
                    <input type="number" class="form-control custom-sm" name="includesCarryOn" id="includesCarryOn">
                    <div class="input-group-append custom-sm">
                      <span class="input-group-text font-small py-0"
                            i18n="adm|Weight and balance - kg@@adm.wb.tab.flightInfo.groupPassengerWeights.kg">kg</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="centre-charts" role="tabpanel" aria-labelledby="centre-charts-tab">
        <div class="card mb-3" *ngFor="let centreOfGravityChart of ahmData.centreOfGravityChart; let i_cgc = index">
          <div class="card-body">
            <p class="font-weight-bold text-blue h5 mb-3 pb-2 border-bottom"
                i18n="adm|Weight and balance - fuel weights@@adm.wb.tab.centreOfGravity.fuelWeights">Fuel weights</p>
            <div class="row">
              <div class="col-4 border-right">
                <div class="input-group flex-nowrap">
                  <div class="input-group-prepend custom-sm">
                    <span class="input-group-text font-small py-0"
                      i18n="adm|Weight and balance@@adm.wb.tab.centreOfGravity.note">
                      Note
                    </span>
                  </div>
                  <input type="text" class="w-100 form-control custom-sm" [(ngModel)]="centreOfGravityChart.note" />
                </div>
                <div class="d-flex justify-content-between align-items-center pt-3">
                  <p class="font-weight-bold my-3"
                    i18n="adm|Weight and balance - rules of use@@adm.wb.tab.centreOfGravity.rulesOfUse">
                    Rules of use
                  </p>
                  <button class="btn btn-xs btn-blue"
                          (click)="addItemByClass(centreOfGravityChart.conditions, centreOfGravityChartCondition)"
                          i18n="adm|Weight and balance - button add rule@@adm.wb.tab.fuel.groupFuel.button.addRule">
                    Add rule
                  </button>
                </div>
                <table class="w-100 contenteditable mb-3">
                  <thead>
                    <tr class="font-weight-bold bg-blue color-white">
                      <td i18n="adm|Weight and balance - table type@@adm.wb.tab.centreOfGravityChart.rules.table.type" width="40%" class="p-2">Type</td>
                      <td i18n="adm|Weight and balance - table from@@adm.wb.tab.centreOfGravityChart.rules.table.from" class="p-2">From</td>
                      <td i18n="adm|Weight and balance - table to@@adm.wb.tab.centreOfGravityChart.rules.table.to" class="p-2">To</td>
                      <td width="35px"></td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let condition of centreOfGravityChart.conditions; let i_condition = index">
                      <td>
                        <ng-select [items]="chartTypes" class="w-100 custom-sm bg-white" bindLabel="nameEnglish"
                          bindValue="id" name="chartType" id="chartType" [(ngModel)]="condition.type">
                        </ng-select>
                      </td>
                      <td>
                        <input type="number" class="w-100 form-control custom-sm" [(ngModel)]="condition.from" />
                      </td>
                      <td>
                        <input type="number" class="w-100 form-control custom-sm" [(ngModel)]="condition.to" />
                      </td>
                      <td>
                        <i class="material-icons m-0 text-red font-weight-bold pointer" ngbTooltip="Delete"
                        (click)="deleteElement(centreOfGravityChart.conditions, i_condition)"
                        i18n-ngbTooltip="adm|Weight and balance buttons - delete@@adm.wb.main.button.delete">clear</i>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="d-flex justify-content-between align-items-center border-top mt-3 pt-3">
                  <p class="font-weight-bold my-3"
                    i18n="adm|Weight and balance - centure of gravity@@adm.wb.tab.centreOfGravity.centreOfGravity">
                    Centure of gravity
                  </p>
                  <button type="button" class="btn btn-xs btn-blue"
                    (click)="getGravity(ahmData.centreOfGravityChart)"
                    i18n="adm|Weight and balance buttons - refresh chart@@adm.wb.main.button.refreshChart">
                    Refresh
                  </button>
                </div>
                <div id="gravityCentreBlock_{{i_cgc}}" class="mt-3" #gravityCentreBlock_{{i_cgc}}></div>
              </div>
              <div class="col-8">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item">
                    <a class="nav-link active" id="zeroFuel-tab" data-toggle="tab"
                      i18n="adm|Weight and balance - zero fuel@@adm.wb.tab.centreOfGravity.zeroFuel"
                      href="#zeroFuel_{{i_cgc}}" role="tab">Zero fuel</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" id="takeOff-tab" data-toggle="tab"
                      i18n="adm|Weight and balance - take off@@adm.wb.tab.centreOfGravity.takeOff"
                      href="#takeOff_{{i_cgc}}" role="tab">Take off</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" id="landing-tab" data-toggle="tab"
                      i18n="adm|Weight and balance - landing@@adm.wb.tab.centreOfGravity.landing"
                      href="#landing_{{i_cgc}}" role="tab">Landing</a>
                  </li>
                </ul>
                <div class="tab-content pt-3" id="myTabContent">
                  <div class="tab-pane fade show active" id="zeroFuel_{{i_cgc}}" role="tabpanel" aria-labelledby="zeroFuel-tab">
                    <div class="row">
                      <div class="col-6">
                        <div class="d-flex justify-content-between align-items-center mb-3">
                          <p class="mb-0 font-weight-bold"
                              i18n="adm|Weight and balance@@adm.wb.tab.centreOfGravity.groupZeroFuel.fwd">FWD</p>
                          <button class="btn-ico btn btn-xs btn-light-gray"
                            (click)="addItemByClass(centreOfGravityChart.fwd.zeroFuel, itemEl)">
                            +
                          </button>
                        </div>
                        <table class="w-100 contenteditable text-center mb-3">
                          <thead>
                            <tr class="font-weight-bold">
                              <td width="35px"></td>
                              <td i18n="adm|Weight and balance - table weight@@adm.wb.table.weight">Weight</td>
                              <td></td>
                              <td i18n="adm|Weight and balance - table index@@adm.wb.table.index">Index</td>
                              <td width="35px"></td>
                            </tr>
                          </thead>
                          <tbody cdkDropList (cdkDropListDropped)="drop($event, centreOfGravityChart.fwd.zeroFuel)">
                            <tr *ngFor="let element of centreOfGravityChart.fwd.zeroFuel; let i = index" cdkDrag>
                              <td><i class="material-icons pointer" cdkDragHandle>drag_handle</i></td>
                              <td contenteditable="true" [innerHTML]="element.weight" (keydown)="inputNotLetter($event)"
                                (blur)="element.weight = toNumber($event.target.innerHTML)"></td>
                              <td (click)="element.index = -element.index"
                                  class="font-weight-bold h5"
                                  [ngClass]="{'text-red': element.index < 0, 'text-green': element.index > 0}">
                                {{ element.index < 0 ? '-' : '+' }}
                              </td>
                              <td contenteditable="true"
                                  [innerHTML]="element.index < 0 ? -element.index : element.index"
                                  (keydown)="inputNotLetter($event)"
                                  (blur)="element.index = toNumber(element.index >= 0 ? $event.target.innerHTML : '-' + $event.target.innerHTML)">
                              </td>
                              <td>
                                <i class="material-icons m-0 text-red font-weight-bold pointer"
                                  (click)="deleteElement(centreOfGravityChart.fwd.zeroFuel, i)">clear</i>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="col-6">
                        <div class="d-flex justify-content-between align-items-center mb-3">
                          <p class="mb-0 font-weight-bold"
                             i18n="adm|Weight and balance@@adm.wb.tab.centreOfGravity.groupZeroFuel.aft">AFT</p>
                          <button class="btn-ico btn btn-xs btn-light-gray"
                            (click)="addItemByClass(centreOfGravityChart.aft.zeroFuel, itemEl)">
                            +
                          </button>
                        </div>
                        <table class="w-100 contenteditable text-center mb-3">
                          <thead>
                            <tr class="font-weight-bold">
                              <td width="35px"></td>
                              <td i18n="adm|Weight and balance - table weight@@adm.wb.table.weight">Weight</td>
                              <td></td>
                              <td i18n="adm|Weight and balance - table index@@adm.wb.table.index">Index</td>
                              <td width="35px"></td>
                            </tr>
                          </thead>
                          <tbody cdkDropList (cdkDropListDropped)="drop($event, centreOfGravityChart.aft.zeroFuel)">
                            <tr *ngFor="let element of centreOfGravityChart.aft.zeroFuel; let i = index" cdkDrag>
                              <td><i class="material-icons pointer" cdkDragHandle>drag_handle</i></td>
                              <td contenteditable="true" [innerHTML]="element.weight" (keydown)="inputNotLetter($event)"
                                (blur)="element.weight = toNumber($event.target.innerHTML)"></td>
                              <td (click)="element.index = -element.index"
                                  class="font-weight-bold h5"
                                  [ngClass]="{'text-red': element.index < 0, 'text-green': element.index > 0}">
                                {{ element.index < 0 ? '-' : '+' }}
                              </td>
                              <td contenteditable="true"
                                  [innerHTML]="element.index < 0 ? -element.index : element.index"
                                  (keydown)="inputNotLetter($event)"
                                  (blur)="element.index = toNumber(element.index >= 0 ? $event.target.innerHTML : '-' + $event.target.innerHTML)">
                              </td>
                              <td>
                                <i class="material-icons m-0 text-red font-weight-bold pointer"
                                  (click)="deleteElement(centreOfGravityChart.aft.zeroFuel, i)">clear</i>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="takeOff_{{i_cgc}}" role="tabpanel" aria-labelledby="takeOff-tab">
                    <div class="row">
                      <div class="col-6">
                        <div class="d-flex justify-content-between align-items-center mb-3">
                          <p class="mb-0 font-weight-bold"
                             i18n="adm|Weight and balance - charts take off@@adm.wb.tab.centreOfGravity.fwd">FWD</p>
                          <button class="btn-ico btn btn-xs btn-light-gray"
                            (click)="addItemByClass(centreOfGravityChart.fwd.takeOff, itemEl)">
                            +
                          </button>
                        </div>
                        <table class="w-100 contenteditable text-center mb-3">
                          <thead>
                            <tr class="font-weight-bold">
                              <td width="35px"></td>
                              <td i18n="adm|Weight and balance - table weight@@adm.wb.table.weight">Weight</td>
                              <td></td>
                              <td i18n="adm|Weight and balance - table index@@adm.wb.table.index">Index</td>
                              <td width="35px"></td>
                            </tr>
                          </thead>
                          <tbody cdkDropList (cdkDropListDropped)="drop($event, centreOfGravityChart.fwd.takeOff)">
                            <tr *ngFor="let element of centreOfGravityChart.fwd.takeOff; let i = index" cdkDrag>
                              <td><i class="material-icons pointer" cdkDragHandle>drag_handle</i></td>
                              <td contenteditable="true" [innerHTML]="element.weight" (keydown)="inputNotLetter($event)"
                                (blur)="element.weight = toNumber($event.target.innerHTML)"></td>
                              <td (click)="element.index = -element.index"
                                  class="font-weight-bold h5"
                                  [ngClass]="{'text-red': element.index < 0, 'text-green': element.index > 0}">
                                {{ element.index < 0 ? '-' : '+' }}
                              </td>
                              <td contenteditable="true"
                                  [innerHTML]="element.index < 0 ? -element.index : element.index"
                                  (keydown)="inputNotLetter($event)"
                                  (blur)="element.index = toNumber(element.index >= 0 ? $event.target.innerHTML : '-' + $event.target.innerHTML)">
                              </td>
                              <td>
                                <i class="material-icons m-0 text-red font-weight-bold pointer"
                                  (click)="deleteElement(centreOfGravityChart.fwd.takeOff, i)">clear</i>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="col-6">
                        <div class="d-flex justify-content-between align-items-center mb-3">
                          <p class="mb-0 font-weight-bold"
                            i18n="adm|Weight and balance - charts take off@@adm.wb.tab.centreOfGravity.aft">AFT</p>
                          <button class="btn-ico btn btn-xs btn-light-gray"
                            (click)="addItemByClass(centreOfGravityChart.aft.takeOff, itemEl)">
                            +
                          </button>
                        </div>
                        <table class="w-100 contenteditable text-center mb-3">
                          <thead>
                            <tr class="font-weight-bold">
                              <td width="35px"></td>
                              <td i18n="adm|Weight and balance - table weight@@adm.wb.table.weight">Weight</td>
                              <td></td>
                              <td i18n="adm|Weight and balance - table index@@adm.wb.table.index">Index</td>
                              <td width="35px"></td>
                            </tr>
                          </thead>
                          <tbody cdkDropList (cdkDropListDropped)="drop($event, centreOfGravityChart.aft.takeOff)">
                            <tr *ngFor="let element of centreOfGravityChart.aft.takeOff; let i = index" cdkDrag>
                              <td><i class="material-icons pointer" cdkDragHandle>drag_handle</i></td>
                              <td contenteditable="true" [innerHTML]="element.weight" (keydown)="inputNotLetter($event)"
                                (blur)="element.weight = toNumber($event.target.innerHTML)"></td>
                              <td (click)="element.index = -element.index"
                                  class="font-weight-bold h5"
                                  [ngClass]="{'text-red': element.index < 0, 'text-green': element.index > 0}">
                                {{ element.index < 0 ? '-' : '+' }}
                              </td>
                              <td contenteditable="true"
                                  [innerHTML]="element.index < 0 ? -element.index : element.index"
                                  (keydown)="inputNotLetter($event)"
                                  (blur)="element.index = toNumber(element.index >= 0 ? $event.target.innerHTML : '-' + $event.target.innerHTML)">
                              </td>
                              <td>
                                <i class="material-icons m-0 text-red font-weight-bold pointer"
                                  (click)="deleteElement(centreOfGravityChart.aft.takeOff, i)">clear</i>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="landing_{{i_cgc}}" role="tabpanel" aria-labelledby="landing-tab">
                    <div class="row">
                      <div class="col-6">
                        <div class="d-flex justify-content-between align-items-center mb-3">
                          <p class="mb-0 font-weight-bold"
                             i18n="adm|Weight and balance - charts landing@@adm.wb.tab.centreOfGravity.groupLanding.fwd">FWD</p>
                          <button class="btn-ico btn btn-xs btn-light-gray"
                            (click)="addItemByClass(centreOfGravityChart.fwd.landing, itemEl)">
                            +
                          </button>
                        </div>
                        <table class="w-100 contenteditable text-center mb-3">
                          <thead>
                            <tr class="font-weight-bold">
                              <td width="35px"></td>
                              <td i18n="adm|Weight and balance - table weight@@adm.wb.table.weight">Weight</td>
                              <td></td>
                              <td i18n="adm|Weight and balance - table index@@adm.wb.table.index">Index</td>
                              <td width="35px"></td>
                            </tr>
                          </thead>
                          <tbody cdkDropList (cdkDropListDropped)="drop($event, centreOfGravityChart.fwd.landing)">
                            <tr *ngFor="let element of centreOfGravityChart.fwd.landing; let i = index" cdkDrag>
                              <td><i class="material-icons pointer" cdkDragHandle>drag_handle</i></td>
                              <td contenteditable="true" [innerHTML]="element.weight" (keydown)="inputNotLetter($event)"
                                (blur)="element.weight = toNumber($event.target.innerHTML)"></td>
                              <td (click)="element.index = -element.index"
                                  class="font-weight-bold h5"
                                  [ngClass]="{'text-red': element.index < 0, 'text-green': element.index > 0}">
                                {{ element.index < 0 ? '-' : '+' }}
                              </td>
                              <td contenteditable="true"
                                  [innerHTML]="element.index < 0 ? -element.index : element.index"
                                  (keydown)="inputNotLetter($event)"
                                  (blur)="element.index = toNumber(element.index >= 0 ? $event.target.innerHTML : '-' + $event.target.innerHTML)">
                              </td>
                              <td>
                                <i class="material-icons m-0 text-red font-weight-bold pointer"
                                  (click)="deleteElement(centreOfGravityChart.fwd.landing, i)">clear</i>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="col-6">
                        <div class="d-flex justify-content-between align-items-center mb-3">
                          <p class="mb-0 font-weight-bold"
                             i18n="adm|Weight and balance - charts landing@@adm.wb.tab.centreOfGravity.groupLanding.aft">AFT</p>
                          <button class="btn-ico btn btn-xs btn-light-gray"
                            (click)="addItemByClass(centreOfGravityChart.aft.landing, itemEl)">
                            +
                          </button>
                        </div>
                        <table class="w-100 contenteditable text-center mb-3">
                          <thead>
                            <tr class="font-weight-bold">
                              <td width="35px"></td>
                              <td i18n="adm|Weight and balance - table weight@@adm.wb.table.weight">Weight</td>
                              <td></td>
                              <td i18n="adm|Weight and balance - table index@@adm.wb.table.index">Index</td>
                              <td width="35px"></td>
                            </tr>
                          </thead>
                          <tbody cdkDropList (cdkDropListDropped)="drop($event, centreOfGravityChart.aft.landing)">
                            <tr *ngFor="let element of centreOfGravityChart.aft.landing; let i = index" cdkDrag>
                              <td><i class="material-icons pointer" cdkDragHandle>drag_handle</i></td>
                              <td contenteditable="true" [innerHTML]="element.weight" (keydown)="inputNotLetter($event)"
                                (blur)="element.weight = toNumber($event.target.innerHTML)"></td>
                              <td (click)="element.index = -element.index"
                                  class="font-weight-bold h5"
                                  [ngClass]="{'text-red': element.index < 0, 'text-green': element.index > 0}">
                                {{ element.index < 0 ? '-' : '+' }}
                              </td>
                              <td contenteditable="true"
                                  [innerHTML]="element.index < 0 ? -element.index : element.index"
                                  (keydown)="inputNotLetter($event)"
                                  (blur)="element.index = toNumber(element.index >= 0 ? $event.target.innerHTML : '-' + $event.target.innerHTML)">
                              </td>
                              <td>
                                <i class="material-icons m-0 text-red font-weight-bold pointer"
                                  (click)="deleteElement(centreOfGravityChart.aft.landing, i)">clear</i>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button type="button" class="btn ml-3 btn-red float-right"
              (click)="deleteElement(ahmData.centreOfGravityChart, i_cgc)"
              i18n="adm|Weight and balance buttons - delete@@adm.wb.main.button.delete">
              Delete
            </button>
          </div>
        </div>
        <div class="text-right">
          <button class="btn btn-blue" (click)="addItemByClass(ahmData.centreOfGravityChart, centreOfGravityChart)"
                  i18n="adm|Weight and balance - button add@@adm.wb.tab.fuel.groupFuel.button.add">
            Add
          </button>
        </div>
      </div>
      <div class="tab-pane fade" id="fuel" role="tabpanel" aria-labelledby="fuel-tab">
        <div class="input-group flex-nowrap mb-3">
          <div class="input-group-prepend custom-sm">
            <span class="input-group-text font-small py-0"
                  i18n="adm|Weight and balance - fuel taxi fuel weight@@adm.wb.tab.fuel.taxiFuelWeight">
              Taxi fuel weight
            </span>
          </div>
          <input type="number" class="w-100 form-control custom-sm" [(ngModel)]="ahmData.taxiFuel" />
        </div>
        <div class="row">
          <div class="col-lg-6 mb-3" *ngFor="let effectOfFuel of ahmData.effectOfFuel; let indexFuel = index">
            <div class="card h-100">
              <div class="card-body">
                <div class="d-flex align-items-center justify-content-between mb-2">
                  <div>
                    <p class="font-weight-bold text-blue h5 mb-2"
                      i18n="adm|Weight and balance - fuel fuel@@adm.wb.tab.fuel.groupFuel">Fuel</p>
                    <label>
                      <input type="checkbox"
                            [(ngModel)]="effectOfFuel.default"
                            (click)="setStandard(ahmData.effectOfFuel, 'default', indexFuel)"
                            [checked]="effectOfFuel.default">&nbsp;<span i18n="adm|Weight and balance - fuel@@adm.wb.tab.fuel.groupFuel.standard">Standard</span>
                    </label>
                  </div>
                  <div>
                    <button type="button" class="btn btn-xs mr-2 btn-ico btn-blue" ngbTooltip="Duplicate"
                      (click)="copyItem(ahmData.effectOfFuel, ahmData.effectOfFuel[indexFuel])"
                      i18n-ngbTooltip="adm|Weight and balance buttons - duplicate@@adm.wb.main.button.duplicate">
                      <i class="material-icons color-white">content_copy</i>
                    </button>
                    <button type="button" class="btn btn-xs mr-2 btn-ico btn-red" ngbTooltip="Delete"
                      (click)="deleteElement(ahmData.effectOfFuel, indexFuel)"
                      i18n-ngbTooltip="adm|Weight and balance buttons - delete@@adm.wb.main.button.delete">
                      <i class="material-icons color-white">delete</i>
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 pr-0">
                    <div class="input-group flex-nowrap mb-3">
                      <div class="input-group-prepend custom-sm">
                        <span class="input-group-text font-small py-0" i18n="adm|Weight and balance - fuel fuel density@@adm.wb.tab.fuel.groupFuel.fuelDensity">
                          Density
                        </span>
                      </div>
                      <input type="number" class="w-100 form-control custom-sm" [(ngModel)]="effectOfFuel.density" />
                    </div>
                  </div>
                  <div class="col-md-4 pr-0">
                    <div class="input-group flex-nowrap mb-3">
                      <div class="input-group-prepend custom-sm">
                        <span class="input-group-text font-small py-0" i18n="adm|Weight and balance - fuel fuel density min@@adm.wb.tab.fuel.groupFuel.fuelDensityMin">
                          Density min
                        </span>
                      </div>
                      <input type="number" class="w-100 form-control custom-sm" />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="input-group flex-nowrap">
                      <div class="input-group-prepend custom-sm">
                        <span class="input-group-text font-small py-0" i18n="adm|Weight and balance - fuel fuel density max@@adm.wb.tab.fuel.groupFuel.fuelDensityMax">
                          Density max
                        </span>
                      </div>
                      <input type="number" class="w-100 form-control custom-sm"/>
                    </div>
                  </div>
                </div>
                <table class="w-100 contenteditable text-center mb-3">
                  <thead>
                    <tr class="font-weight-bold">
                      <td width="35px"></td>
                      <td i18n="adm|Weight and balance - table fuel weight@@adm.wb.tab.fuel.groupFuel.table.fuelWeight">Fuel Weight</td>
                      <td></td>
                      <td i18n="adm|Weight and balance - table index@@adm.wb.tab.fuel.groupFuel.table.index">Index</td>
                      <td width="35px"></td>
                    </tr>
                  </thead>
                  <tbody cdkDropList (cdkDropListDropped)="drop($event, effectOfFuel.balance)">
                    <tr *ngFor="let element of effectOfFuel.balance; let i = index" cdkDrag>
                      <td><i class="material-icons pointer" cdkDragHandle>drag_handle</i></td>
                      <td contenteditable="true" [innerHTML]="element.weight" (keydown)="inputNotLetter($event)"
                        (blur)="element.weight = toNumber($event.target.innerHTML)"></td>
                      <td (click)="element.index = -element.index"
                          class="font-weight-bold h5"
                          [ngClass]="{'text-red': element.index < 0, 'text-green': element.index > 0}">
                        {{ element.index < 0 ? '-' : '+' }}
                      </td>
                      <td contenteditable="true" [innerHTML]="element.index < 0 ? -element.index : element.index"
                        (keydown)="inputNotLetter($event)" (blur)="element.index = toNumber(element.index >= 0 ? $event.target.innerHTML : '-' + $event.target.innerHTML)">
                      </td>
                      <td>
                        <i class="material-icons m-0 text-red font-weight-bold pointer"
                          (click)="deleteElement(effectOfFuel.balance, i)">clear</i>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="text-right">
                  <button class="btn btn-blue" (click)="addItemByClass(effectOfFuel.balance, itemEl)"
                          i18n="adm|Weight and balance - button add@@adm.wb.tab.fuel.groupFuel.button.add">
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-3">
            <div class="card bg-gray" (click)="addItem(ahmData.effectOfFuel, {density: null, default:false, balance: []})">
              <div class="card-body d-flex align-items-center justify-content-center">
                <div class="h1">+</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="holds-compartments" role="tabpanel" aria-labelledby="holds-compartments-tab">
        <div class="row mb-4">
          <div class="col-md-6">
            <div class="card h-100">
              <div class="card-body">
                <p class="font-weight-bold text-blue h5 mb-3" i18n="adm|Weight and balance@@adm.wb.tab.holdsAndCompartments.groupFwd">FWD</p>
                <table class="w-100 contenteditable text-center mb-3">
                  <thead>
                    <tr class="font-weight-bold">
                      <td width="35px"></td>
                      <td i18n="adm|Weight and balance - table compartment@@adm.wb.tab.holdsAndCompartments.groupFwd.table.compartment">Compartment</td>
                      <td i18n="adm|Weight and balance - table section@@adm.wb.tab.holdsAndCompartments.groupFwd.table.section">Bay / Section</td>
                      <td i18n="adm|Weight and balance - table type@@adm.wb.tab.holdsAndCompartments.groupFwd.table.type">Type</td>
                      <td i18n="adm|Weight and balance - table max weight@@adm.wb.tab.holdsAndCompartments.groupFwd.table.maxWeight">Max weight</td>
                      <td i18n="adm|Weight and balance - table volume@@adm.wb.tab.holdsAndCompartments.groupFwd.table.volume">Volume</td>
                      <td></td>
                      <td i18n="adm|Weight and balance - table index@@adm.wb.tab.holdsAndCompartments.groupFwd.table.index">Index</td>
                      <td width="35px"></td>
                    </tr>
                  </thead>
                  <tbody cdkDropList (cdkDropListDropped)="drop($event, ahmData.holdsAndCompartments.fwd)">
                    <tr *ngFor="let element of ahmData.holdsAndCompartments.fwd; let i = index" cdkDrag>
                      <td><i class="material-icons pointer" cdkDragHandle>drag_handle</i></td>
                      <td contenteditable="true"[innerHTML]="element.group"
                          (blur)="element.group = $event.target.innerHTML"></td>
                      <td contenteditable="true" [innerHTML]="element.name"
                          (blur)="element.name = $event.target.innerHTML"></td>
                      <td>
                        <select id="holdsAndCompartments_hwd_{{i}}" class="form-control form-control-sm mr-2 py-0"
                          name="holdsAndCompartments_hwd_{{i}}" [value]="element.type"
                          (change)="element.type = $event.target.value">
                          <option value="bulk">BULK</option>
                          <option value="uld">ULD</option>
                        </select>
                      </td>
                      <td contenteditable="true" [innerHTML]="element.maxWeight" (keydown)="inputNotLetter($event)"
                        (blur)="element.maxWeight = toNumber($event.target.innerHTML)"></td>
                      <td contenteditable="true" [innerHTML]="element.volume" (keydown)="inputNotLetter($event)"
                        (blur)="element.volume = toNumber($event.target.innerHTML)"></td>
                      <td (click)="element.index = -element.index"
                          class="font-weight-bold h5"
                          [ngClass]="{'text-red': element.index < 0, 'text-green': element.index > 0}">
                        {{ element.index < 0 ? '-' : '+' }}
                      </td>
                      <td contenteditable="true" [innerHTML]="element.index < 0 ? -element.index : element.index"
                        (keydown)="inputNotLetter($event)" (blur)="element.index = toNumber(element.index >= 0 ? $event.target.innerHTML : '-' + $event.target.innerHTML)">
                      </td>
                      <td>
                        <i class="material-icons m-0 text-red font-weight-bold pointer"
                          (click)="deleteElement(ahmData.holdsAndCompartments.fwd, i)">clear</i>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="text-right">
                  <button class="btn btn-blue"
                          (click)="addItemByClass(ahmData.holdsAndCompartments.fwd, holdsAndCompartments)"
                          i18n="adm|Weight and balance - button add@@adm.wb.tab.holdsAndCompartments.groupFwd.button.add">
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card h-100">
              <div class="card-body">
                <p class="font-weight-bold text-blue h5 mb-3" i18n="adm|Weight and balance@@adm.wb.tab.holdsAndCompartments.groupAft">AFT</p>
                <table class="w-100 contenteditable text-center mb-3">
                  <thead>
                    <tr class="font-weight-bold">
                      <td width="35px"></td>
                      <td i18n="adm|Weight and balance - table compartment@@adm.wb.tab.holdsAndCompartments.groupAft.table.compartment">Compartment</td>
                      <td i18n="adm|Weight and balance - table section@@adm.wb.tab.holdsAndCompartments.groupFwd.table.section">Bay / Section</td>
                      <td i18n="adm|Weight and balance - table type@@adm.wb.tab.holdsAndCompartments.groupAft.table.type">Type</td>
                      <td i18n="adm|Weight and balance - table max weight@@adm.wb.tab.holdsAndCompartments.groupAft.table.maxWeight">Max weight</td>
                      <td i18n="adm|Weight and balance - table volume@@adm.wb.tab.holdsAndCompartments.groupAft.table.volume">Volume</td>
                      <td></td>
                      <td i18n="adm|Weight and balance - table index@@adm.wb.tab.holdsAndCompartments.groupAft.table.index">Index</td>
                      <td width="35px"></td>
                    </tr>
                  </thead>
                  <tbody cdkDropList (cdkDropListDropped)="drop($event, ahmData.holdsAndCompartments.aft)">
                    <tr *ngFor="let element of ahmData.holdsAndCompartments.aft; let i = index" cdkDrag>
                      <td><i class="material-icons pointer" cdkDragHandle>drag_handle</i></td>
                      <td contenteditable="true" [innerHTML]="element.group"
                        (blur)="element.group = $event.target.innerHTML"></td>
                      <td contenteditable="true" [innerHTML]="element.name"
                        (blur)="element.name = $event.target.innerHTML"></td>
                      <td>
                        <select id="holdsAndCompartments_hwd_{{i}}" class="form-control form-control-sm mr-2 py-0"
                          name="holdsAndCompartments_hwd_{{i}}" [value]="element.type"
                          (change)="element.type = $event.target.value">
                          <option value="bulk">BULK</option>
                          <option value="uld">ULD</option>
                        </select>
                      </td>
                      <td contenteditable="true" [innerHTML]="element.maxWeight" (keydown)="inputNotLetter($event)"
                        (blur)="element.maxWeight = toNumber($event.target.innerHTML)"></td>
                      <td contenteditable="true" [innerHTML]="element.volume" (keydown)="inputNotLetter($event)"
                        (blur)="element.volume = toNumber($event.target.innerHTML)"></td>
                      <td (click)="element.index = -element.index"
                          class="font-weight-bold h5"
                          [ngClass]="{'text-red': element.index < 0, 'text-green': element.index > 0}">
                        {{ element.index < 0 ? '-' : '+' }}
                      </td>
                      <td contenteditable="true" [innerHTML]="element.index < 0 ? -element.index : element.index"
                        (keydown)="inputNotLetter($event)" (blur)="element.index = toNumber(element.index >= 0 ? $event.target.innerHTML : '-' + $event.target.innerHTML)">
                      </td>
                      <td>
                        <i class="material-icons m-0 text-red font-weight-bold pointer"
                          (click)="deleteElement(ahmData.holdsAndCompartments.aft, i)">clear</i>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="text-right">
                  <button class="btn btn-blue"
                          (click)="addItemByClass(ahmData.holdsAndCompartments.aft, holdsAndCompartments)"
                          i18n="adm|Weight and balance - button add@@adm.wb.tab.holdsAndCompartments.groupAft.button.add">
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <p class="font-weight-bold text-blue h5 mb-3" i18n="adm|Weight and balance@@adm.wb.tab.holdsAndCompartments.uldTypes">ULD types</p>
            <table class="w-100 contenteditable text-center mb-3">
              <thead>
                <tr class="font-weight-bold">
                  <td width="35px"></td>
                  <td i18n="adm|Weight and balance - table name@@adm.wb.tab.holdsAndCompartments.uldTypes.table.name">Name</td>
                  <td i18n="adm|Weight and balance - table weight@@adm.wb.tab.holdsAndCompartments.uldTypes.table.weight">Weight</td>
                  <td i18n="adm|Weight and balance - table max weight@@adm.wb.tab.holdsAndCompartments.uldTypes.table.maxWeight">Max weight</td>
                  <td i18n="adm|Weight and balance - table max volume@@adm.wb.tab.holdsAndCompartments.uldTypes.table.maxVolume">Max volume</td>
                  <td width="35px"></td>
                </tr>
              </thead>
              <tbody cdkDropList (cdkDropListDropped)="drop($event, ahmData.uldTypes)">
                <tr *ngFor="let element of ahmData.uldTypes; let i = index" cdkDrag>
                  <td><i class="material-icons pointer" cdkDragHandle>drag_handle</i></td>
                  <td contenteditable="true" [innerHTML]="element.name"
                      (blur)="element.name = $event.target.innerHTML"></td>
                  <td contenteditable="true" [innerHTML]="element.weight" (keydown)="inputNotLetter($event)"
                    (blur)="element.weight = toNumber($event.target.innerHTML)"></td>
                  <td contenteditable="true" [innerHTML]="element.maxWeight" (keydown)="inputNotLetter($event)"
                    (blur)="element.maxWeight = toNumber($event.target.innerHTML)"></td>
                  <td contenteditable="true" [innerHTML]="element.maxVolume" (keydown)="inputNotLetter($event)"
                    (blur)="element.maxVolume = toNumber($event.target.innerHTML)"></td>
                  <td>
                    <i class="material-icons m-0 text-red font-weight-bold pointer"
                      (click)="deleteElement(ahmData.uldTypes, i)">clear</i>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="text-right">
              <button class="btn btn-blue"
                      (click)="addItemByClass(ahmData.uldTypes, uldTypes)"
                      i18n="adm|Weight and balance - button add@@adm.wb.tab.holdsAndCompartments.uldTypes.button.add">
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="cabin-equipment" role="tabpanel" aria-labelledby="cabin-equipment-tab">
        <div class="row mb-3">
          <div class="col-md-6 mb-3" *ngFor="let configuration of ahmData.configurations; let indexConfiguration = index">
            <div class="card h-100">
              <div class="card-body">
                <div class="d-flex align-items-center justify-content-between mb-3">
                  <p class="font-weight-bold text-blue h5 mb-3"
                     i18n="adm|Weight and balance - button add@@adm.wb.tab.cabinAndEquipment.groupCabinArea">Cabin area information</p>
                  <div>
                    <button type="button" class="btn btn-xs mr-2 btn-ico btn-blue" ngbTooltip="Duplicate"
                      (click)="copyItem(ahmData.configurations, ahmData.configurations[indexConfiguration])"
                      i18n-ngbTooltip="adm|Weight and balance buttons - duplicate@@adm.wb.main.button.duplicate">
                      <i class="material-icons color-white">content_copy</i>
                    </button>
                    <button type="button" class="btn btn-xs mr-2 btn-ico btn-red" ngbTooltip="Delete"
                      (click)="deleteElement(ahmData.configurations, indexConfiguration)"
                      i18n-ngbTooltip="adm|Weight and balance buttons - delete@@adm.wb.main.button.delete">
                      <i class="material-icons color-white">delete</i>
                    </button>
                  </div>
                </div>
                <div class="input-group flex-nowrap mb-3">
                  <div class="input-group-prepend custom-sm">
                    <span class="input-group-text font-small py-0"
                          i18n="adm|Weight and balance - weight@@adm.wb.tab.cabinAndEquipment.groupCabinArea.name">
                      Name
                    </span>
                  </div>
                  <input type="text" class="w-100 form-control custom-sm" [(ngModel)]="configuration.name" />
                </div>
                <table class="w-100 contenteditable text-center mb-3">
                  <thead>
                    <tr class="font-weight-bold">
                      <td width="35px"></td>
                      <td i18n="adm|Weight and balance@@adm.wb.tab.cabinAndEquipment.groupCabinArea.table.section">Section</td>
                      <td i18n="adm|Weight and balance@@adm.wb.tab.cabinAndEquipment.groupCabinArea.table.rowFrom">Row from</td>
                      <td i18n="adm|Weight and balance@@adm.wb.tab.cabinAndEquipment.groupCabinArea.table.rowTo">Row to</td>
                      <td i18n="adm|Weight and balance@@adm.wb.tab.cabinAndEquipment.groupCabinArea.table.maxSeats">Max seats</td>
                      <td></td>
                      <td i18n="adm|Weight and balance@@adm.wb.tab.cabinAndEquipment.groupCabinArea.table.indexPerWeightUnit">Index per weight unit</td>
                      <td width="35px"></td>
                    </tr>
                  </thead>
                  <tbody cdkDropList (cdkDropListDropped)="drop($event, configuration.sections)">
                    <tr *ngFor="let section of configuration.sections; let i = index" cdkDrag>
                      <td><i class="material-icons pointer" cdkDragHandle>drag_handle</i></td>
                      <td contenteditable="true" [innerHTML]="section.name"
                        (blur)="section.name = clearTags($event.target.innerHTML)"></td>
                      <td contenteditable="true" [innerHTML]="section.rowFrom" (keydown)="inputNotLetter($event)"
                          (blur)="section.rowFrom = toNumber($event.target.innerHTML)"></td>
                      <td contenteditable="true" [innerHTML]="section.rowTo" (keydown)="inputNotLetter($event)"
                          (blur)="section.rowTo = toNumber($event.target.innerHTML)"></td>
                      <td contenteditable="true" [innerHTML]="section.maxPassengers" (keydown)="inputNotLetter($event)"
                        (blur)="section.maxPassengers = toNumber($event.target.innerHTML)"></td>
                      <td (click)="section.index = -section.index"
                          class="font-weight-bold h5"
                          [ngClass]="{'text-red': section.index < 0, 'text-green': section.index > 0}">
                        {{ section.index < 0 ? '-' : '+' }}
                      </td>
                      <td contenteditable="true" [innerHTML]="section.index < 0 ? -section.index : section.index"
                        (keydown)="inputNotLetter($event)" (blur)="section.index = toNumber(section.index >= 0 ? $event.target.innerHTML : '-' + $event.target.innerHTML)">
                      </td>
                      <td>
                        <i class="material-icons m-0 text-red font-weight-bold pointer"
                          (click)="deleteElement(configuration.sections, i)">clear</i>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="text-right">
                  <button class="btn btn-blue"
                          (click)="addItemByClass(configuration.sections, configurationSection)"
                          i18n="adm|Weight and balance@@adm.wb.tab.cabinAndEquipment.groupCabinArea.button.add">
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-3">
            <div class="card bg-gray" (click)="addItem(ahmData.configurations, {name: '', sections: []})">
              <div class="card-body d-flex align-items-center justify-content-center">
                <div class="h1">+</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="crew-composition" role="tabpanel" aria-labelledby="crew-composition-tab">
        <div class="row">
          <div class="col-md-6 mb-3">
            <div class="card h-100">
              <div class="card-body">
                <p class="font-weight-bold text-blue h5 mb-3"
                   i18n="adm|Weight and balance@@adm.wb.tab.crewComposition.groupCrewComposition">Crew composition</p>
                <table class="w-100 contenteditable text-center mb-3">
                  <thead>
                    <tr class="font-weight-bold">
                      <td width="35px"></td>
                      <td i18n="adm|Weight and balance@@adm.wb.tab.crewComposition.groupCrewComposition.table.cockpitCrew">Cockpit сrew</td>
                      <td i18n="adm|Weight and balance@@adm.wb.tab.crewComposition.groupCrewComposition.table.cabinCrew">Cabin сrew</td>
                      <td i18n="adm|Weight and balance@@adm.wb.tab.crewComposition.groupCrewComposition.table.extraCrew">Extra сrew</td>
                      <td i18n="adm|Weight and balance@@adm.wb.tab.crewComposition.groupCrewComposition.table.standartConfiguration">Standard configuration</td>
                      <td i18n="adm|Weight and balance@@adm.wb.tab.crewComposition.groupCrewComposition.table.dow">DOW</td>
                      <td i18n="adm|Weight and balance@@adm.wb.tab.crewComposition.groupCrewComposition.table.doi">DOI</td>
                      <td width="35px"></td>
                    </tr>
                  </thead>
                  <tbody cdkDropList (cdkDropListDropped)="drop($event, ahmData.dowChanges.crew)">
                    <tr *ngFor="let crew of ahmData.dowChanges.crew; let i = index" cdkDrag>
                      <td><i class="material-icons pointer" cdkDragHandle>drag_handle</i></td>
                      <td contenteditable="true" [innerHTML]="crew.cockpit" (keydown)="inputNotLetter($event)"
                        (blur)="crew.cockpit = toNumber($event.target.innerHTML)"></td>
                      <td contenteditable="true" [innerHTML]="crew.cabin" (keydown)="inputNotLetter($event)"
                        (blur)="crew.cabin = toNumber($event.target.innerHTML)"></td>
                      <td contenteditable="true" [innerHTML]="crew.extra" (keydown)="inputNotLetter($event)"
                        (blur)="crew.extra = toNumber($event.target.innerHTML)"></td>
                      <td contenteditable="true">
                        <input type="checkbox"
                               name="crewStandard"
                               id="crewStandard"
                               class="d-hover"
                               [(ngModel)]="crew.standard"
                               (click)="setStandard(ahmData.dowChanges.crew, 'standard', i)"
                               [checked]="crew.standard">
                      </td>
                      <td contenteditable="true" [innerHTML]="crew.dow" (keydown)="inputNotLetter($event)"
                        (blur)="crew.dow = toNumber($event.target.innerHTML)"></td>
                      <td contenteditable="true" [innerHTML]="crew.doi" (keydown)="inputNotLetter($event)"
                        (blur)="crew.doi = toNumber($event.target.innerHTML)"></td>
                      <td>
                        <i class="material-icons m-0 text-red font-weight-bold pointer"
                          (click)="deleteElement(ahmData.dowChanges.crew, i)">clear</i>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="text-right">
                  <button class="btn btn-blue"
                          (click)="addItemByClass(ahmData.dowChanges.crew, dowChangesCrew)"
                          i18n="adm|Weight and balance@@adm.wb.tab.crewComposition.groupCrewComposition.button.add">
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="stabilizer-trim" role="tabpanel" aria-labelledby="stabilizer-trim-tab">
        <div class="card mb-3" *ngFor="let stabilizer of ahmData.stabilizerTrim; let indexStab = index">
          <div class="card-body">
            <div class="input-group flex-nowrap mb-3">
              <div class="input-group-prepend custom-sm">
                <span class="input-group-text font-small py-0"
                      i18n="adm|Weight and balance@@adm.wb.tab.stabilizerTrim.note">
                  Note
                </span>
              </div>
              <input type="text" class="w-100 form-control custom-sm" [(ngModel)]="stabilizer.note" />
            </div>
            <div class="row">
              <div class="col-xl-4 col-lg-6" *ngFor="let stabilizerTrim of stabilizer.values; let indexStabTo = index">
                <div class="card mb-4">
                  <div class="card-body">
                    <div class="d-flex align-items-center justify-content-between mb-3">
                      <p class="font-weight-bold text-blue h5 mb-3"
                         i18n="adm|Weight and balance@@adm.wb.tab.stabilizerTrim.settings">Settings</p>
                      <div>
                        <button type="button" class="btn btn-xs mr-2 btn-ico btn-blue" ngbTooltip="Duplicate"
                                (click)="copyItem(stabilizer.values, stabilizer.values[indexStabTo])"
                                i18n-ngbTooltip="adm|Weight and balance buttons - duplicate@@adm.wb.main.button.duplicate">
                          <i class="material-icons color-white">content_copy</i>
                        </button>
                        <button type="button" class="btn btn-xs mr-2 btn-ico btn-red" ngbTooltip="Delete"
                                (click)="deleteElement(stabilizer.values, indexStabTo)"
                                i18n-ngbTooltip="adm|Weight and balance buttons - delete@@adm.wb.main.button.delete">
                          <i class="material-icons color-white">delete</i>
                        </button>
                      </div>
                    </div>
                    <div class="input-group flex-nowrap mb-3">
                      <div class="input-group-prepend custom-sm">
                        <span class="input-group-text font-small py-0"
                              i18n="adm|Weight and balance - weight@@adm.wb.tab.stabilizerTrim.weight">
                          Weight
                        </span>
                      </div>
                      <input type="number" class="w-100 form-control custom-sm" [(ngModel)]="stabilizerTrim.weight" />
                    </div>
                    <table class="w-100 contenteditable text-center mb-3">
                      <thead>
                        <tr class="font-weight-bold">
                          <td width="35px"></td>
                          <td i18n="adm|Weight and balance@@adm.wb.tab.stabilizerTrim.table.mac">%MAC</td>
                          <td></td>
                          <td i18n="adm|Weight and balance@@adm.wb.tab.stabilizerTrim.table.stab">STAB</td>
                          <td width="35px"></td>
                        </tr>
                      </thead>
                      <tbody cdkDropList (cdkDropListDropped)="drop($event, stabilizerTrim.trims)">
                        <tr *ngFor="let trim of stabilizerTrim.trims; let i = index" cdkDrag>
                          <td><i class="material-icons pointer" cdkDragHandle>drag_handle</i></td>
                          <td [innerHTML]="trim.mac"
                              (keydown)="inputNotLetter($event)"
                              (blur)="trim.mac = toNumber($event.target.innerHTML)"
                              contenteditable="true"></td>
                          <td (click)="trim.trim = -trim.trim"
                              class="font-weight-bold h5"
                              [ngClass]="{'text-red': trim.trim < 0, 'text-green': trim.trim > 0}">
                            {{ trim.trim < 0 ? '-' : '+' }}
                          </td>
                          <td contenteditable="true"
                              [innerHTML]="trim.trim < 0 ? -trim.trim : trim.trim" (keydown)="inputNotLetter($event)"
                              (blur)="trim.trim = toNumber(trim.trim >= 0 ? $event.target.innerHTML : '-' + $event.target.innerHTML)"></td>
                          <td>
                            <i class="material-icons m-0 text-red font-weight-bold pointer"
                              (click)="deleteElement(stabilizerTrim.trims, i)">clear</i>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="text-right">
                      <button class="btn btn-blue"
                          (click)="addItemByClass(stabilizerTrim.trims, stabToTrims)"
                          i18n="adm|Weight and balance@@adm.wb.tab.stabilizerTrim.button.add">
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-6">
                <div class="card bg-gray" (click)="addItemByClass(stabilizer.values, stabToValues)">
                  <div class="card-body d-flex align-items-center justify-content-center">
                    <div class="h1">+</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mb-3 text-right">
          <button class="btn btn-blue"
              (click)="addItemByClass(ahmData.stabilizerTrim, stabilizerTrim)"
              i18n="adm|Weight and balance@@adm.wb.tab.stabilizerTrim.button.add">
            Add
          </button>
        </div>
      </div>
    </div>


    <!--<ul class="nav nav-tabs">
      <li class="nav-item">
        <a id="standard-units"
           [ngClass]="{'active': showTab('standard-units')}"
           class="nav-link"
           (click)="changeTab($event)"
           i18n="adm|Weight and balance - tab standard units@@adm.wb.main.tab.standardUnits">
          Standard units and codes
        </a>
      </li>
      <li class="nav-item">
        <a id="flight-information"
           [ngClass]="{'active': showTab('flight-information')}"
           class="nav-link"
           (click)="changeTab($event)"
           i18n="adm|Weight and balance - tab flight information@@adm.wb.main.tab.flightInformation">
          Flight information
        </a>
      </li>
      <li class="nav-item">
        <a id="centre-charts"
           [ngClass]="{'active': showTab('centre-charts')}"
           class="nav-link"
           (click)="changeTab($event)"
           i18n="adm|Weight and balance - tab centre charts@@adm.wb.main.tab.centreCharts">
          Centre of gravity charts
        </a>
      </li>
      <li class="nav-item">
        <a id="fuel"
           [ngClass]="{'active': showTab('fuel')}"
           class="nav-link"
           (click)="changeTab($event)"
           i18n="adm|Weight and balance - tab fuel@@adm.wb.main.tab.fuel">
          Fuel
        </a>
      </li>
      <li class="nav-item">
        <a id="holds-compartments"
           [ngClass]="{'active': showTab('holds-compartments')}"
           class="nav-link"
           (click)="changeTab($event)"
           i18n="adm|Weight and balance - tab holds and compartments@@adm.wb.main.tab.holdsAndCompartments">
          Holds and compartments
        </a>
      </li>
      <li class="nav-item">
        <a id="cabin-equipment"
           [ngClass]="{'active': showTab('cabin-equipment')}"
           class="nav-link"
           (click)="changeTab($event)"
           i18n="adm|Weight and balance - tab cabin and equipment@@adm.wb.main.tab.cabinAndEquipment">
          Cabin and equipment
        </a>
      </li>
      <li class="nav-item">
        <a id="crew-composition"
           [ngClass]="{'active': showTab('crew-composition')}"
           class="nav-link"
           (click)="changeTab($event)"
           i18n="adm|Weight and balance - tab crew composition@@adm.wb.main.tab.crewComposition">
          Crew composition
        </a>
      </li>
      <li class="nav-item">
        <a id="stabilizer-trim"
           [ngClass]="{'active': showTab('stabilizer-trim')}"
           class="nav-link"
           (click)="changeTab($event)"
           i18n="adm|Weight and balance - tab crew composition@@adm.wb.main.tab.stabilizer">
           Stabilizer Trim
        </a>
      </li>
    </ul>-->
    <!--<div *ngIf="showTab('standard-units')" class="bg-white py-3">
      <div class="row">
        <div class="col-6">
          <div class="card mb-4">
            <div class="card-body">
              <p class="font-weight-bold text-blue h5 mb-3"
                 i18n="adm|Weight and balance@@adm.wb.tab.standartInits.groupUnits">Units of measure</p>
              <div class="row">
                <div class="col-6">
                  <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0"
                            i18n="adm|Weight and balance - standard units weight@@adm.wb.tab.standartInits.groupUnits.weight">
                        Weight
                      </span>
                    </div>
                    <ng-select [items]="units.weight" class="w-100 custom-sm" bindLabel="englishName"
                      bindValue="englishName" name="unitsWeight" id="unitsWeight" [(ngModel)]="ahmData.units.weight">
                    </ng-select>
                  </div>
                </div>
                <div class="col-6">
                  <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0"
                            i18n="adm|Weight and balance - standard units weight@@adm.wb.tab.tab.standartInits.groupUnits.length">
                        Length
                      </span>
                    </div>
                    <ng-select [items]="units.length" class="w-100 custom-sm" bindLabel="englishName"
                      bindValue="englishName" name="unitsLength" id="unitsLength" [(ngModel)]="ahmData.units.length">
                    </ng-select>
                  </div>
                </div>
                <div class="col-6">
                  <div class="input-group flex-nowrap">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0"
                            i18n="adm|Weight and balance - standard units weight@@adm.wb.tab.standartInits.groupUnits.volume">
                        Volume
                      </span>
                    </div>
                    <ng-select [items]="units.volume" class="w-100 custom-sm" bindLabel="englishName"
                      bindValue="englishName" name="unitsVolume" id="unitsVolume" [(ngModel)]="ahmData.units.volume">
                    </ng-select>
                  </div>
                </div>
                <div class="col-6">
                  <div class="input-group flex-nowrap">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0"
                            i18n="adm|Weight and balance - standard units liquid volume@@adm.wb.tab.standartInits.groupUnits.liquidVolume">
                        Liquid volume
                      </span>
                    </div>
                    <ng-select [items]="units.liquidVolume" class="w-100 custom-sm" bindLabel="englishName"
                      bindValue="englishName" name="unitsLiquidVolume" id="unitsLiquidVolume"
                      [(ngModel)]="ahmData.units.liquidVolume">
                    </ng-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <p class="font-weight-bold text-blue h5 mb-3" i18n="adm|Weight and balance@@adm.wb.tab.standartInits.groupCrew">Crew weight</p>
              <div class="row mb-3">
                <div class="col-6">
                  <p i18n="adm|Weight and balance@@adm.wb.tab.standartInits.groupCrew.cockpit">Cockpit</p>
                  <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0"
                        i18n="adm|Weight and balance - standard units crew weight@@adm.wb.tab.standartInits.groupCrew.crewWeight">
                        Crew weight
                      </span>
                    </div>
                    <input type="number" class="w-100 form-control custom-sm" name="cockpitCrewWeight"
                      id="cockpitCrewWeight" [(ngModel)]="ahmData.standartWeights.crew.cockpit.weight" />
                  </div>
                  <div class="input-group flex-nowrap">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0"
                        i18n="adm|Weight and balance - standard units hand baggage@@adm.wb.tab.standartInits.groupCrew.handBaggage">
                        Hand baggage
                      </span>
                    </div>
                    <input type="number" class="w-100 form-control custom-sm" name="cockpitHandBaggage"
                      id="cockpitHandBaggage" [(ngModel)]="ahmData.standartWeights.crew.cockpit.handLuggage" />
                  </div>
                </div>
                <div class="col-6">
                  <p i18n="adm|Weight and balance@@adm.wb.tab.standartInits.groupCrew.cabin">Cabin</p>
                  <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0"
                        i18n="adm|Weight and balance - standard units crew weight@@adm.wb.tab.standartInits.groupCrew.crewWeight">
                        Crew weight
                      </span>
                    </div>
                    <input type="number" class="w-100 form-control custom-sm" name="cabinCrewWeight"
                      id="cabinCrewWeight" [(ngModel)]="ahmData.standartWeights.crew.cabin.weight" />
                  </div>
                  <div class="input-group flex-nowrap">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0"
                        i18n="adm|Weight and balance - standard units hand baggage@@adm.wb.tab.standartInits.groupCrew.handBaggage">
                        Hand baggage
                      </span>
                    </div>
                    <input type="number"
                           class="w-100 form-control custom-sm"
                           name="cabinHandBaggage"
                           id="cabinHandBaggage"
                           [(ngModel)]="ahmData.standartWeights.crew.cabin.handLuggage" />
                  </div>
                </div>
              </div>
              <p class="m-0"
                 i18n="adm|Weight and balance - standard units hand baggage included@@adm.wb.tab.standartInits.groupCrew.handBaggageIncluded">
                Hand baggage weight is included in the crew weights
              </p>
              <label class="mr-3">
                <input type="radio"
                       name="handLuggageIncluded"
                       id="handLuggageIncludedYes"
                       [checked]="ahmData.standartWeights.crew.handLuggageIncluded === true"
                       [value]="true"
                       [(ngModel)]="ahmData.standartWeights.crew.handLuggageIncluded">
                <span i18n="adm|Weight and balance - yes@@adm.wb.tab.standartInits.groupCrew.button.yes">Yes</span>
              </label>
              <label>
                <input type="radio"
                       name="handLuggageIncluded"
                       id="handLuggageIncludedNo"
                       [checked]="!ahmData.standartWeights.crew.handLuggageIncluded"
                       [value]="false"
                       [(ngModel)]="ahmData.standartWeights.crew.handLuggageIncluded">
                <span i18n="adm|Weight and balance - no@@adm.wb.tab.standartInits.groupCrew.button.no">No</span>
              </label>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="card mb-4">
            <div class="card-body">
              <p class="font-weight-bold text-blue h5 mb-3"
                  i18n="adm|Weight and balance - standard units constants@@adm.wb.tab.standartInits.groupConstants">
                Constants
              </p>
              <div class="row">
                <div class="col-6">
                  <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0"
                            i18n="adm|Weight and balance - standard units constants@@adm.wb.tab.standartInits.groupConstants.k">
                        K
                      </span>
                    </div>
                    <input type="number" class="w-100 form-control custom-sm" [(ngModel)]="ahmData.k" />
                  </div>
                </div>
                <div class="col-6">
                  <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0" i18n="adm|Weight and balance - standard units constants@@adm.wb.tab.standartInits.groupConstants.c">
                        C
                      </span>
                    </div>
                    <input type="number" class="w-100 form-control custom-sm" [(ngModel)]="ahmData.c" />
                  </div>
                </div>
                <div class="col-6">
                  <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0"
                        i18n="adm|Weight and balance - standard units reference arm@@adm.wb.tab.standartInits.groupConstants.referenceArm">
                        Reference arm
                      </span>
                    </div>
                    <input type="number" class="w-100 form-control custom-sm" [(ngModel)]="ahmData.referenceArm" />
                  </div>
                </div>
                <div class="col-6">
                  <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0"
                        i18n="adm|Weight and balance - weight@@adm.wb.tab.standartInits.groupConstants.macRc">
                        Mac RC
                      </span>
                    </div>
                    <input type="number" class="w-100 form-control custom-sm" [(ngModel)]="ahmData.macRc" />
                  </div>
                </div>
                <div class="col-6">
                  <div class="input-group flex-nowrap">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0"
                        i18n="adm|Weight and balance - weight@@adm.wb.tab.standartInits.groupConstants.lemacRc">
                        Lemac RC
                      </span>
                    </div>
                    <input type="number" class="w-100 form-control custom-sm" [(ngModel)]="ahmData.lemacLerc" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="showTab('flight-information')" class="bg-white py-3">
      <div class="row">
        <div class="col-6 mb-4">
          <div class="card h-100 mb-3">
            <div class="card-body">
              <p class="font-weight-bold text-blue h5 mb-3" i18n="adm|Weight and balance - table ac registration@@adm.wb.tab.flightInfo.groupAcReg.table.acRegistration">A/C registration</p>
              <table class="w-100 contenteditable">
                <tbody>
                  <tr>
                    <td i18n="adm|Weight and balance - table ac registration@@adm.wb.tab.flightInfo.groupAcReg.table.acRegistration">A/C registration</td>
                    <td>
                      <div class="w-100" *ngIf="!selectLoadAnimation.tailsFiltered; else tailsLoad">
                        <ng-select class="w-100 custom-sm"
                                  [items]="references.tailsFiltered"
                                  [virtualScroll]="true"
                                  bindLabel="tail" bindValue="id"
                                  name="ahmDataTailId" [hideSelected]="true"
                                  [searchFn]="customSelectSearch"
                                  [loading]="selectLoadAnimation.tails" (scroll)="onScrollAirlines($event, 'tails')"
                                  (scrollToEnd)="onScrollToEndAirlines('tails')" [(ngModel)]="ahmData.tailId"
                                  #ahmDataTailId="ngModel" placeholder="Enter tail for search">
                          <ng-template ng-option-tmp let-item="item">
                            IATA:&nbsp;<span
                              class="font-weight-bold">{{item.aircraftTypeIata}}</span>,&nbsp;ICAO:&nbsp;{{item.aircraftTypeIcao}}<br />
                            <small class="font-weight-bold">{{item.tail}}</small>
                          </ng-template>
                        </ng-select>
                      </div>
                      <ng-template #tailsLoad>
                        <div class="d-flex align-items-center pl-2 bg-light-gray rounded">
                          <div class="spinner-border spinner-border-sm float-left" role="status" aria-hidden="true">
                          </div>
                          <div class="float-left pl-2" i18n="adm|Weight and balance - loading@@adm.wb.main.loading">
                            Loading...
                          </div>
                        </div>
                      </ng-template>
                    </td>
                  </tr>
                  <tr>
                    <td i18n="adm|Weight and balance - flight information crew standart@@adm.wb.flightInformation.crewStandard">Crew standard</td>
                    <td class="font-weight-bold">{{ ahmData.crewStandart }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-6 mb-4">
          <div class="card">
            <div class="card-body">
              <p class="font-weight-bold text-blue h5 mb-3"
                 i18n="adm|Weight and balance@@adm.wb.tab.flightInfo.groupAircraftMaxWeight">Aircraft maximum weights</p>
              <div class="row">
                <div class="col-6 mb-3">
                  <div class="input-group flex-nowrap">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0"
                        i18n="adm|Weight and balance - flight information zero fuel weight@@adm.wb.tab.flightInfo.groupAircraftMaxWeight.zeroFuelWeight">
                        Zero fuel weight
                      </span>
                    </div>
                    <input type="number" class="w-100 form-control custom-sm" [(ngModel)]="ahmData.zeroFuelWeight"/>
                  </div>
                </div>
                <div class="col-6 mb-3">
                  <div class="input-group flex-nowrap">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0"
                        i18n="adm|Weight and balance - flight information landing weight@@adm.wb.tab.flightInfo.groupAircraftMaxWeight.landingWeight">
                        Landing weight
                      </span>
                    </div>
                    <input type="number" class="w-100 form-control custom-sm" [(ngModel)]="ahmData.landingWeight" />
                  </div>
                </div>
                <div class="col-6">
                  <div class="input-group flex-nowrap">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0"
                        i18n="adm|Weight and balance - flight information take off weight@@adm.wb.tab.flightInfo.groupAircraftMaxWeight.takeOffWeight">
                        Take off weight
                      </span>
                    </div>
                    <input type="number" class="w-100 form-control custom-sm" [(ngModel)]="ahmData.takeOffWeight" />
                  </div>
                </div>
                <div class="col-6">
                  <div class="input-group flex-nowrap">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0"
                        i18n="adm|Weight and balance - flight information ramp taxi@@adm.wb.tab.flightInfo.groupAircraftMaxWeight.rampTaxi">
                        Ramp/Taxi
                      </span>
                    </div>
                    <input type="number" class="w-100 form-control custom-sm" [(ngModel)]="ahmData.rampTaxiWeight" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="card mb-3">
            <div class="card-body">
              <p class="font-weight-bold text-blue h5 mb-3"
                i18n="adm|Weight and balance - flight information passenger weights@@adm.wb.tab.flightInfo.groupPassengerWeights">
                Passenger weights
              </p>
              <table class="w-100 contenteditable mb-3">
                <thead>
                  <tr class="font-weight-bold bg-blue color-white">
                    <td i18n="adm|Weight and balance - table season@@adm.wb.tab.flightInfo.groupPassengerWeights.table.season">Season</td>
                    <td i18n="adm|Weight and balance - table adult male@@adm.wb.tab.flightInfo.groupPassengerWeights.table.adultMale">Adult / Male</td>
                    <td i18n="adm|Weight and balance - table female@@adm.wb.tab.flightInfo.groupPassengerWeights.table.female">Female</td>
                    <td i18n="adm|Weight and balance - table children@@adm.wb.tab.flightInfo.groupPassengerWeights.table.children">Children</td>
                    <td i18n="adm|Weight and balance - table infant@@adm.wb.tab.flightInfo.groupPassengerWeights.table.infant">Infant</td>
                    <td i18n="adm|Weight and balance - table hand baggage@@adm.wb.tab.flightInfo.groupPassengerWeights.table.handBaggage">Hand baggage</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td i18n="adm|Weight and balance - table winter@@adm.wb.tab.flightInfo.groupPassengerWeights.table.winter">Winter</td>
                    <td>
                      <input type="number" [(ngModel)]="ahmData.standartWeights.passengers.winter.adult">
                    </td>
                    <td>
                      <input type="number" [(ngModel)]="ahmData.standartWeights.passengers.winter.female">
                    </td>
                    <td>
                      <input type="number" [(ngModel)]="ahmData.standartWeights.passengers.winter.child">
                    </td>
                    <td>
                      <input type="number" [(ngModel)]="ahmData.standartWeights.passengers.winter.infant">
                    </td>
                    <td>
                      <input type="number" [(ngModel)]="ahmData.standartWeights.passengers.winter.handLuggage">
                    </td>
                  </tr>
                  <tr>
                    <td i18n="adm|Weight and balance - table summer@@adm.wb.tab.flightInfo.groupPassengerWeights.table.summer">Summer</td>
                    <td>
                      <input type="number" [(ngModel)]="ahmData.standartWeights.passengers.summer.adult">
                    </td>
                    <td>
                      <input type="number" [(ngModel)]="ahmData.standartWeights.passengers.summer.female">
                    </td>
                    <td>
                      <input type="number" [(ngModel)]="ahmData.standartWeights.passengers.summer.child">
                    </td>
                    <td>
                      <input type="number" [(ngModel)]="ahmData.standartWeights.passengers.summer.infant">
                    </td>
                    <td>
                      <input type="number" [(ngModel)]="ahmData.standartWeights.passengers.summer.handLuggage">
                    </td>
                  </tr>
                </tbody>
              </table>
              <p class="m-0"
                  i18n="adm|Weight and balance - light information hand baggage weight is included@@adm.wb.tab.flightInfo.groupPassengerWeights.handBaggageWeightIncluded">
                Hand baggage weight is included in the passenger weights
              </p>
              <label class="mr-3">
                <input type="radio"
                       name="baggageIncluded"
                       id="baggageIncludedYes"
                       [checked]="ahmData.standartWeights.passengers.handLuggageIncluded === true"
                       [value]="true"
                       [(ngModel)]="ahmData.standartWeights.passengers.handLuggageIncluded">
                <span i18n="adm|Weight and balance - yes@@adm.wb.tab.flightInfo.groupPassengerWeights.button.yes">Yes</span>
              </label>
              <label>
                <input type="radio" name="baggageIncluded" id="baggageIncludedNo"
                  [checked]="ahmData.standartWeights.passengers.handLuggageIncluded === false" [value]="false"
                  [(ngModel)]="ahmData.standartWeights.passengers.handLuggageIncluded">
                <span i18n="adm|Weight and balance - no@@adm.wb.tab.flightInfo.groupPassengerWeights.button.no">No</span>
              </label>
              <div class="d-flex align-items-center mt-3">
                <p class="mb-0 mr-3 text-nowrap"
                  i18n="adm|Weight and balance - flight information passenger weight includes@@adm.wb.tab.flightInfo.groupPassengerWeights.passengerWeightIncludes">
                  Passenger weight includes carry-on weight
                </p>
                <div class="input-group w-auto">
                  <input type="number" class="form-control custom-sm" name="includesCarryOn" id="includesCarryOn">
                  <div class="input-group-append custom-sm">
                    <span class="input-group-text font-small py-0"
                          i18n="adm|Weight and balance - kg@@adm.wb.tab.flightInfo.groupPassengerWeights.kg">kg</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="showTab('centre-charts')" class="bg-white py-3">
      <div class="card mb-3" *ngFor="let centreOfGravityChart of ahmData.centreOfGravityChart; let i_cgc = index">
        <div class="card-body">
          <p class="font-weight-bold text-blue h5 mb-3 pb-2 border-bottom"
              i18n="adm|Weight and balance - fuel weights@@adm.wb.tab.centreOfGravity.fuelWeights">Fuel weights</p>
          <div class="row">
            <div class="col-4 border-right">
              <div class="input-group flex-nowrap">
                <div class="input-group-prepend custom-sm">
                  <span class="input-group-text font-small py-0"
                    i18n="adm|Weight and balance@@adm.wb.tab.centreOfGravity.note">
                    Note
                  </span>
                </div>
                <input type="text" class="w-100 form-control custom-sm" [(ngModel)]="centreOfGravityChart.note" />
              </div>
              <p class="font-weight-bold my-3"
                i18n="adm|Weight and balance - rules of use@@adm.wb.tab.centreOfGravity.rulesOfUse">
                Rules of use
              </p>
              <table class="w-100 contenteditable mb-3">
                <thead>
                  <tr class="font-weight-bold bg-blue color-white">
                    <td i18n="adm|Weight and balance - table type@@adm.wb.tab.centreOfGravityChart.rules.table.type" width="40%" class="p-2">Type</td>
                    <td i18n="adm|Weight and balance - table from@@adm.wb.tab.centreOfGravityChart.rules.table.from" class="p-2">From</td>
                    <td i18n="adm|Weight and balance - table to@@adm.wb.tab.centreOfGravityChart.rules.table.to" class="p-2">To</td>
                    <td width="35px"></td>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let condition of centreOfGravityChart.conditions; let i_condition = index">
                    <td>
                      <ng-select [items]="chartTypes" class="w-100 custom-sm bg-white" bindLabel="nameEnglish"
                        bindValue="id" name="chartType" id="chartType" [(ngModel)]="condition.type">
                      </ng-select>
                    </td>
                    <td>
                      <input type="number" class="w-100 form-control custom-sm" [(ngModel)]="condition.from" />
                    </td>
                    <td>
                      <input type="number" class="w-100 form-control custom-sm" [(ngModel)]="condition.to" />
                    </td>
                    <td>
                      <i class="material-icons m-0 text-red font-weight-bold pointer" ngbTooltip="Delete"
                      (click)="deleteElement(centreOfGravityChart.conditions, i_condition)"
                      i18n-ngbTooltip="adm|Weight and balance buttons - delete@@adm.wb.main.button.delete">clear</i>
                    </td>
                  </tr>
                </tbody>
              </table>
              <button class="btn btn-blue float-right"
                      (click)="addItemByClass(centreOfGravityChart.conditions, centreOfGravityChartCondition)"
                      i18n="adm|Weight and balance - button add rule@@adm.wb.tab.fuel.groupFuel.button.addRule">
                Add rule
              </button>
              <div id="gravityCentreBlock_{{i_cgc}}" class="mt-3" #gravityCentreBlock_{{i_cgc}}></div>
            </div>
            <div class="col-8">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                  <a class="nav-link active" id="zeroFuel-tab" data-toggle="tab"
                    i18n="adm|Weight and balance - zero fuel@@adm.wb.tab.centreOfGravity.zeroFuel"
                    href="#zeroFuel_{{i_cgc}}" role="tab">Zero fuel</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="takeOff-tab" data-toggle="tab"
                    i18n="adm|Weight and balance - take off@@adm.wb.tab.centreOfGravity.takeOff"
                    href="#takeOff_{{i_cgc}}" role="tab">Take off</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="landing-tab" data-toggle="tab"
                    i18n="adm|Weight and balance - landing@@adm.wb.tab.centreOfGravity.landing"
                    href="#landing_{{i_cgc}}" role="tab">Landing</a>
                </li>
              </ul>
              <div class="tab-content pt-3" id="myTabContent">
                <div class="tab-pane fade show active" id="zeroFuel_{{i_cgc}}" role="tabpanel" aria-labelledby="zeroFuel-tab">
                  <div class="row">
                    <div class="col-6">
                      <div class="d-flex justify-content-between align-items-center mb-3">
                        <p class="mb-0 font-weight-bold"
                            i18n="adm|Weight and balance@@adm.wb.tab.centreOfGravity.groupZeroFuel.fwd">FWD</p>
                        <button class="btn-ico btn btn-xs btn-light-gray"
                          (click)="addItemByClass(centreOfGravityChart.fwd.zeroFuel, itemEl)">
                          +
                        </button>
                      </div>
                      <table class="w-100 contenteditable text-center mb-3">
                        <thead>
                          <tr class="font-weight-bold">
                            <td width="35px"></td>
                            <td i18n="adm|Weight and balance - table weight@@adm.wb.table.weight">Weight</td>
                            <td></td>
                            <td i18n="adm|Weight and balance - table index@@adm.wb.table.index">Index</td>
                            <td width="35px"></td>
                          </tr>
                        </thead>
                        <tbody cdkDropList (cdkDropListDropped)="drop($event, centreOfGravityChart.fwd.zeroFuel)">
                          <tr *ngFor="let element of centreOfGravityChart.fwd.zeroFuel; let i = index" cdkDrag>
                            <td><i class="material-icons pointer" cdkDragHandle>drag_handle</i></td>
                            <td contenteditable="true" [innerHTML]="element.weight" (keydown)="inputNotLetter($event)"
                              (blur)="element.weight = toNumber($event.target.innerHTML)"></td>
                            <td (click)="element.index = -element.index"
                                class="font-weight-bold h5"
                                [ngClass]="{'text-red': element.index < 0, 'text-green': element.index > 0}">
                              {{ element.index < 0 ? '-' : '+' }}
                            </td>
                            <td contenteditable="true"
                                [innerHTML]="element.index < 0 ? -element.index : element.index"
                                (keydown)="inputNotLetter($event)"
                                (blur)="element.index = toNumber(element.index >= 0 ? $event.target.innerHTML : '-' + $event.target.innerHTML)">
                            </td>
                            <td>
                              <i class="material-icons m-0 text-red font-weight-bold pointer"
                                (click)="deleteElement(centreOfGravityChart.fwd.zeroFuel, i)">clear</i>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="col-6">
                      <div class="d-flex justify-content-between align-items-center mb-3">
                        <p class="mb-0 font-weight-bold"
                           i18n="adm|Weight and balance@@adm.wb.tab.centreOfGravity.groupZeroFuel.aft">AFT</p>
                        <button class="btn-ico btn btn-xs btn-light-gray"
                          (click)="addItemByClass(centreOfGravityChart.aft.zeroFuel, itemEl)">
                          +
                        </button>
                      </div>
                      <table class="w-100 contenteditable text-center mb-3">
                        <thead>
                          <tr class="font-weight-bold">
                            <td width="35px"></td>
                            <td i18n="adm|Weight and balance - table weight@@adm.wb.table.weight">Weight</td>
                            <td></td>
                            <td i18n="adm|Weight and balance - table index@@adm.wb.table.index">Index</td>
                            <td width="35px"></td>
                          </tr>
                        </thead>
                        <tbody cdkDropList (cdkDropListDropped)="drop($event, centreOfGravityChart.aft.zeroFuel)">
                          <tr *ngFor="let element of centreOfGravityChart.aft.zeroFuel; let i = index" cdkDrag>
                            <td><i class="material-icons pointer" cdkDragHandle>drag_handle</i></td>
                            <td contenteditable="true" [innerHTML]="element.weight" (keydown)="inputNotLetter($event)"
                              (blur)="element.weight = toNumber($event.target.innerHTML)"></td>
                            <td (click)="element.index = -element.index"
                                class="font-weight-bold h5"
                                [ngClass]="{'text-red': element.index < 0, 'text-green': element.index > 0}">
                              {{ element.index < 0 ? '-' : '+' }}
                            </td>
                            <td contenteditable="true"
                                [innerHTML]="element.index < 0 ? -element.index : element.index"
                                (keydown)="inputNotLetter($event)"
                                (blur)="element.index = toNumber(element.index >= 0 ? $event.target.innerHTML : '-' + $event.target.innerHTML)">
                            </td>
                            <td>
                              <i class="material-icons m-0 text-red font-weight-bold pointer"
                                (click)="deleteElement(centreOfGravityChart.aft.zeroFuel, i)">clear</i>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="takeOff_{{i_cgc}}" role="tabpanel" aria-labelledby="takeOff-tab">
                  <div class="row">
                    <div class="col-6">
                      <div class="d-flex justify-content-between align-items-center mb-3">
                        <p class="mb-0 font-weight-bold"
                           i18n="adm|Weight and balance - charts take off@@adm.wb.tab.centreOfGravity.fwd">FWD</p>
                        <button class="btn-ico btn btn-xs btn-light-gray"
                          (click)="addItemByClass(centreOfGravityChart.fwd.takeOff, itemEl)">
                          +
                        </button>
                      </div>
                      <table class="w-100 contenteditable text-center mb-3">
                        <thead>
                          <tr class="font-weight-bold">
                            <td width="35px"></td>
                            <td i18n="adm|Weight and balance - table weight@@adm.wb.table.weight">Weight</td>
                            <td></td>
                            <td i18n="adm|Weight and balance - table index@@adm.wb.table.index">Index</td>
                            <td width="35px"></td>
                          </tr>
                        </thead>
                        <tbody cdkDropList (cdkDropListDropped)="drop($event, centreOfGravityChart.fwd.takeOff)">
                          <tr *ngFor="let element of centreOfGravityChart.fwd.takeOff; let i = index" cdkDrag>
                            <td><i class="material-icons pointer" cdkDragHandle>drag_handle</i></td>
                            <td contenteditable="true" [innerHTML]="element.weight" (keydown)="inputNotLetter($event)"
                              (blur)="element.weight = toNumber($event.target.innerHTML)"></td>
                            <td (click)="element.index = -element.index"
                                class="font-weight-bold h5"
                                [ngClass]="{'text-red': element.index < 0, 'text-green': element.index > 0}">
                              {{ element.index < 0 ? '-' : '+' }}
                            </td>
                            <td contenteditable="true"
                                [innerHTML]="element.index < 0 ? -element.index : element.index"
                                (keydown)="inputNotLetter($event)"
                                (blur)="element.index = toNumber(element.index >= 0 ? $event.target.innerHTML : '-' + $event.target.innerHTML)">
                            </td>
                            <td>
                              <i class="material-icons m-0 text-red font-weight-bold pointer"
                                (click)="deleteElement(centreOfGravityChart.fwd.takeOff, i)">clear</i>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="col-6">
                      <div class="d-flex justify-content-between align-items-center mb-3">
                        <p class="mb-0 font-weight-bold"
                          i18n="adm|Weight and balance - charts take off@@adm.wb.tab.centreOfGravity.aft">AFT</p>
                        <button class="btn-ico btn btn-xs btn-light-gray"
                          (click)="addItemByClass(centreOfGravityChart.aft.takeOff, itemEl)">
                          +
                        </button>
                      </div>
                      <table class="w-100 contenteditable text-center mb-3">
                        <thead>
                          <tr class="font-weight-bold">
                            <td width="35px"></td>
                            <td i18n="adm|Weight and balance - table weight@@adm.wb.table.weight">Weight</td>
                            <td></td>
                            <td i18n="adm|Weight and balance - table index@@adm.wb.table.index">Index</td>
                            <td width="35px"></td>
                          </tr>
                        </thead>
                        <tbody cdkDropList (cdkDropListDropped)="drop($event, centreOfGravityChart.aft.takeOff)">
                          <tr *ngFor="let element of centreOfGravityChart.aft.takeOff; let i = index" cdkDrag>
                            <td><i class="material-icons pointer" cdkDragHandle>drag_handle</i></td>
                            <td contenteditable="true" [innerHTML]="element.weight" (keydown)="inputNotLetter($event)"
                              (blur)="element.weight = toNumber($event.target.innerHTML)"></td>
                            <td (click)="element.index = -element.index"
                                class="font-weight-bold h5"
                                [ngClass]="{'text-red': element.index < 0, 'text-green': element.index > 0}">
                              {{ element.index < 0 ? '-' : '+' }}
                            </td>
                            <td contenteditable="true"
                                [innerHTML]="element.index < 0 ? -element.index : element.index"
                                (keydown)="inputNotLetter($event)"
                                (blur)="element.index = toNumber(element.index >= 0 ? $event.target.innerHTML : '-' + $event.target.innerHTML)">
                            </td>
                            <td>
                              <i class="material-icons m-0 text-red font-weight-bold pointer"
                                (click)="deleteElement(centreOfGravityChart.aft.takeOff, i)">clear</i>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="landing_{{i_cgc}}" role="tabpanel" aria-labelledby="landing-tab">
                  <div class="row">
                    <div class="col-6">
                      <div class="d-flex justify-content-between align-items-center mb-3">
                        <p class="mb-0 font-weight-bold"
                           i18n="adm|Weight and balance - charts landing@@adm.wb.tab.centreOfGravity.groupLanding.fwd">FWD</p>
                        <button class="btn-ico btn btn-xs btn-light-gray"
                          (click)="addItemByClass(centreOfGravityChart.fwd.landing, itemEl)">
                          +
                        </button>
                      </div>
                      <table class="w-100 contenteditable text-center mb-3">
                        <thead>
                          <tr class="font-weight-bold">
                            <td width="35px"></td>
                            <td i18n="adm|Weight and balance - table weight@@adm.wb.table.weight">Weight</td>
                            <td></td>
                            <td i18n="adm|Weight and balance - table index@@adm.wb.table.index">Index</td>
                            <td width="35px"></td>
                          </tr>
                        </thead>
                        <tbody cdkDropList (cdkDropListDropped)="drop($event, centreOfGravityChart.fwd.landing)">
                          <tr *ngFor="let element of centreOfGravityChart.fwd.landing; let i = index" cdkDrag>
                            <td><i class="material-icons pointer" cdkDragHandle>drag_handle</i></td>
                            <td contenteditable="true" [innerHTML]="element.weight" (keydown)="inputNotLetter($event)"
                              (blur)="element.weight = toNumber($event.target.innerHTML)"></td>
                            <td (click)="element.index = -element.index"
                                class="font-weight-bold h5"
                                [ngClass]="{'text-red': element.index < 0, 'text-green': element.index > 0}">
                              {{ element.index < 0 ? '-' : '+' }}
                            </td>
                            <td contenteditable="true"
                                [innerHTML]="element.index < 0 ? -element.index : element.index"
                                (keydown)="inputNotLetter($event)"
                                (blur)="element.index = toNumber(element.index >= 0 ? $event.target.innerHTML : '-' + $event.target.innerHTML)">
                            </td>
                            <td>
                              <i class="material-icons m-0 text-red font-weight-bold pointer"
                                (click)="deleteElement(centreOfGravityChart.fwd.landing, i)">clear</i>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="col-6">
                      <div class="d-flex justify-content-between align-items-center mb-3">
                        <p class="mb-0 font-weight-bold"
                           i18n="adm|Weight and balance - charts landing@@adm.wb.tab.centreOfGravity.groupLanding.aft">AFT</p>
                        <button class="btn-ico btn btn-xs btn-light-gray"
                          (click)="addItemByClass(centreOfGravityChart.aft.landing, itemEl)">
                          +
                        </button>
                      </div>
                      <table class="w-100 contenteditable text-center mb-3">
                        <thead>
                          <tr class="font-weight-bold">
                            <td width="35px"></td>
                            <td i18n="adm|Weight and balance - table weight@@adm.wb.table.weight">Weight</td>
                            <td></td>
                            <td i18n="adm|Weight and balance - table index@@adm.wb.table.index">Index</td>
                            <td width="35px"></td>
                          </tr>
                        </thead>
                        <tbody cdkDropList (cdkDropListDropped)="drop($event, centreOfGravityChart.aft.landing)">
                          <tr *ngFor="let element of centreOfGravityChart.aft.landing; let i = index" cdkDrag>
                            <td><i class="material-icons pointer" cdkDragHandle>drag_handle</i></td>
                            <td contenteditable="true" [innerHTML]="element.weight" (keydown)="inputNotLetter($event)"
                              (blur)="element.weight = toNumber($event.target.innerHTML)"></td>
                            <td (click)="element.index = -element.index"
                                class="font-weight-bold h5"
                                [ngClass]="{'text-red': element.index < 0, 'text-green': element.index > 0}">
                              {{ element.index < 0 ? '-' : '+' }}
                            </td>
                            <td contenteditable="true"
                                [innerHTML]="element.index < 0 ? -element.index : element.index"
                                (keydown)="inputNotLetter($event)"
                                (blur)="element.index = toNumber(element.index >= 0 ? $event.target.innerHTML : '-' + $event.target.innerHTML)">
                            </td>
                            <td>
                              <i class="material-icons m-0 text-red font-weight-bold pointer"
                                (click)="deleteElement(centreOfGravityChart.aft.landing, i)">clear</i>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button type="button" class="btn ml-3 btn-ico btn-red float-right"
            (click)="deleteElement(ahmData.centreOfGravityChart, i_cgc)"
            i18n="adm|Weight and balance buttons - delete@@adm.wb.main.button.delete">
            Delete
          </button>
        </div>
      </div>
      <button class="btn btn-blue float-right" (click)="addItemByClass(ahmData.centreOfGravityChart, centreOfGravityChart)"
              i18n="adm|Weight and balance - button add@@adm.wb.tab.fuel.groupFuel.button.add">
        Add
      </button>
    </div>
    <div *ngIf="showTab('fuel')" class="bg-white py-3">
      <div class="input-group flex-nowrap mb-3">
        <div class="input-group-prepend custom-sm">
          <span class="input-group-text font-small py-0"
                i18n="adm|Weight and balance - fuel taxi fuel weight@@adm.wb.tab.fuel.taxiFuelWeight">
            Taxi fuel weight
          </span>
        </div>
        <input type="number" class="w-100 form-control custom-sm" [(ngModel)]="ahmData.taxiFuel" />
      </div>
      <div class="row">
        <div class="col-lg-6 mb-3" *ngFor="let effectOfFuel of ahmData.effectOfFuel; let indexFuel = index">
          <div class="card h-100">
            <div class="card-body">
              <div class="d-flex align-items-center justify-content-between mb-2">
                <div>
                  <p class="font-weight-bold text-blue h5 mb-2"
                    i18n="adm|Weight and balance - fuel fuel@@adm.wb.tab.fuel.groupFuel">Fuel</p>
                  <label>
                    <input type="checkbox"
                          [(ngModel)]="effectOfFuel.default"
                          (click)="setStandard(ahmData.effectOfFuel, 'default', indexFuel)"
                          [checked]="effectOfFuel.default">&nbsp;<span i18n="adm|Weight and balance - fuel@@adm.wb.tab.fuel.groupFuel.standard">Standard</span>
                  </label>
                </div>
                <div>
                  <button type="button" class="btn btn-xs mr-2 btn-ico btn-blue" ngbTooltip="Duplicate"
                    (click)="copyItem(ahmData.effectOfFuel, ahmData.effectOfFuel[indexFuel])"
                    i18n-ngbTooltip="adm|Weight and balance buttons - duplicate@@adm.wb.main.button.duplicate">
                    <i class="material-icons color-white">content_copy</i>
                  </button>
                  <button type="button" class="btn btn-xs mr-2 btn-ico btn-red" ngbTooltip="Delete"
                    (click)="deleteElement(ahmData.effectOfFuel, indexFuel)"
                    i18n-ngbTooltip="adm|Weight and balance buttons - delete@@adm.wb.main.button.delete">
                    <i class="material-icons color-white">delete</i>
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4 pr-0">
                  <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0" i18n="adm|Weight and balance - fuel fuel density@@adm.wb.tab.fuel.groupFuel.fuelDensity">
                        Density
                      </span>
                    </div>
                    <input type="number" class="w-100 form-control custom-sm" [(ngModel)]="effectOfFuel.density" />
                  </div>
                </div>
                <div class="col-md-4 pr-0">
                  <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0" i18n="adm|Weight and balance - fuel fuel density min@@adm.wb.tab.fuel.groupFuel.fuelDensityMin">
                        Density min
                      </span>
                    </div>
                    <input type="number" class="w-100 form-control custom-sm" />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="input-group flex-nowrap">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0" i18n="adm|Weight and balance - fuel fuel density max@@adm.wb.tab.fuel.groupFuel.fuelDensityMax">
                        Density max
                      </span>
                    </div>
                    <input type="number" class="w-100 form-control custom-sm"/>
                  </div>
                </div>
              </div>
              <table class="w-100 contenteditable text-center mb-3">
                <thead>
                  <tr class="font-weight-bold">
                    <td width="35px"></td>
                    <td i18n="adm|Weight and balance - table fuel weight@@adm.wb.tab.fuel.groupFuel.table.fuelWeight">Fuel Weight</td>
                    <td></td>
                    <td i18n="adm|Weight and balance - table index@@adm.wb.tab.fuel.groupFuel.table.index">Index</td>
                    <td width="35px"></td>
                  </tr>
                </thead>
                <tbody cdkDropList (cdkDropListDropped)="drop($event, effectOfFuel.balance)">
                  <tr *ngFor="let element of effectOfFuel.balance; let i = index" cdkDrag>
                    <td><i class="material-icons pointer" cdkDragHandle>drag_handle</i></td>
                    <td contenteditable="true" [innerHTML]="element.weight" (keydown)="inputNotLetter($event)"
                      (blur)="element.weight = toNumber($event.target.innerHTML)"></td>
                    <td (click)="element.index = -element.index"
                        class="font-weight-bold h5"
                        [ngClass]="{'text-red': element.index < 0, 'text-green': element.index > 0}">
                      {{ element.index < 0 ? '-' : '+' }}
                    </td>
                    <td contenteditable="true" [innerHTML]="element.index < 0 ? -element.index : element.index"
                      (keydown)="inputNotLetter($event)" (blur)="element.index = toNumber(element.index >= 0 ? $event.target.innerHTML : '-' + $event.target.innerHTML)">
                    </td>
                    <td>
                      <i class="material-icons m-0 text-red font-weight-bold pointer"
                        (click)="deleteElement(effectOfFuel.balance, i)">clear</i>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="text-right">
                <button class="btn btn-blue" (click)="addItemByClass(effectOfFuel.balance, itemEl)"
                        i18n="adm|Weight and balance - button add@@adm.wb.tab.fuel.groupFuel.button.add">
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 mb-3">
          <div class="card bg-gray" (click)="addItem(ahmData.effectOfFuel, {density: null, default:false, balance: []})">
            <div class="card-body d-flex align-items-center justify-content-center">
              <div class="h1">+</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="showTab('holds-compartments')" class="bg-white py-3">
      <div class="row mb-4">
        <div class="col-md-6">
          <div class="card h-100">
            <div class="card-body">
              <p class="font-weight-bold text-blue h5 mb-3" i18n="adm|Weight and balance@@adm.wb.tab.holdsAndCompartments.groupFwd">FWD</p>
              <table class="w-100 contenteditable text-center mb-3">
                <thead>
                  <tr class="font-weight-bold">
                    <td width="35px"></td>
                    <td i18n="adm|Weight and balance - table compartment@@adm.wb.tab.holdsAndCompartments.groupFwd.table.compartment">Compartment</td>
                    <td i18n="adm|Weight and balance - table section@@adm.wb.tab.holdsAndCompartments.groupFwd.table.section">Bay / Section</td>
                    <td i18n="adm|Weight and balance - table type@@adm.wb.tab.holdsAndCompartments.groupFwd.table.type">Type</td>
                    <td i18n="adm|Weight and balance - table max weight@@adm.wb.tab.holdsAndCompartments.groupFwd.table.maxWeight">Max weight</td>
                    <td i18n="adm|Weight and balance - table volume@@adm.wb.tab.holdsAndCompartments.groupFwd.table.volume">Volume</td>
                    <td></td>
                    <td i18n="adm|Weight and balance - table index@@adm.wb.tab.holdsAndCompartments.groupFwd.table.index">Index</td>
                    <td width="35px"></td>
                  </tr>
                </thead>
                <tbody cdkDropList (cdkDropListDropped)="drop($event, ahmData.holdsAndCompartments.fwd)">
                  <tr *ngFor="let element of ahmData.holdsAndCompartments.fwd; let i = index" cdkDrag>
                    <td><i class="material-icons pointer" cdkDragHandle>drag_handle</i></td>
                    <td contenteditable="true"[innerHTML]="element.group"
                        (blur)="element.group = $event.target.innerHTML"></td>
                    <td contenteditable="true" [innerHTML]="element.name"
                        (blur)="element.name = $event.target.innerHTML"></td>
                    <td>
                      <select id="holdsAndCompartments_hwd_{{i}}" class="form-control form-control-sm mr-2 py-0"
                        name="holdsAndCompartments_hwd_{{i}}" [value]="element.type"
                        (change)="element.type = $event.target.value">
                        <option value="bulk">BULK</option>
                        <option value="uld">ULD</option>
                      </select>
                    </td>
                    <td contenteditable="true" [innerHTML]="element.maxWeight" (keydown)="inputNotLetter($event)"
                      (blur)="element.maxWeight = toNumber($event.target.innerHTML)"></td>
                    <td contenteditable="true" [innerHTML]="element.volume" (keydown)="inputNotLetter($event)"
                      (blur)="element.volume = toNumber($event.target.innerHTML)"></td>
                    <td (click)="element.index = -element.index"
                        class="font-weight-bold h5"
                        [ngClass]="{'text-red': element.index < 0, 'text-green': element.index > 0}">
                      {{ element.index < 0 ? '-' : '+' }}
                    </td>
                    <td contenteditable="true" [innerHTML]="element.index < 0 ? -element.index : element.index"
                      (keydown)="inputNotLetter($event)" (blur)="element.index = toNumber(element.index >= 0 ? $event.target.innerHTML : '-' + $event.target.innerHTML)">
                    </td>
                    <td>
                      <i class="material-icons m-0 text-red font-weight-bold pointer"
                        (click)="deleteElement(ahmData.holdsAndCompartments.fwd, i)">clear</i>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="text-right">
                <button class="btn btn-blue"
                        (click)="addItemByClass(ahmData.holdsAndCompartments.fwd, holdsAndCompartments)"
                        i18n="adm|Weight and balance - button add@@adm.wb.tab.holdsAndCompartments.groupFwd.button.add">
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card h-100">
            <div class="card-body">
              <p class="font-weight-bold text-blue h5 mb-3" i18n="adm|Weight and balance@@adm.wb.tab.holdsAndCompartments.groupAft">AFT</p>
              <table class="w-100 contenteditable text-center mb-3">
                <thead>
                  <tr class="font-weight-bold">
                    <td width="35px"></td>
                    <td i18n="adm|Weight and balance - table compartment@@adm.wb.tab.holdsAndCompartments.groupAft.table.compartment">Compartment</td>
                    <td i18n="adm|Weight and balance - table section@@adm.wb.tab.holdsAndCompartments.groupFwd.table.section">Bay / Section</td>
                    <td i18n="adm|Weight and balance - table type@@adm.wb.tab.holdsAndCompartments.groupAft.table.type">Type</td>
                    <td i18n="adm|Weight and balance - table max weight@@adm.wb.tab.holdsAndCompartments.groupAft.table.maxWeight">Max weight</td>
                    <td i18n="adm|Weight and balance - table volume@@adm.wb.tab.holdsAndCompartments.groupAft.table.volume">Volume</td>
                    <td></td>
                    <td i18n="adm|Weight and balance - table index@@adm.wb.tab.holdsAndCompartments.groupAft.table.index">Index</td>
                    <td width="35px"></td>
                  </tr>
                </thead>
                <tbody cdkDropList (cdkDropListDropped)="drop($event, ahmData.holdsAndCompartments.aft)">
                  <tr *ngFor="let element of ahmData.holdsAndCompartments.aft; let i = index" cdkDrag>
                    <td><i class="material-icons pointer" cdkDragHandle>drag_handle</i></td>
                    <td contenteditable="true" [innerHTML]="element.group"
                      (blur)="element.group = $event.target.innerHTML"></td>
                    <td contenteditable="true" [innerHTML]="element.name"
                      (blur)="element.name = $event.target.innerHTML"></td>
                    <td>
                      <select id="holdsAndCompartments_hwd_{{i}}" class="form-control form-control-sm mr-2 py-0"
                        name="holdsAndCompartments_hwd_{{i}}" [value]="element.type"
                        (change)="element.type = $event.target.value">
                        <option value="bulk">BULK</option>
                        <option value="uld">ULD</option>
                      </select>
                    </td>
                    <td contenteditable="true" [innerHTML]="element.maxWeight" (keydown)="inputNotLetter($event)"
                      (blur)="element.maxWeight = toNumber($event.target.innerHTML)"></td>
                    <td contenteditable="true" [innerHTML]="element.volume" (keydown)="inputNotLetter($event)"
                      (blur)="element.volume = toNumber($event.target.innerHTML)"></td>
                    <td (click)="element.index = -element.index"
                        class="font-weight-bold h5"
                        [ngClass]="{'text-red': element.index < 0, 'text-green': element.index > 0}">
                      {{ element.index < 0 ? '-' : '+' }}
                    </td>
                    <td contenteditable="true" [innerHTML]="element.index < 0 ? -element.index : element.index"
                      (keydown)="inputNotLetter($event)" (blur)="element.index = toNumber(element.index >= 0 ? $event.target.innerHTML : '-' + $event.target.innerHTML)">
                    </td>
                    <td>
                      <i class="material-icons m-0 text-red font-weight-bold pointer"
                        (click)="deleteElement(ahmData.holdsAndCompartments.aft, i)">clear</i>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="text-right">
                <button class="btn btn-blue"
                        (click)="addItemByClass(ahmData.holdsAndCompartments.aft, holdsAndCompartments)"
                        i18n="adm|Weight and balance - button add@@adm.wb.tab.holdsAndCompartments.groupAft.button.add">
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <p class="font-weight-bold text-blue h5 mb-3" i18n="adm|Weight and balance@@adm.wb.tab.holdsAndCompartments.uldTypes">ULD types</p>
          <table class="w-100 contenteditable text-center mb-3">
            <thead>
              <tr class="font-weight-bold">
                <td width="35px"></td>
                <td i18n="adm|Weight and balance - table name@@adm.wb.tab.holdsAndCompartments.uldTypes.table.name">Name</td>
                <td i18n="adm|Weight and balance - table weight@@adm.wb.tab.holdsAndCompartments.uldTypes.table.weight">Weight</td>
                <td i18n="adm|Weight and balance - table max weight@@adm.wb.tab.holdsAndCompartments.uldTypes.table.maxWeight">Max weight</td>
                <td i18n="adm|Weight and balance - table max volume@@adm.wb.tab.holdsAndCompartments.uldTypes.table.maxVolume">Max volume</td>
                <td width="35px"></td>
              </tr>
            </thead>
            <tbody cdkDropList (cdkDropListDropped)="drop($event, ahmData.uldTypes)">
              <tr *ngFor="let element of ahmData.uldTypes; let i = index" cdkDrag>
                <td><i class="material-icons pointer" cdkDragHandle>drag_handle</i></td>
                <td contenteditable="true" [innerHTML]="element.name"
                    (blur)="element.name = $event.target.innerHTML"></td>
                <td contenteditable="true" [innerHTML]="element.weight" (keydown)="inputNotLetter($event)"
                  (blur)="element.weight = toNumber($event.target.innerHTML)"></td>
                <td contenteditable="true" [innerHTML]="element.maxWeight" (keydown)="inputNotLetter($event)"
                  (blur)="element.maxWeight = toNumber($event.target.innerHTML)"></td>
                <td contenteditable="true" [innerHTML]="element.maxVolume" (keydown)="inputNotLetter($event)"
                  (blur)="element.maxVolume = toNumber($event.target.innerHTML)"></td>
                <td>
                  <i class="material-icons m-0 text-red font-weight-bold pointer"
                    (click)="deleteElement(ahmData.uldTypes, i)">clear</i>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="text-right">
            <button class="btn btn-blue"
                    (click)="addItemByClass(ahmData.uldTypes, uldTypes)"
                    i18n="adm|Weight and balance - button add@@adm.wb.tab.holdsAndCompartments.uldTypes.button.add">
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="showTab('cabin-equipment')" class="bg-white py-3">
      <div class="row mb-3">
        <div class="col-md-6 mb-3" *ngFor="let configuration of ahmData.configurations; let indexConfiguration = index">
          <div class="card h-100">
            <div class="card-body">
              <div class="d-flex align-items-center justify-content-between mb-3">
                <p class="font-weight-bold text-blue h5 mb-3"
                   i18n="adm|Weight and balance - button add@@adm.wb.tab.cabinAndEquipment.groupCabinArea">Cabin area information</p>
                <div>
                  <button type="button" class="btn btn-xs mr-2 btn-ico btn-blue" ngbTooltip="Duplicate"
                    (click)="copyItem(ahmData.configurations, ahmData.configurations[indexConfiguration])"
                    i18n-ngbTooltip="adm|Weight and balance buttons - duplicate@@adm.wb.main.button.duplicate">
                    <i class="material-icons color-white">content_copy</i>
                  </button>
                  <button type="button" class="btn btn-xs mr-2 btn-ico btn-red" ngbTooltip="Delete"
                    (click)="deleteElement(ahmData.configurations, indexConfiguration)"
                    i18n-ngbTooltip="adm|Weight and balance buttons - delete@@adm.wb.main.button.delete">
                    <i class="material-icons color-white">delete</i>
                  </button>
                </div>
              </div>
              <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend custom-sm">
                  <span class="input-group-text font-small py-0"
                        i18n="adm|Weight and balance - weight@@adm.wb.tab.cabinAndEquipment.groupCabinArea.name">
                    Name
                  </span>
                </div>
                <input type="text" class="w-100 form-control custom-sm" [(ngModel)]="configuration.name" />
              </div>
              <table class="w-100 contenteditable text-center mb-3">
                <thead>
                  <tr class="font-weight-bold">
                    <td width="35px"></td>
                    <td i18n="adm|Weight and balance@@adm.wb.tab.cabinAndEquipment.groupCabinArea.table.section">Section</td>
                    <td i18n="adm|Weight and balance@@adm.wb.tab.cabinAndEquipment.groupCabinArea.table.rowFrom">Row from</td>
                    <td i18n="adm|Weight and balance@@adm.wb.tab.cabinAndEquipment.groupCabinArea.table.rowTo">Row to</td>
                    <td i18n="adm|Weight and balance@@adm.wb.tab.cabinAndEquipment.groupCabinArea.table.maxSeats">Max seats</td>
                    <td></td>
                    <td i18n="adm|Weight and balance@@adm.wb.tab.cabinAndEquipment.groupCabinArea.table.indexPerWeightUnit">Index per weight unit</td>
                    <td width="35px"></td>
                  </tr>
                </thead>
                <tbody cdkDropList (cdkDropListDropped)="drop($event, configuration.sections)">
                  <tr *ngFor="let section of configuration.sections; let i = index" cdkDrag>
                    <td><i class="material-icons pointer" cdkDragHandle>drag_handle</i></td>
                    <td contenteditable="true" [innerHTML]="section.name"
                      (blur)="section.name = clearTags($event.target.innerHTML)"></td>
                    <td contenteditable="true" [innerHTML]="section.rowFrom" (keydown)="inputNotLetter($event)"
                        (blur)="section.rowFrom = toNumber($event.target.innerHTML)"></td>
                    <td contenteditable="true" [innerHTML]="section.rowTo" (keydown)="inputNotLetter($event)"
                        (blur)="section.rowTo = toNumber($event.target.innerHTML)"></td>
                    <td contenteditable="true" [innerHTML]="section.maxPassengers" (keydown)="inputNotLetter($event)"
                      (blur)="section.maxPassengers = toNumber($event.target.innerHTML)"></td>
                    <td (click)="section.index = -section.index"
                        class="font-weight-bold h5"
                        [ngClass]="{'text-red': section.index < 0, 'text-green': section.index > 0}">
                      {{ section.index < 0 ? '-' : '+' }}
                    </td>
                    <td contenteditable="true" [innerHTML]="section.index < 0 ? -section.index : section.index"
                      (keydown)="inputNotLetter($event)" (blur)="section.index = toNumber(section.index >= 0 ? $event.target.innerHTML : '-' + $event.target.innerHTML)">
                    </td>
                    <td>
                      <i class="material-icons m-0 text-red font-weight-bold pointer"
                        (click)="deleteElement(configuration.sections, i)">clear</i>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="text-right">
                <button class="btn btn-blue"
                        (click)="addItemByClass(configuration.sections, configurationSection)"
                        i18n="adm|Weight and balance@@adm.wb.tab.cabinAndEquipment.groupCabinArea.button.add">
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 mb-3">
          <div class="card bg-gray" (click)="addItem(ahmData.configurations, {name: '', sections: []})">
            <div class="card-body d-flex align-items-center justify-content-center">
              <div class="h1">+</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="showTab('crew-composition')" class="bg-white py-3">
      <div class="row">
        <div class="col-md-6 mb-3">
          <div class="card h-100">
            <div class="card-body">
              <p class="font-weight-bold text-blue h5 mb-3"
                 i18n="adm|Weight and balance@@adm.wb.tab.crewComposition.groupCrewComposition">Crew composition</p>
              <table class="w-100 contenteditable text-center mb-3">
                <thead>
                  <tr class="font-weight-bold">
                    <td width="35px"></td>
                    <td i18n="adm|Weight and balance@@adm.wb.tab.crewComposition.groupCrewComposition.table.cockpitCrew">Cockpit сrew</td>
                    <td i18n="adm|Weight and balance@@adm.wb.tab.crewComposition.groupCrewComposition.table.cabinCrew">Cabin сrew</td>
                    <td i18n="adm|Weight and balance@@adm.wb.tab.crewComposition.groupCrewComposition.table.extraCrew">Extra сrew</td>
                    <td i18n="adm|Weight and balance@@adm.wb.tab.crewComposition.groupCrewComposition.table.standartConfiguration">Standard configuration</td>
                    <td i18n="adm|Weight and balance@@adm.wb.tab.crewComposition.groupCrewComposition.table.dow">DOW</td>
                    <td i18n="adm|Weight and balance@@adm.wb.tab.crewComposition.groupCrewComposition.table.doi">DOI</td>
                    <td width="35px"></td>
                  </tr>
                </thead>
                <tbody cdkDropList (cdkDropListDropped)="drop($event, ahmData.dowChanges.crew)">
                  <tr *ngFor="let crew of ahmData.dowChanges.crew; let i = index" cdkDrag>
                    <td><i class="material-icons pointer" cdkDragHandle>drag_handle</i></td>
                    <td contenteditable="true" [innerHTML]="crew.cockpit" (keydown)="inputNotLetter($event)"
                      (blur)="crew.cockpit = toNumber($event.target.innerHTML)"></td>
                    <td contenteditable="true" [innerHTML]="crew.cabin" (keydown)="inputNotLetter($event)"
                      (blur)="crew.cabin = toNumber($event.target.innerHTML)"></td>
                    <td contenteditable="true" [innerHTML]="crew.extra" (keydown)="inputNotLetter($event)"
                      (blur)="crew.extra = toNumber($event.target.innerHTML)"></td>
                    <td contenteditable="true">
                      <input type="checkbox"
                             name="crewStandard"
                             id="crewStandard"
                             class="d-hover"
                             [(ngModel)]="crew.standard"
                             (click)="setStandard(ahmData.dowChanges.crew, 'standard', i)"
                             [checked]="crew.standard">
                    </td>
                    <td contenteditable="true" [innerHTML]="crew.dow" (keydown)="inputNotLetter($event)"
                      (blur)="crew.dow = toNumber($event.target.innerHTML)"></td>
                    <td contenteditable="true" [innerHTML]="crew.doi" (keydown)="inputNotLetter($event)"
                      (blur)="crew.doi = toNumber($event.target.innerHTML)"></td>
                    <td>
                      <i class="material-icons m-0 text-red font-weight-bold pointer"
                        (click)="deleteElement(ahmData.dowChanges.crew, i)">clear</i>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="text-right">
                <button class="btn btn-blue"
                        (click)="addItemByClass(ahmData.dowChanges.crew, dowChangesCrew)"
                        i18n="adm|Weight and balance@@adm.wb.tab.crewComposition.groupCrewComposition.button.add">
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="showTab('stabilizer-trim')" class="bg-white p-3">
      <div class="card mb-3" *ngFor="let stabilizer of ahmData.stabilizerTrim; let indexStab = index">
        <div class="card-body">
          <div class="input-group flex-nowrap mb-3">
            <div class="input-group-prepend custom-sm">
              <span class="input-group-text font-small py-0"
                    i18n="adm|Weight and balance@@adm.wb.tab.stabilizerTrim.note">
                Note
              </span>
            </div>
            <input type="text" class="w-100 form-control custom-sm" [(ngModel)]="stabilizer.note" />
          </div>
          <div class="row">
            <div class="col-xl-4 col-lg-6" *ngFor="let stabilizerTrim of stabilizer.values; let indexStabTo = index">
              <div class="card mb-4">
                <div class="card-body">
                  <div class="d-flex align-items-center justify-content-between mb-3">
                    <p class="font-weight-bold text-blue h5 mb-3"
                       i18n="adm|Weight and balance@@adm.wb.tab.stabilizerTrim.settings">Settings</p>
                    <div>
                      <button type="button" class="btn btn-xs mr-2 btn-ico btn-blue" ngbTooltip="Duplicate"
                              (click)="copyItem(stabilizer.values, stabilizer.values[indexStabTo])"
                              i18n-ngbTooltip="adm|Weight and balance buttons - duplicate@@adm.wb.main.button.duplicate">
                        <i class="material-icons color-white">content_copy</i>
                      </button>
                      <button type="button" class="btn btn-xs mr-2 btn-ico btn-red" ngbTooltip="Delete"
                              (click)="deleteElement(stabilizer.values, indexStabTo)"
                              i18n-ngbTooltip="adm|Weight and balance buttons - delete@@adm.wb.main.button.delete">
                        <i class="material-icons color-white">delete</i>
                      </button>
                    </div>
                  </div>
                  <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0"
                            i18n="adm|Weight and balance - weight@@adm.wb.tab.stabilizerTrim.weight">
                        Weight
                      </span>
                    </div>
                    <input type="number" class="w-100 form-control custom-sm" [(ngModel)]="stabilizerTrim.weight" />
                  </div>
                  <table class="w-100 contenteditable text-center mb-3">
                    <thead>
                      <tr class="font-weight-bold">
                        <td width="35px"></td>
                        <td i18n="adm|Weight and balance@@adm.wb.tab.stabilizerTrim.table.mac">%MAC</td>
                        <td></td>
                        <td i18n="adm|Weight and balance@@adm.wb.tab.stabilizerTrim.table.stab">STAB</td>
                        <td width="35px"></td>
                      </tr>
                    </thead>
                    <tbody cdkDropList (cdkDropListDropped)="drop($event, stabilizerTrim.trims)">
                      <tr *ngFor="let trim of stabilizerTrim.trims; let i = index" cdkDrag>
                        <td><i class="material-icons pointer" cdkDragHandle>drag_handle</i></td>
                        <td [innerHTML]="trim.mac"
                            (keydown)="inputNotLetter($event)"
                            (blur)="trim.mac = toNumber($event.target.innerHTML)"
                            contenteditable="true"></td>
                        <td (click)="trim.trim = -trim.trim"
                            class="font-weight-bold h5"
                            [ngClass]="{'text-red': trim.trim < 0, 'text-green': trim.trim > 0}">
                          {{ trim.trim < 0 ? '-' : '+' }}
                        </td>
                        <td contenteditable="true"
                            [innerHTML]="trim.trim < 0 ? -trim.trim : trim.trim" (keydown)="inputNotLetter($event)"
                            (blur)="trim.trim = toNumber(trim.trim >= 0 ? $event.target.innerHTML : '-' + $event.target.innerHTML)"></td>
                        <td>
                          <i class="material-icons m-0 text-red font-weight-bold pointer"
                            (click)="deleteElement(stabilizerTrim.trims, i)">clear</i>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="text-right">
                    <button class="btn btn-blue"
                        (click)="addItemByClass(stabilizerTrim.trims, stabToTrims)"
                        i18n="adm|Weight and balance@@adm.wb.tab.stabilizerTrim.button.add">
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-6">
              <div class="card bg-gray" (click)="addItemByClass(stabilizer.values, stabToValues)">
                <div class="card-body d-flex align-items-center justify-content-center">
                  <div class="h1">+</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3 text-right">
        <button class="btn btn-blue"
            (click)="addItemByClass(ahmData.stabilizerTrim, stabilizerTrim)"
            i18n="adm|Weight and balance@@adm.wb.tab.stabilizerTrim.button.add">
          Add
        </button>
      </div>
    </div>-->
  </div>
</div>
<div class="card weight-balance-content" *ngIf="activeWindow == 'ahm-card'">
  <div class="card-body">
    <p class="font-weight-bold text-blue h5 mb-3">AHM</p>
  </div>
</div>
<!-- Modal -->


<ng-template #modalAhm let-modal>
  <form #modalAhmForm="ngForm">
    <!--<div class="loading" *ngIf="loading">
        <div class="cssload-spin-box"></div>
      </div>-->
    <div class="modal-header">
      <h5 class="modal-title" id="exampleModalScrollableTitle">
        <span *ngIf="userAction === 'add-ahm'"
              i18n="adm|Weight and balance@@adm.wb.modal.editAhm.addAhm">Add AHM</span>
        <span *ngIf="userAction === 'edit-ahm'"
              i18n="adm|Weight and balance@@adm.wb.modal.editAhm.editAhm">Edit AHM</span>
        <span *ngIf="userAction === 'copy-ahm'"
              i18n="adm|Weight and balance@@adm.wb.modal.editAhm.copyAhm">Copy AHM</span>
      </h5>
      <button type="button" class="close mp-0" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body bg-light-gray">
      <div class="row mb-2">
        <div class="col-8 pr-0">
          <div class="card bg-none">
            <div class="card-body p-3 bg-white rounded">
              <p class="m-0 h6 card__header--important">
                <i class="material-icons">apartment</i>
                <span i18n="adm|Weight and balance@@adm.wb.modal.editAhm.groupAirline">Airline</span>
              </p>
              <div class="row">
                <div class="col-4 pr-0">
                  <label class="col-form-label col-form-label-sm">
                    <span
                      i18n="adm|Weight and balance@@adm.wb.modal.editAhm.groupAirline.iata">IATA</span><span
                      class="required-asterisk">*</span>
                  </label>
                  <ng-select class="form-control custom-sm p-0" [items]="references.airlines" bindLabel="iata"
                    bindValue="id" [(ngModel)]="ahm.airlineId" #airlineIata="ngModel" name="airlineIata"
                    [virtualScroll]="true" [loading]="selectLoadAnimation.airlines" required>
                  </ng-select>
                  <div *ngIf="!airlineIata?.valid && (airlineIata?.dirty || airlineIata?.touched)"
                    class="invalid-feedback d-block">
                    <div *ngIf="airlineIata.errors.required"
                         i18n="adm|Weight and balance@@adm.wb.modal.editAhm.groupAirline.req">
                      IATA is required
                    </div>
                  </div>
                </div>
                <div class="col-4 pr-0">
                  <label class="col-form-label col-form-label-sm">
                    <span
                      i18n="adm|Weight and balance@@adm.wb.modal.editAhm.groupAirline.icao">ICAO</span><span
                      class="required-asterisk">*</span>
                  </label>
                  <ng-select class="form-control custom-sm p-0" [items]="references.airlines" bindLabel="icao"
                    bindValue="id" [(ngModel)]="ahm.airlineId" name="airlineIcao" #airlineIcao="ngModel"
                    [virtualScroll]="true" [loading]="selectLoadAnimation.airlines" required>
                  </ng-select>
                  <div *ngIf="!airlineIcao?.valid && (airlineIcao?.dirty || airlineIcao?.touched)"
                    class="invalid-feedback d-block">
                    <div *ngIf="airlineIcao.errors.required"
                          i18n="adm|Weight and balance@@adm.wb.modal.editAhm.groupAirline.icao.req">
                      ICAO is required
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <label class="col-form-label col-form-label-sm">
                    <span i18n="adm|Weight and balance@@adm.wb.modal.editAhm.groupAirline.name">Name</span><span
                          class="required-asterisk">*</span>
                  </label>
                  <ng-select class="form-control custom-sm p-0" [items]="references.airlines" bindLabel="name"
                    bindValue="id" [(ngModel)]="ahm.airlineId" name="airlineName" #airlineName="ngModel"
                    [virtualScroll]="true" [loading]="selectLoadAnimation.airlines" required>
                  </ng-select>
                  <div *ngIf="!airlineName?.valid && (airlineName?.dirty || airlineName?.touched)"
                    class="invalid-feedback d-block">
                    <div *ngIf="airlineName.errors.required"
                        i18n="adm|Weight and balance@@adm.wb.modal.editAhm.groupAirline.name.req">
                      Name is required
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="card bg-none">
            <div class="card-body p-3 bg-white rounded">
              <p class="m-0 h6 card__header--important">
                <i class="material-icons">airplanemode_active</i>
                <span i18n="adm|Weight and balance@@adm.wb.modal.editAhm.groupAircraft">Aircraft</span>
              </p>
              <label class="col-form-label col-form-label-sm">
                <span i18n="adm|Weight and balance@@adm.wb.modal.editAhm.groupAircraft.type">Type</span><span
                      class="required-asterisk">*</span>
              </label>
              <ng-select class="form-control custom-sm p-0" [items]="references.aircraft_types" bindLabel="iata"
                bindValue="id" [(ngModel)]="ahm.aircraftTypeId" #aircraftTypeId="ngModel" name="aircraftTypeId"
                [virtualScroll]="true" [loading]="selectLoadAnimation.aircraft_types" required>
              </ng-select>
              <div *ngIf="!aircraftTypeId?.valid && (aircraftTypeId?.dirty || aircraftTypeId?.touched)"
                class="invalid-feedback d-block">
                <div *ngIf="aircraftTypeId.errors.required"
                    i18n="adm|Weight and balance@@adm.wb.modal.editAhm.groupAircraft.type.req">
                  Type is required
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 mt-3">
          <div class="card bg-none">
            <div class="card-body p-3 bg-white rounded">
              <p class="m-0 h6 card__header--important">
                <span i18n="adm|Weight and balance@@adm.wb.modal.editAhm.groupAhm">AHM</span>
              </p>
              <div class="row">
                <div class="col-4">
                  <label class="col-form-label col-form-label-sm">
                    <span i18n="adm|Weight and balance@@adm.wb.modal.editAhm.groupAhm.revision">Revision</span><span class="required-asterisk">*</span>
                  </label>
                  <input type="number"
                        class="form-control"
                        name="ahmRevision"
                        [(ngModel)]="ahm.revision"
                        #ahmRevision="ngModel" required>
                  <div *ngIf="!ahmRevision?.valid && (ahmRevision?.dirty || ahmRevision?.touched)"
                    class="invalid-feedback d-block">
                    <div *ngIf="ahmRevision.errors.required"
                         i18n="adm|Weight and balance@@adm.wb.modal.editAhm.groupAhm.revision.req">
                      AHM revision is required
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <label class="col-form-label col-form-label-sm">
                    <span i18n="adm|Weight and balance@@adm.wb.modal.editAhm.groupAhm.complatedBy">Completed by</span>
                  </label>
                  <input type="number" class="form-control">
                </div>
                <div class="col-4">
                  <label class="col-form-label col-form-label-sm">
                    <span i18n="adm|Weight and balance@@adm.wb.modal.editAhm.groupAhm.reasonChangesOverview">Reason/Changes overview</span>
                  </label>
                  <input type="text" class="form-control">
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <label class="col-form-label col-form-label-sm">
                    <span i18n="adm|Weight and balance@@adm.wb.modal.editAhm.groupAhm.dateRangeStart">Release date / Date range start</span>
                  </label>
                  <input type="date"
                          name="dtRangeStart"
                          #dtRangeStart="ngModel"
                          [ngModel]="ahm.dtRangeStart | date: 'yyyy-MM-dd' : 'UTC+0'"
                          (blur)="ahm.dtRangeStart = parseDate($event.target.value)"
                          class="form-control my-1"
                          required>
                  <div *ngIf="!dtRangeStart?.valid && (dtRangeStart?.dirty || dtRangeStart?.touched)"
                      class="invalid-feedback d-block">
                    <div *ngIf="dtRangeStart.errors.required"
                        i18n="ref|Modal season - error date range finish is req@@ref.main.modal.season.err.dtRangeFinishReq">
                      Date range start is required
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <label class="col-form-label col-form-label-sm">
                    <span i18n="adm|Weight and balance@@adm.wb.modal.editAhm.groupAhm.dateRangeFinish">Date range finish</span>
                  </label>
                  <input type="date"
                          name="dtRangeFinish"
                          #dtRangeFinish="ngModel"
                          [ngModel]="ahm.dtRangeFinish | date: 'yyyy-MM-dd' : 'UTC+0'"
                          (blur)="ahm.dtRangeFinish = parseDate($event.target.value)"
                          class="form-control my-1"
                          required>
                    <div *ngIf="!dtRangeFinish?.valid && (dtRangeFinish?.dirty || dtRangeFinish?.touched)"
                        class="invalid-feedback d-block">
                      <div *ngIf="dtRangeFinish.errors.required"
                          i18n="ref|Modal season - error date range finish is req@@ref.main.modal.season.err.dtRangeFinishReq">
                        Date range finish is required
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer bg-light-gray">
      <button *ngIf="userAction === 'add-ahm'" type="button" class="btn btn-sm btn-blue" (click)="addAhm(modal.dismiss)">
        <span i18n="adm|Weight and balance@@adm.wb.modal.editAhm.button.append">Append</span>
      </button>
      <button *ngIf="userAction === 'copy-ahm'" type="button" class="btn btn-sm btn-blue" (click)="saveCopedAhm(modal.dismiss)">
        <span i18n="adm|Weight and balance@@adm.wb.modal.editAhm.button.copy">Save a copy</span>
      </button>
      <button *ngIf="userAction === 'edit-ahm'" type="button" class="btn btn-sm btn-blue" (click)="saveAhm(modal.dismiss)">
        <span i18n="adm|Weight and balance@@adm.wb.modal.editAhm.button.save">Save</span>
      </button>
      <button type="button" class="btn btn-sm btn-gray-blue-dark ml-2" data-dismiss="modal" (click)="modal.dismiss()">
        <span i18n="adm|Weight and balance@@adm.wb.modal.editAhm.button.close">Close</span>
      </button>
    </div>
  </form>
</ng-template>

<div *ngIf="errorMessage" class="ms-modal-message">
  <div class="modal-content w-25">
    <div class="modal-header" [ngClass]="{'bg-info': errorType == 'info',
                                    'bg-warning': errorType == 'warning',
                                    'bg-danger': errorType == 'error'}">
      <h5 *ngIf="errorType == 'info'" class="modal-title"
        i18n="adm|Weight and balance modal message error - header information@@adm.wb.main.modalMessage.errMes.header.inf">
        Information
      </h5>
      <h5 *ngIf="errorType == 'warning'" class="modal-title"
        i18n="adm|Weight and balance modal message error - header watning@@adm.wb.main.modalMessage.errMes.header.warn">
        Warning
      </h5>
      <h5 *ngIf="errorType == 'error'" class="modal-title"
        i18n="adm|Weight and balance modal message error - header error@@adm.wb.main.modalMessage.errMes.header.err">
        Error
      </h5>
      <button type="button" class="close" (click)="clearErrorMess()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      {{errorMessage}}
    </div>
    <div class="modal-footer">
      <button type="button" *ngIf="modalType==='setAnswer'" class="btn btn-sm btn-blue" (click)="userAnswer = true"
        i18n="adm|Weight and balance modal message error - button yes@@adm.wb.main.modalMessage.errMes.button.yes">
        Yes
      </button>
      <button type="button" *ngIf="modalType==='setAnswer'" class="btn btn-sm btn-gray-blue-dark"
        (click)="userAnswer = false" i18n="adm|Weight and balance modal message error - button no@@adm.wb.main.modalMessage.errMes.button.no">
        No
      </button>
      <button type="button" *ngIf="modalType!=='setAnswer'" class="btn btn-sm btn-gray-blue-dark"
        (click)="errorMessage = null"
        i18n="adm|Weight and balance modal create message error - button close@@adm.wb.modalCreateMessage.buttons.close">
        Close
      </button>
    </div>
  </div>
</div>
